// Package Imports
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Project Imports
import ApiURL from "core/constants/ApiEndPoints";
import HttpMethod from "core/constants/ApiMethodConstants";
import { useAxios } from "./useAxios";
import * as ApiEndPoints from "core/constants/ApiEndPoints";
// import AuthContext from "context/AuthContext";

export const useNftCollectionData = (taskUrlQueryParams, page, size) => {
  let { response, postData, responseSchema } = useAxios();
  const [nftCollectionData, setNftCollectionData] = useState(undefined);
  const [paginationData, setPaginationData] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();

  const fetchData = useCallback(
    (taskUrlQueryParams, page, size) => {
      let addExtraParams = "";
      if (page !== undefined && size !== undefined) {
        setPaginationData({ page, size });
        addExtraParams += `page=${page + 1}&itemsperpage=${size}`;
      }
      let callingMethod = HttpMethod.GET;
      if (Object.keys(taskUrlQueryParams).length !== 0 && Object.keys(taskUrlQueryParams)[0] === 'null' ) {
      // if (Object.keys(taskUrlQueryParams).length !== 0 && Object.keys(taskUrlQueryParams) ) {
        // console.log("key>>", Object.keys(taskUrlQueryParams)[0]);
        let objectKey = Object.keys(taskUrlQueryParams)[0];
        // console.log("query>>", taskUrlQueryParams[objectKey]);
        taskUrlQueryParams = {"collection_name":taskUrlQueryParams[objectKey]}
      }
      if (Object.keys(taskUrlQueryParams).length !== 0) {
        callingMethod = HttpMethod.POST;
      }
      postData({
        // method: "GET",
        // url: (`${ApiEndPoints.TASK_SEARCH}?${taskUrlQueryParams}&${addExtraParams}&${sortParams}&`),
        method: callingMethod,
        url: (`${ApiURL.NFT_COLLECTION}?${addExtraParams}`),
        data: taskUrlQueryParams
      });
    },
    [postData]
  );

  useEffect(() => {
    if (response !== undefined) {
      setNftCollectionData(response);
    }
    if (
      responseSchema !== undefined &&
      responseSchema.headers &&
      paginationData.page !== undefined &&
      paginationData.size !== undefined
    ) {
      setPaginationData({
        total: parseInt(response.summary.total_rows),
        page: paginationData.page,
        size: paginationData.size,
      });
    }
  }, [response]);

  return [nftCollectionData, fetchData, paginationData];
};
