// Package Imports
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

//Project Imports
import Heading from "ui/shared/components/heading/Heading";
import TabularDataCard from "ui/shared/components/tabularDataCard/TabularDataCard";
import { SubscriptionIcon } from "core/constants/Images";
import { useAxios } from "core/hooks/useAxios";
import CdModal from "ui/shared/components/CdModal";
import CdButton from "ui/shared/components/cdButton/CdButton";
import AddSubscriptionModal from "./components/AddSubscriptionModal/AddSubscriptionModal";
import DeleteModal from "ui/shared/components/deleteModal/DeleteModal";
import AlertModal from "ui/shared/components/alertModal/AlertModal";
import { Plusicon } from "core/constants/Images";
import ApiURL from "core/constants/ApiEndPoints";
import Loader from "ui/shared/components/loader/Loader";
import {
  DeletionSuccess,
  ErrorMsg,
  SubscriptionDelete,
} from "core/constants/Constants";
import HttpMethod from "core/constants/ApiMethodConstants";
import "./Subscription.css";
import { useSubscriptionData } from "core/hooks/useSubscriptionData";
import Pagination from "../Pagination/Pagination";
import TabularDataCardTabsView from "ui/shared/components/tabularDataCard/TabularDataCardTabsView";

const Subscription = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showStatus, setShowStatus] = useState("");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [metricData, setMetricData] = useState({});
  const [selectedSubscription, setselectedSubscription] = useState();
  const [subscriptionId, setSubscriptionId] = useState();
  const getSubscriptionDataList = useAxios();
  const getMetricsData = useAxios();
  const deleteSubscriptionDataList = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;

  let size = 3;
  let currentPage = parseInt(
    new URLSearchParams(window.location.search).get("page") || "1"
  );
  const [page, setPage] = useState(currentPage - 1 || 0);

  const [subscriptionList, fetchData, paginationData] = useSubscriptionData(
    page,
    size
  );

  useEffect(() => {
    // console.log("filterObj", filterParam);
    // fetchData(page, size);
    fetchSubscriptionData()
  }, [page]);

  function fetchSubscriptionData() {
    fetchData(page, size);
  }

  // function onSearchClick(obj) {
  //   console.log("SearchObj", obj);
  //   setFilterObj(obj);
  //   setPage(0);
  // }

  function onPageChange(pageNumber) {
    setPage(pageNumber);
  }

  useEffect(() => {
    if (subscriptionList !== undefined) {
      setSubscriptionData(subscriptionList.data);
    }
  }, [subscriptionList]);

  // useEffect(() => {
  //   getAllSubscriptions();
  // }, []);

  // const getAllSubscriptions = async () => {
  //   setIsLoading(true);
  //   await getSubscriptionDataList.postData({
  //     method: HttpMethod.GET,
  //     url: ApiURL.SUBSCRIPTION_URL,
  //   });
  //   setIsLoading(false);
  // };

  // const metricsApiCall = async (subscription_id) => {
  //   setSubscriptionId(subscription_id);
  //   console.log("subscriptionID>>", subscription_id);

  //   await getMetricsData.postData({
  //     method: HttpMethod.GET,
  //     url: ApiURL.METRICS_URL + "?subscription_id=" + subscription_id,
  //   });
  // };
  // useEffect(() => {
  //   if (getMetricsData.response !== undefined) {
  //     let metricObject = metricData;
  //     metricObject[getMetricsData.response.subscrption_id] =
  //       getMetricsData.response;
  //     setMetricData(metricObject);
  //   }
  // }, [getMetricsData.response]);


  const onCloseModal = () => {
    setShowModal(false);
  };
  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const onCloseAlertModal = () => {
    setShowAlertModal(false);
    fetchSubscriptionData();
  };
  const onEditSubscription = (item) => {
    setselectedSubscription(item);
    setShowModal(true);
  };
  const onDeleteSubscription = (item) => {
    setselectedSubscription(item);
    setShowDeleteModal(true);
  };
  const onSubmitModal = (status, showAddUpdateStatus) => {
    if (status) {
      setselectedSubscription(null);
    }
    setShowModal(false);
    setShowStatus(showAddUpdateStatus);
    setShowAlertModal(true);
    // getAllSubscriptions();
  };
  const onAddSubscriptionClick = () => {
    setShowModal(true);
    setselectedSubscription(null);
  };
  const onSubscriptionDeletion = async (item) => {
    await deleteSubscriptionDataList.postData({
      method: HttpMethod.DELETE,
      url: ApiURL.SUBSCRIPTION_URL,
      data: {
        subscription_id: item.subscription_id,
      },
    });
    setShowDeleteModal(false);
    // getAllSubscriptions();
  };
  useEffect(() => {
    if (deleteSubscriptionDataList.response !== undefined) {
      if (deleteSubscriptionDataList.response.status === DeletionSuccess) {
        setShowStatus(SubscriptionDelete);
        setShowAlertModal(true);
      } else {
        setShowStatus(ErrorMsg);
        setShowAlertModal(true);
      }
    }
  }, [deleteSubscriptionDataList.response]);

  // console.log("====================", metricData[0]?.id);
  return (
    <>
      {/* Subscription-page-Start */}
      <div className="c-Subscription subscription">
        {loader}
        <div className="subscription-container">
          <Heading label="Subscription Page" logo={SubscriptionIcon} />
        </div>
        <hr className="subscription-border" />
        <div className="subscription-button">
          <CdButton
            label="Add Subscription"
            icon={Plusicon}
            onClick={() => onAddSubscriptionClick()}
          />
        </div>

        <div className="subscription-modal">
          {showModal ? (
            <AddSubscriptionModal
              showModal={showModal}
              onClose={onCloseModal}
              onSubmit={onSubmitModal}
              item={selectedSubscription}
            />
          ) : undefined}
          {showDeleteModal ? (
            <DeleteModal
              title="Delete Subscription"
              showModal={showDeleteModal}
              onClose={onCloseDeleteModal}
              onSubmit={onSubscriptionDeletion}
              item={selectedSubscription}
            />
          ) : undefined}

          {showAlertModal ? (
            <AlertModal
              title="Status"
              showDescription={showStatus}
              showModal={showAlertModal}
              onClose={onCloseAlertModal}
              onSubmit={onSubscriptionDeletion}
              item={selectedSubscription}
            />
          ) : undefined}
          <div className="subscription-icon">
            {subscriptionData.map((item) => (
              <div
                key={item.subscription_id}
                className="subscription-tabular-data-card"
              >
                <TabularDataCardTabsView
                  metricData={metricData}
                  subId={subscriptionId}
                  keyFor="Subscription"
                  item={item}
                  onEdit={() => onEditSubscription(item)}
                  onDelete={() => onDeleteSubscription(item)}
                />
              </div>
            ))}
          </div>
          <div className="mt-tmd">
            <Pagination
              currentPage={paginationData.page}
              total={paginationData.total}
              size={paginationData.size}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
      {/* Subscription-page-end*/}
    </>
  );
};
export default Subscription;
