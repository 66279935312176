// Package imports
import React from 'react';
import { useContext, useEffect, useState } from "react";

// Project Imports
import LoaderContext from "context/LoaderContext";
import "./Loader.css"

export default function LoaderComponent(props) {

  const [loaderStatus, setLoaderStatus] = useState(false);

  function showLoader() {
    setLoaderStatus(true)
  }
  function hideLoader() {
    setLoaderStatus(false)
  }
  function setIsLoading(status) {
    setLoaderStatus(status)
  }

  return (
    <LoaderContext.Provider value={{
      showLoader,
      hideLoader,
      setIsLoading
    }} >

      {loaderStatus && <div className={(props.isGlobal ? "fixed" : "absolute") + " bg-black bg-opacity-10 h-full left-0 overflow-hidden top-0 w-full z-30 flex justify-center items-center"}>
        <svg className="c-Loader loader-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="loader-svg-shape" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="loader-svg-loading" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>}
      {
        props.children
      }
    </LoaderContext.Provider>
  );
}