// Package imports
import React from "react";

// Project imports
import CdButton from "./cdButton/CdButton";
function CdModal(props) {
  const {showModal,onClose,btnList,title}=props

  const onBtnClick=(item)=>{
    if(item.onClick){
      item.onClick()
    }
  }
  return (
    <>
      {showModal ? (
        <>
          <div className="opacity-75 fixed inset-0 z-40 bg-gray-500 "></div>
          <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-tmd mt-12 md:mt-0">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-900 outline-none focus:outline-none">
                {/*header*/}

                <div className="bg-slate-900 grid grid-cols-6 md:gap-20 text-center py-1 border-b border-solid border-slate-200 rounded-t">
                  <div className="col-start-2 col-span-4 my-auto">
                    <h3 className="text-2xl font-medium text-center text-white">
                      {title && title}
                    </h3>
                  </div>
                  <div>
                    <button
                      className=" p-1 ml-auto bg-transparent border-0   text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={onClose}
                    >
                      <span className="text-green-300 mb-4 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                </div>
                {props.children}
                <div className="bg-slate-900 text-white flex items-center space-x-3 justify-end p-6 border-solid border-slate-200 rounded-b">
                  
                {btnList && btnList.map((item,index) => (
                  <CdButton label={item.label} type={item.type} key={index} onClick={()=> onBtnClick(item)} />
                ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
export default CdModal;
