// Package Imports
import * as Yup from "yup";

// Project Imports

export const yupSchema = () => {
    let validation = {}
    validation = Yup.object().shape({
        subscriptionName: Yup.string().required("Please enter the Subscription Name"),
        subscriptionImageURL: Yup.string().required("Please enter the Image URL"),
        subscriptionSelectedDeliveryMethod: Yup.object().required("Please select the Delivery Method"),
        subscriptionselectedFrequency: Yup.object().required("Please select the required Frequency"),
        subscriptionQuery: Yup.string().required("Please enter the Query"),
        subscriptionDescription: Yup.string().required("Please enter the Description"),

    });
    return validation
  };