// Package imports
import { useEffect, React, useState } from "react";
import { useNftCollectionData } from "core/hooks/useNftCollectionData";
import Loader from "ui/shared/components/loader/Loader";
import NftRareRankingRow from "ui/view/nftRareRankingTable/components/nftRareRankingRow/NftRareRankingRow";
import RareRankingCollectionCard from "ui/view/nftRareRankingTable/components/nftRareRankingRow/RareRankingCollectionCard";
import Pagination from "ui/view/Pagination/Pagination";

function TopNFTCollectionTable() {
  const [collectionData, setCollectionData] = useState({});
  const [nftRareRankingTable, setNftRareRankingTable] = useState();
  const [isLoading, setIsLoading] = useState(false);

  function onPageChange(pageNumber) {
    setPage(pageNumber);
  }

  let size = 5;
  let currentPage = parseInt(
    new URLSearchParams(window.location.search).get("page") || "1"
  );
  const [page, setPage] = useState(currentPage - 1 || 0);
  const [nftCollectionData, fetchData, paginationData] = useNftCollectionData(
    collectionData,
    page,
    size
  );

  useEffect(() => {
    if (collectionData !== undefined) {
      fetchData(collectionData, page, size);
    }
  }, [collectionData, page]);

  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;

  useEffect(() => {
    if (nftCollectionData !== undefined) {
      if (nftCollectionData.data.length > 0)
        setNftRareRankingTable(nftCollectionData.data);
    }
  }, [nftCollectionData]);

  return (
    <div className="container max-w-7xl mx-auto text-white">
      {loader}
      {!isLoading && nftRareRankingTable && nftRareRankingTable.length !== 0 ? (
        <>
          {/* it will showing it deskstop */}
          <div className="md:block hidden">
            <NftRareRankingRow nftRareRankingTableData={nftRareRankingTable} />
          </div>

          {/* it will showing it mobile */}
          <div className="md:hidden block">
            {nftRareRankingTable &&
              nftRareRankingTable.map((item, index) => (
                <div className="m-2" key={index}>
                  <RareRankingCollectionCard key={index} item={item} />
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          {!isLoading && nftRareRankingTable ? (
            <p className="text-white text-2xl flex justify-center mt-5">
              No Collection Found
            </p>
          ) : (
            <></>
          )}
        </>
      )}
      <div className="mt-tmd">
        <Pagination
          currentPage={paginationData.page}
          total={paginationData.total}
          size={paginationData.size}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}

export default TopNFTCollectionTable;
