import AuthContext from "context/AuthContext";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import * as RoutePaths from "core/constants/RoutePaths";
import Header from "ui/shared/components/header/Header";
import Footer from "ui/shared/components/footer/Footer";
import WalletAuth from "ui/shared/components/auth/walletAuth";

export default function NonRequireAuth({
  hideHeader,
  children,
}: {
  hideHeader: boolean,
  children: JSX.Element;
}) {
  return (
    <>
      <WalletAuth />
      {!hideHeader && <Header />}
      <div className="cal-footer-height">{children}</div>
      <Footer />
    </>
  );
}
