//Package Imports
import React from "react";

// Project imports
import "./CdTextBox.css"

const CdTextbox = (props) => {
  const {label,icon} = props
  const textFieldClasses =  icon
     ? "cdTextBox-data-container-style1"
     : "cdTextBox-data-container-style2"
    
  return (
    <div className="c-CdTextBox cdTextBox-container">
      { icon && icon}
      
        <input autoComplete="off" placeholder=" " className={textFieldClasses}
         {...props}/>
        <label className="cdTextBox-label">{label}</label>
    </div>
    
  );
};
export default CdTextbox;
