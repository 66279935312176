// Package Imports

import * as Yup from "yup";

export const yupSchema = () => {
  let validation = {};
  validation = Yup.object().shape({
    // attributeCount: Yup.object().required("select attribute count"),
    // background: Yup.object().required("select background"),
    // skin: Yup.object().required("select skin"),
    // speciality: Yup.object().required("select speciality"),
    // rank: Yup.object().required("select rank"),
    // price: Yup.object().required("select price"),
  });
  return validation;
};
