// Package Imports

import * as Yup from "yup";

export const yupSchema = () => {
  let validation = {};
  validation = Yup.object().shape({
    // search: Yup.string().required("Please Insert Data"),
  });
  return validation;
};
