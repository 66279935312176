// Package imports

// Project imports
import WalletLeaderboardRowItem from "../WalletLeaderboardRowItem/WalletLeaderboardRowItem";
import "./DeFiLeaderboardTable.css"

function DeFiLeaderboardTable(props) {
  const {walletData}=props;
  return (
    <div className="c-DeFiLeaderboardTable page-container">
      <table className="table">
        <thead className="table-header">
          <tr>
            <th className="wallet-heading">Wallet</th>
            <th className="sol-heading">SOL</th>
            
          </tr>
        </thead>
        <tbody>
          {walletData && walletData.map((item,index)=>(
            <WalletLeaderboardRowItem key={index} leaderBoardDataItem={item}/>

          ))}
       
         

        </tbody>
      </table>
    </div>
  );
}

export default DeFiLeaderboardTable;
