const InitialValue = {
  name: "",
  email: "",
};
export function fetchUserData(userProfileData) {
  let userData = InitialValue;
  userData.name = userProfileData.name;
  userData.email = userProfileData.email_address;
  return userData;
}
