// Package Imports
import react from "react";

//project import
import "./aboutUs.css";
import { CompanyLogo, Computer } from "core/constants/Images";

const AboutUsPage = (props) => {
  return (
    <>
      {/* AboutUs Page Start */}
      <div className="c-AboutUs about-us-container">
        <div className="about-us-row">
          <div className="about-us-content-section">
            <h3 className="about-us-title">AboutUs</h3>
            <p className="about-us-body">
              ChainDnA is a streaming indexer that enables blockchain data in
              near real time. We distribute data in many channels including
              streaming, API, Web Sockets, Databases, Bulk Sharing and etc. We
              track and store account state changes and all transactions. Our
              platform decodes account data so you can use it directly. Our
              unique transaction parser parses transactions and gives you
              actionable and meaningful data Our Monitoring services allows you
              setup monitors like new NFT Mints, NFT Account state changes,
              Account balance thresholds and etc. Our vision is to be your
              virtual data store, so we can handle all your data needs, so you
              can focus on your core business.
            </p>
          </div>
          <div className="about-us-image-section">
            <img src={CompanyLogo} className="about-us-image" alt="" />
          </div>
        </div>
      </div>
      {/* AboutUs Page end*/}
    </>
  );
};

export default AboutUsPage;
