// Package Imports

// Project Imports
import { SubscriptionDeliveryMethodList } from "core/utils/DropdownList";
import { SubscriptionFrequencyList } from "core/utils/DropdownList";

var initialValue = {
  subscriptionName: "",
  subscriptionImageURL: "",
  subscriptionSelectedDeliveryMethod: "",
  subscriptionselectedFrequency: "",
  subscriptionQuery: "",
  subscriptionDescription: "",
};

export const fetchSubscriptionData = (subscriptionDataValue) => {
  if(subscriptionDataValue !== null){
    let subscriptionData = initialValue;
    var subscriptionMethodName = SubscriptionDeliveryMethodList.find( ({ name }) => name === subscriptionDataValue.delivery_method );
    var subscriptionFrequencyName = SubscriptionFrequencyList.find( ({ name }) => name === subscriptionDataValue.frequency );
    subscriptionData.subscriptionName = subscriptionDataValue.name;
    subscriptionData.subscriptionImageURL = subscriptionDataValue.image_url;
    subscriptionData.subscriptionSelectedDeliveryMethod = subscriptionMethodName;
    subscriptionData.subscriptionselectedFrequency =  subscriptionFrequencyName;
    subscriptionData.subscriptionQuery = subscriptionDataValue.query;
    subscriptionData.subscriptionDescription = subscriptionDataValue.description;
    return subscriptionData;   
  }
  else{
      return initialValue={
        subscriptionName: "",
        subscriptionImageURL: "",
        subscriptionSelectedDeliveryMethod: "",
        subscriptionselectedFrequency: "",
        subscriptionQuery: "",
        subscriptionDescription: "",
      };
  }

}
