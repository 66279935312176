// Package imports
import { DatabaseIcon } from "@heroicons/react/solid";

// Project imports
import { protectedString } from "core/utils/ProtectedString";
import { convertToDecimal } from "core/utils/ConvertToDecimal";
import "./WalletLeaderboardRowItem.css";

function WalletLeaderboardRowItem(props) {
  const { leaderBoardDataItem } = props;
  return (
    <tr className="c-WalletLeaderboardRowItem container">
      <td className="wallet-table-data flex-cols-2">
        <DatabaseIcon
          className="database-icon"
          aria-hidden="true"
          fill="#00eaff"
          stroke="#00eaff"
        />
        <span className="">{protectedString(leaderBoardDataItem.pubkey)}</span>
      </td>
      <td className="sol-table-data">
        {convertToDecimal(leaderBoardDataItem.balance, 2)}
      </td>
    </tr>
  );
}

export default WalletLeaderboardRowItem;
