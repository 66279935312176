// Package Imports
import React, { useState, useEffect } from "react";
import {
  Link,
  Route,
  Routes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Formik, Form as FormikForm, Field } from "formik";
//Project Imports
import initialValue from "./Initialvalue";
import { yupSchema } from "./validation";
import { useAxios } from "core/hooks/useAxios";
import ApiURL from "core/constants/ApiEndPoints";

import { SearchIcon } from "core/constants/Images";
import InputBox from "ui/shared/components/inbutBox/InputBox";
import "./SearchBar.css";
import HttpMethod from "core/constants/ApiMethodConstants";
import NftRareRankingDropdown from "./NftRareRankingDropdown";
import Loader from "ui/shared/components/loader/Loader";

export const nftFilterDropdownList = [
  {
    label: "Collection Name",
    name: "collection_name",
  },
  {
    label: "Trait Type",
    name: "trait_type",
  },
  {
    label: "Value",
    name: "value",
  },
  {
    label: "Symbol",
    name: "symbol",
  },
  {
    label: "Creator",
    name: "creator",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Collection Family",
    name: "collection_family",
  },
  {
    label: "Mint Key",
    name: "mint_key",
  },
  {
    label: "Royality",
    name: "royality",
  },
];

const NftRareRankingSearchBar = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [showSearchDataError, setShowSearchDataError] = useState(false);
  const [searchAndSelectFieldObj, setSearchAndSelectFieldObj] = useState({
    dropDownValue: searchParams.get("searchBy"),
    searchBarValue: searchParams.get("searchValue"),
  });
  // console.log("searchAndSelectFieldObj,",searchParams);
  const postSearchData = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;
  const { findSearchData, searchCollectionValue } = props;
  useEffect(() => {
      if ( searchAndSelectFieldObj.searchBarValue !== null) {
        onSearch()
        }
  }, []);
  // useEffect(() => {
  //   setSearchAndSelectFieldObj({});
  // }, []);

  useEffect(() => {
      if (searchAndSelectFieldObj.dropDownValue && searchAndSelectFieldObj.searchBarValue !== null) {
        onSearch();
        }
  }, []);


  // let searchCollectionBy = searchParams.get("searchValue");
  // console.log("new",searchCollectionBy);

  const handleClick = () => {
    setSearchParams({
      searchBy: searchAndSelectFieldObj.dropDownValue === null ? "collection_name" : searchAndSelectFieldObj.dropDownValue,
      searchValue: searchAndSelectFieldObj.searchBarValue,
    });
  };

  async function onSearch() {
    const requestObj = {};

    requestObj[searchAndSelectFieldObj.dropDownValue] =
      searchAndSelectFieldObj.searchBarValue;
    
    if (
      "searchBarValue" in searchAndSelectFieldObj &&
    "dropDownValue" in searchAndSelectFieldObj && searchAndSelectFieldObj.dropDownValue !== null
      // searchAndSelectFieldObj.searchBarValue.length > 0
    ) {
      findSearchData(requestObj);
      handleClick();
    } else if (
      "searchBarValue" in searchAndSelectFieldObj &&
      searchAndSelectFieldObj.searchBarValue.length > 0
    ) {
      findSearchData({
        collection_name: searchAndSelectFieldObj.searchBarValue,
      });
      handleClick();
      setShowSearchDataError(false);
    } else {
      // setShowSearchDataError(true)
      // await postSearchData.postData({
      //   method: HttpMethod.POST,
      //   url: ApiURL.NFT_COLLECTION,
      //   headers: { "Content-Type": "application/json" }
      // });
      findSearchData({});
    }
  }

  return (
    <div>
      {loader}
      <Formik
        initialValues={initialValue}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={yupSchema}
        onSubmit={(values) => {
          onSearch();
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldError,
          submitForm,
        }) => (
          <FormikForm>
            <div className="c-NftSearchBar search-bar-container px-2 md:px-0 ">
              <div className="search-bar-field grid grid-cols-12 rounded-lg">
                <div className="md:col-span-2 col-span-5">
                  <Field
                    as={NftRareRankingDropdown}
                    name="dropDown"
                    onSelectMenuChange={(value) => {
                      setSearchAndSelectFieldObj({
                        ...searchAndSelectFieldObj,
                        dropDownValue: value.name,
                      });
                      return setFieldValue(value);
                    }}
                    optionList={nftFilterDropdownList}
                    selectedValue={searchAndSelectFieldObj.dropDownValue}
                    value={searchAndSelectFieldObj.dropDownValue}
                  />
                  {/* {console.log("values.dropDown", values)} */}
                  <p className="text-red-600">
                    {errors.dropDown && touched.dropDown ? errors.dropDown : ""}
                  </p>
                </div>
                <div className="md:col-span-9 col-span-6 ">
                  <Field
                    as={InputBox}
                    label="Search"
                    placeholder="Enter nft name, collection name, creator key, trait key, trait type, trait value"
                    name="search"
                    value={searchAndSelectFieldObj.searchBarValue}
                    onChange={(e) => {
                      setSearchAndSelectFieldObj({
                        ...searchAndSelectFieldObj,
                        searchBarValue: e.target.value,
                      });
                    }}
                  ></Field>

                  <p className="search-bar-validation">
                    {errors.search && touched.search ? errors.search : ""}
                  </p>
                  {showSearchDataError && (
                    <p className="search-bar-validation ">please enter</p>
                  )}
                </div>

                <div className="search-icon">
                  <button type="submit">
                    <img
                      src={SearchIcon}
                      className="search-icon-style"
                      alt="searchIcon"
                    />
                  </button>
                </div>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
export default NftRareRankingSearchBar;
