// Project imports
import "./InputBox.css"
function InputBox(props) {
    return ( 
        <div>
            <input type="text" className="c-Input-box input-box" {...props}/>
        </div>
     );
}

export default InputBox;
