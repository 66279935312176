// Package Imports
import * as Yup from "yup";

// Project Imports

export const yupSchema = () => {
    let validation = {}
    validation = Yup.object().shape({
        monitoringAndAlertName: Yup.string().required("Please enter the Monitoring & Alert name"),
        monitoringAndAlertImageURL: Yup.string().required("Please enter the Image URL"),
        monitoringAndAlertSelectedDeliveryMethod: Yup.object().required("Please select the Delivery Method"),
        monitoringAndAlertselectedFrequency: Yup.object().required("Please select the required Frequency"),
        monitoringAndAlertQuery: Yup.string().required("Please enter the Query"),
        monitoringAndAlertDescription: Yup.string().required("Please enter the Description"),
    });
    return validation
  };