//Package Imports
import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import {
  CalendarIcon,
  CheckCircleIcon,
  LocationMarkerIcon,
  KeyIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
// import Tooltip from "@mui/material/Tooltip";
//Project Imports
import { CompanyIcon } from "core/constants/Images";
import "./TabularDataCard.css";
import SubscriptionChart from "ui/view/subscription/SubscriptionChart";
import { useAxios } from "core/hooks/useAxios";
import ApiURL from "core/constants/ApiEndPoints";
import HttpMethod from "core/constants/ApiMethodConstants";
import { graph } from "core/utils/GraphData";

// import TabsRender from "./Tab";
function TabularDataCardTabsView(props) {
  const { item, onEdit, onDelete, keyFor, subId } = props;
  const [openTab, setOpenTab] = useState(1);
  const [metricData, setMetricData] = useState({});
  // const [graphData, setGraphData] = useState([
  //   [{ type: "date", label: "Day" }, "Graph"],
  // ]);

  const getMetricsData = useAxios();

  function onChangeTabs(switchTab) {
    setOpenTab(switchTab);
    metricsApiCall(switchTab);
  }

  const metricsApiCall = async () => {
    if (keyFor.toLowerCase() === "subscription") {
      await getMetricsData.postData({
        method: HttpMethod.GET,
        url: ApiURL.METRICS_URL + "?subscription_id=" + item.subscription_id,
      });
    } else {
      await getMetricsData.postData({
        method: HttpMethod.GET,
        url: ApiURL.MONITOR_METRICS_URL + "?monitor_id=" + item.monitor_id,
      });
    }
  };
  useEffect(() => {
    // if (getMetricsData.response !== undefined) {
    //   setMetricData(getMetricsData.response);
    // }
    // if (metricData && Object.keys(metricData).length !== 0) {
    //   graph(metricData,"event_category",metricData.data.event_quantity);
    //   }

    if (
      getMetricsData.response &&
      Object.keys(getMetricsData.response).length !== 0
    ) {
      setMetricData(getMetricsData.response);
      // graph(getMetricsData.response.data,"event_category","event_quantity");
    }
  }, [getMetricsData.response]);

  function splitDate(str) {
    var dateFormat = moment(str).format("Do MMM YYYY");
    // var timeFormat = moment(str).format(" h:mm:ss a");
    // var DateTime = `${dateFormat + " " + timeFormat}`;
    var DateTime = `${dateFormat}`;
    return DateTime;
  }

  // "02:00 pm"
  // function creationTime(str) {
  //   var splitted = str.split(" ");
  //   var time = splitted[1];
  //   var newDate = str.split(" ").shift();
  //   var dateString = new Date(newDate).toLocaleString("en-us", {
  //     month: "short",
  //     year: "numeric",
  //     day: "numeric",
  //   });
  //   // var DateTime = `${dateString + " " + time}`;
  //   var Time = `${time}`;
  //   return Time;
  // }
  // for (let i = 0; i < metricData.data.length; i++) {
  //   let singleArray = [];
  //   singleArray.push(Number(metricData.keys_in_subscription));
  //   singleArray.push(Number(metricData.subscrption_id));
  //   console.log("graphData", singleArray);
  // // }
  // console.log("len",metricData.data);

  // useEffect(() => {
  //   if (metricData && Object.keys(metricData).length !== 0) {
  //     const metricgraph = metricData.data;
  //     let arraymetric = [];
  //     for (let i = 0; i < metricData.data.length; i++) {
  //       let singleArray = [];
  //       singleArray.push(String(metricgraph[i].event_category));
  //       singleArray.push(Number(metricgraph[i].event_quantity));

  //       arraymetric.push(singleArray);
  //     }
  //     console.log("graphData", arraymetric);
  //   }
  // }, [getMetricsData]);

  // useEffect(() => {
  //   if (metricData && Object.keys(metricData).length !== 0) {
  //   graph(metricData,"event_category",metricData.data.event_quantity);
  //   }
  // }, [getMetricsData]);

  return (
    <div className="c-TabularDataCard tabular-data-card-body p-0">
      <div className="flex flex-wrap ">
        <div className="w-full">
          <ul className="flex mb-0  flex-wrap " role="tablist">
            <li className="-mb-px  last:mr-0 flex-auto text-center ">
              <a
                className={
                  " font-bold text-sm  uppercase px-5 py-3   block leading-normal " +
                  (openTab === 1
                    ? "text-sky-500 "
                    : " bg-gray-700 rounded-tl-lg text-sky-500 ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                  // onChangeTabs(1)
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Details
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  " font-bold uppercase  text-sm px-5 py-3  block leading-normal " +
                  (openTab === 2
                    ? "text-sky-500 "
                    : "  bg-gray-700 rounded-tr-lg text-sky-500 ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  // setOpenTab(2);
                  if (openTab === 2) {
                  } else {
                    onChangeTabs(2);
                  }
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Metrics
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full rounded">
            <div className="px-5 py-3 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="tabular-data-card-card-tabs-view flex md:flex-row w-full flex-col  ">
                    <div className="mb-4 md:mb-0">
                      <img
                        className="c-TabularDataCard tabular-data-card-image max-w-xs "
                        src={
                          item.image_url.length === 0
                            ? CompanyIcon
                            : item.image_url
                        }
                        alt=""
                      />
                    </div>
                    <div className="c-TabularDataCard image ">
                      <h3 className="tabular-data-card-title">{item.name}</h3>

                      <p className="tabular-data-card-description ">
                        {item.description}
                      </p>
                      <div className="tabular-data-card-col ">
                        <div className="tabular-data-card-col-container">
                          <label
                            htmlFor="Delivery-input"
                            className="tabular-data-card-label"
                          >
                            Delivery Method :-
                          </label>
                          <p className="tabular-data-card-label-data">
                            {item.delivery_method}
                          </p>
                        </div>
                        <div className="tabular-data-card-col-container">
                          <label
                            htmlFor="frequency-input"
                            className="tabular-data-card-label"
                          >
                            Frequency :-
                          </label>
                          <p className="tabular-data-card-label-data">
                            {item.frequency}
                          </p>
                        </div>
                      </div>
                      <div className="tabular-data-card-query-container">
                        <label
                          htmlFor="Query"
                          className="tabular-data-card-label"
                        >
                          Query :-
                        </label>

                        <p
                          title={item.query}
                          className="tabular-data-card-label-data truncate"
                        >
                          {item.query}
                        </p>

                        {/* <Tooltip title={item.query}arrow placement="top">
                        <p title={item.query} className="tabular-data-card-label-data truncate">
                          {item.query}
                        </p>
                        </Tooltip> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  {Object.keys(metricData).length !== 0 ? (
                    <div className="pie-chart-data  flex md:flex-row flex-col w-full">
                      <div className="pie-chart-fields-data-container md:w-3/5 w-full">
                        <div className="pt-4">
                          <div className="grid lg:grid-cols-12 gap-4 grid-cols-1">
                            <div className="col-span-6 c-metrix-tile body">
                              <h3 className="metrix-label px-4 py-2 flex items-center">
                                <div>
                                  <CalendarIcon className="w-6 h-6"></CalendarIcon>
                                </div>
                                <div className="px-2">Creation Date</div>
                              </h3>
                              <p className="custom-blue c-metrix-tile body-data metrix-value px-4 py-2">
                                {metricData && metricData.creation_date
                                  ? splitDate(metricData.creation_date)
                                  : " "}
                              </p>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-12 gap-4 grid-cols-1 mt-4">
                            <div className="col-span-6 c-metrix-tile body">
                              <h3 className="metrix-label px-4 py-2 flex items-center">
                                <div>
                                  <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
                                </div>

                                <div className="px-2">Status</div>
                              </h3>
                              <p className="custom-blue c-metrix-tile body-data metrix-value px-4 py-2">
                                {metricData && metricData.status
                                  ? metricData.status
                                  : " "}
                              </p>
                            </div>
                            <div className="col-span-6 c-metrix-tile body">
                              <h3 className="metrix-label px-4 py-2 flex items-center">
                                <div>
                                  <ExclamationCircleIcon className="w-6 h-6"></ExclamationCircleIcon>
                                </div>
                                <div className="px-2">Error Status</div>
                              </h3>
                              <p className="custom-blue c-metrix-tile body-data metrix-value px-4 py-2 ">
                                {metricData && metricData.error_status
                                  ? metricData.error_status
                                  : " "}
                              </p>
                            </div>
                            <div className="col-span-6 c-metrix-tile body">
                              <h3 className="metrix-label px-4 py-2 flex items-center">
                                <div>
                                  <KeyIcon className="w-6 h-6"></KeyIcon>
                                </div>
                                <div className="px-2">Keys In {keyFor}</div>
                              </h3>
                              <p className="custom-blue c-metrix-tile body-data metrix-value px-4 py-2">
                                {keyFor.toLowerCase() === "subscription"
                                  ? metricData &&
                                    metricData.keys_in_subscription
                                    ? metricData.keys_in_subscription
                                    : " "
                                  : metricData && metricData.keys_in_monitor
                                  ? metricData.keys_in_monitor
                                  : " "}
                              </p>
                            </div>
                            <div className="col-span-6 c-metrix-tile body">
                              <h3 className="metrix-label px-4 py-2 flex items-center">
                                <div>
                                  <LocationMarkerIcon className="w-6 h-6"></LocationMarkerIcon>
                                </div>
                                <div className="px-2">
                                  Data Points Delivered :-
                                </div>
                              </h3>
                              <p className="custom-blue c-metrix-tile body-data metrix-value px-4 py-2">
                                {/* {console.log("itemdata>",metricData[176].subscrption_id)}</p> */}
                                {metricData
                                  ? metricData.data_points_delivered
                                  : " "}
                              </p>
                              {/* <p className="custom-blue metrix-value">{item.name}</p> */}
                            </div>
                          </div>
                          {/* <label
                            htmlFor="Delivery-input"
                            className="tabular-data-card-label"
                          >
                            Creation Date :- 
                          </label>
                          <p className="tabular-data-card-label-data">
                          14 July 2022
                          </p> */}
                        </div>
                        {/* <div className="grid md:grid-cols-2">
                          <div className="pt-4 col-span-1">
                            <label
                              htmlFor="frequency-input"
                              className="tabular-data-card-label"
                            >
                              Status :-
                            </label>
                            <p className="tabular-data-card-label-data">
                              {item.status}
                            </p>
                          </div>

                          <div className="pt-4 col-span-1">
                            <label
                              htmlFor="frequency-input"
                              className="tabular-data-card-label"
                            >
                              Error Status :-
                            </label>
                            <p className="tabular-data-card-label-data">
                              Inactive
                            </p>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2">
                          <div className="pt-4 col-span-1">
                            <label
                              className="tabular-data-card-label"
                            >
                              No.of Keys In Subscription :-
                            </label>
                            <p className="tabular-data-card-label-data">
                              12
                            </p>
                          </div>
                          <div className="pt-4 col-span-1">
                            <label
                              htmlFor="frequency-input"
                              className="tabular-data-card-label"
                            >
                              Data Points Delivered :-
                            </label>
                            <p className="tabular-data-card-label-data">
                              8
                            </p>
                          </div>

                        </div> */}
                      </div>
                      <div className="md:w-2/5 w-full mt-6 md:mt-0">
                        {getMetricsData.response &&
                          getMetricsData.response.metrics.map((item, index) => (
                            <>
                              {item.data.length !== 0 ? (
                                <div className="pb-5">
                                  <SubscriptionChart
                                    key={index}
                                    gData={graph(
                                      item.data,
                                      "event_category",
                                      "event_quantity"
                                    )}
                                    title={item.monitor_type}
                                  />
                                </div>
                              ) : (
                                <p className="text-2xl text-white text-center">
                                  No Metrics Data Found
                                </p>
                              )}{" "}
                            </>
                          ))}
                      </div>

                      {/* <SubscriptionChart
                        gData={graph(
                          getMetricsData.response.metrics[0].data,
                          "event_category",
                          "event_quantity"
                        )}
                      /> */}
                    </div>
                  ) : (
                    <>
                      <p className="text-white">No Metrics Yet</p>{" "}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tabular-data-card-icon-container">
        <button onClick={onEdit} className="mr-2  edit-btn  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tabular-data-card-edit-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </button>
        <button onClick={onDelete} className="delete-btn ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tabular-data-card-delete-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
export default TabularDataCardTabsView;
