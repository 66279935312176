// Package Imports

import { useEffect, React, useState } from "react";

// Project Imports
import Heading from "ui/shared/components/heading/Heading";
import DeFiLeaderboardTable from "./components/DeFiLeaderboardTable/DeFiLeaderboardTable";
import TopNFTCollectionTable from "./components/TopNFTCollectionTable";
import ApiURL from "core/constants/ApiEndPoints";
import { useAxios } from "core/hooks/useAxios";
import Loader from "ui/shared/components/loader/Loader";
import HttpMethod from "core/constants/ApiMethodConstants";
import "./Dashboard.css";
import { headingTitle } from "./Constants";
import  * as Constants from "core/constants/Constants";
import MarketPlaceTable from "./components/MarketPlaceActivitiesTable/MarketPlaceTable";

const Dashboard = (props) => {
  const getWalletLeaderboardDataList = useAxios();
  const getMarketPlaceActivitiesList = useAxios();
  const [walletLeaderboard, setWalletLeaderboard] = useState();
  const [marketplaceactivitesData, setMarketplaceactivitesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;

  useEffect(() => {
    getWalletLeaderboardData();
    getMarketPlaceActivities();
  }, []);
  const getWalletLeaderboardData = async () => {
    // setIsLoading(true);
    await getWalletLeaderboardDataList.postData({
      method: HttpMethod.GET,
      url: ApiURL.WALLET_LEADERBOARD_URL,
    });
    // setIsLoading(false);
  };
  useEffect(() => {
    if (getWalletLeaderboardDataList.response !== undefined) {
      if (getWalletLeaderboardDataList.response.data.length > 0)
        setWalletLeaderboard(getWalletLeaderboardDataList.response.data);
    }
  }, [getWalletLeaderboardDataList.response]);


  const getMarketPlaceActivities = async () => {
    // setIsLoading(true);
    await getMarketPlaceActivitiesList.postData({
      method: HttpMethod.GET,
      url: ApiURL.MARKET_PLACE_ACTIVITIES_URL,
    });
    // setIsLoading(false);
  };

  useEffect(() => {
    if (getMarketPlaceActivitiesList.response !== undefined) {
      if (getMarketPlaceActivitiesList.response.data.length > 0)
      setMarketplaceactivitesData(getMarketPlaceActivitiesList.response.data);
    }
  }, [getMarketPlaceActivitiesList.response]);
  return (
    <div className="c-Dashboard page-container ">
      <div className="heading-container">
        <Heading label={headingTitle.TOP_NFT_COLLECTION} />
      </div>
      <TopNFTCollectionTable />
      <div className="heading-container">
        <Heading label={headingTitle.NFT_HOLDERS_LEADERBOARD} />
      </div>
      <div className="mx-2">
        {loader}
        <DeFiLeaderboardTable walletData={walletLeaderboard} />
      </div>
      <div className="heading-container">
        <Heading label={headingTitle.MARKET_PLACE_ACTIVITIES} />
      </div>
      <div className="mx-2">
        <MarketPlaceTable data={marketplaceactivitesData}/> 
      </div>
    </div>
  );
};
export default Dashboard;
