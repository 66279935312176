import AuthContext from "context/AuthContext";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import * as RoutePaths from "core/constants/RoutePaths";
import Header from "ui/shared/components/header/Header";
import Footer from "ui/shared/components/footer/Footer";
import "index.css"
import WalletAuth from "ui/shared/components/auth/walletAuth";

export default function RequireAuth({ children }: { children: JSX.Element }) {
    const auth: any = {};
    let location = useLocation();

    if (false && !auth.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={RoutePaths.SIGNIN} state={{ from: location }} replace />;
    }

    return (<>
        <WalletAuth />

        <div>
            <Header />
            <div className="cal-footer-height">
                {children}
            </div>

        </div>
        <div>
            <Footer />
        </div>
    </>);
}
