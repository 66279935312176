// Package Imports
import { useState, useEffect } from "react";
import { Formik, Form as FormikForm, Field } from "formik";

//Project imports
import CdTextbox from "ui/shared/components/cdTextBox/CdTextbox";
import editicon from "assets/images/editicon.svg";
import Changepassword from "../ChangePassword/Changepassword";
import { yupSchema } from "./Validation";
import { useAxios } from "core/hooks/useAxios";
import { fetchUserData } from "./InitialValue";
import ApiURL from "core/constants/ApiEndPoints";
import CdButton from "ui/shared/components/cdButton/CdButton";
import Loader from "ui/shared/components/loader/Loader";
import HttpMethod from "core/constants/ApiMethodConstants";
import AlertModal from "ui/shared/components/alertModal/AlertModal";
import "./ProfilePage.css";

const ProfilePageUI = (props) => {
  const getUserProfileDataList = useAxios();
  const postUserProfileData = useAxios();
  const [userProfileData, setUserProfileData] = useState();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showStatus, setShowStatus] = useState("");
  const signinType = localStorage.getItem("signinType");
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;
  function handleChangeEditSubmit() {
    setEdit(!edit);
  }
  const [showModal, setShowModal] = useState(false);
  const onCloseChangeModal = () => {
    setShowModal(false);
  };
  const onSubmitModal = (status, changePasswordUpdateStatus) => {
    if (status) {
      setShowModal(false);
      setShowStatus(changePasswordUpdateStatus);
      setShowAlertModal(true);
    }
  };
  const onCloseAlertModal = () => {
    setShowAlertModal(false);
  };
  const [change, setChange] = useState();
  function handleChangeSubmit() {
    setChange(null);
    setShowModal(true);
  }
  const [onchange, setonchange] = useState(false);
  function handleChangeButton() {
    setonchange();
  }

  // const suffixicon = (
  //   <img
  //     className="edit-icon "
  //     src={editicon}
  //     alt=""
  //     onClick={handleChangeEditSubmit}
  //   />
  // );
  useEffect(() => {
    if (getUserProfileDataList.response !== undefined) {
      if (getUserProfileDataList.response.data.userid > 0)
        setUserProfileData(getUserProfileDataList.response.data);
    }
  }, [getUserProfileDataList.response]);

  useEffect(() => {
    getUserProfileData();
  }, []);

  const getUserProfileData = async () => {
    // setIsLoading(true);
    const userId = localStorage.getItem("UserId");
    await getUserProfileDataList.postData({
      method: HttpMethod.GET,
      url: ApiURL.USER_PROFILE_URL + "?id=" + userId,
    });
    // setIsLoading(false);
  };
  // async function onSubmit(profileData) {
  //   const userId = localStorage.getItem("UserId");
  //   console.log(userId)
  //   await postUserProfileData.postData({
  //     method: HttpMethod.PUT,
  //     url: ApiURL.USER_PROFILE_URL,
  //     headers: { "Content-Type": "application/json" },
  //     data: {
  //       name: profileData.name,
  //       email_address: profileData.email,
  //       userid: parseInt(userId),
  //       status: "ACTIVE",
  //     },
  //   });
  // }

  return (
    <div className="profilepage-content c-ProfilePage profile-page-body ">
      {loader}
      <div className="profile-page-card">
        {userProfileData && (
          <Formik
            initialValues={fetchUserData(userProfileData)}
            validateOnBlur={true}
            validateOnChange={false}
            validationSchema={yupSchema}

            // onSubmit={(values) => {
            //   onSubmit(values);
            // }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldError,
              submitForm,
            }) => (
              <FormikForm>
                <p className="profile-page-title">Profile</p>
                <div className="profile-page-form-container">
                  {signinType === "apiLogin" ? (
                    <Field
                      as={CdTextbox}
                      label="Name"
                      name="name"
                      disabled={!edit}
                      // icon={suffixicon}
                    />
                  ) : (
                    <Field
                      as={CdTextbox}
                      label="Name"
                      name="name"
                      disabled="disabled"
                    />
                  )}
                  <p className="validation-text">
                    {" "}
                    {errors.name && touched.name ? errors.name : ""}
                  </p>
                  <Field as={CdTextbox} label="Email" name="email" disabled />
                  <p className="validation-text">
                    {" "}
                    {errors.email && touched.email ? errors.email : ""}
                  </p>
                </div>
              </FormikForm>
            )}
          </Formik>
        )}

        <div className="button-container">
          {signinType === "apiLogin" ? (
            <button
              className="border-primary button-style"
              onClick={() => handleChangeSubmit()}
            >
              Change Password
            </button>
          ) : (
            <button
              className=" cd-button border-primary button-style"
              disabled={true}
              onClick={() => handleChangeSubmit()}
            >
              Change Password
            </button>
          )}

          {/* {signinType === "apiLogin" ?(<button
            className="custom-gradient submit-button-style"
            onClick={() => onSubmit()}
          >
            Update
          </button>):<button
            className=" cd-button custom-gradient submit-button-style"
            disabled={true}
            onClick={() => onSubmit()}
          >
            Update
          </button>} */}

          {/* {signinType === "apiLogin" ?( <CdButton
            type="submit"
            label="Update"
            className="custom-gradient submit-button-style "
          >
            Update
          </CdButton>):<CdButton
            type="submit"
            label="Update"
            disabled={true}
            className="cd-button custom-gradient submit-button-style "
          >
            Update
          </CdButton>} */}
        </div>
      </div>
      {showModal ? (
        <Changepassword
          showModal={showModal}
          onClose={onCloseChangeModal}
          onSubmit={onSubmitModal}
        />
      ) : undefined}
      {showAlertModal ? (
        <AlertModal
          title="Status"
          showDescription={showStatus}
          showModal={showAlertModal}
          onClose={onCloseAlertModal}
          onSubmit={onSubmitModal}
        />
      ) : undefined}
    </div>
  );
};

export default ProfilePageUI;
