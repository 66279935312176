// Package Imports
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

// Project Imports
import { MonitorImage } from "core/constants/Images";
import Heading from "ui/shared/components/heading/Heading";
import TabularDataCard from "ui/shared/components/tabularDataCard/TabularDataCard";
import CdButton from "ui/shared/components/cdButton/CdButton";
import { useAxios } from "core/hooks/useAxios";
import AddMonitoringandAlertModal from "./components/AddMonitoringandAlertModal/AddMonitoringandAlertModal";
import DeleteModal from "ui/shared/components/deleteModal/DeleteModal";
import AlertModal from "ui/shared/components/alertModal/AlertModal";
import { Plusicon } from "core/constants/Images";
import ApiURL from "core/constants/ApiEndPoints";
import Loader from "ui/shared/components/loader/Loader";
import "./MonitorsAndAlerts.css"
import {
  DeletionSuccess,
  ErrorMsg,
  MonitorAlertDelete,
} from "core/constants/Constants";
import HttpMethod from "core/constants/ApiMethodConstants";
import { UseMonitoringAndAlertsData } from "core/hooks/useMonitoringAndAlertsData";
import Pagination from "../Pagination/Pagination";
import TabularDataCardTabsView from "ui/shared/components/tabularDataCard/TabularDataCardTabsView";

const MonitorsAndAlerts = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showStatus, setShowStatus] = useState("");
  const [monitorData, setMonitorData] = useState([]);
  const [selectedAlert, setselectedAlert] = useState();
  const getMonitorDataList = useAxios();
  const deleteMonitorDataList = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;

  let size = 5;
  let currentPage = parseInt(
    new URLSearchParams(window.location.search).get("page") || "1"
  );
  const [page, setPage] = useState(currentPage - 1 || 0);

  const [monitoringAndAlertsList, fetchData, paginationData] = UseMonitoringAndAlertsData(

    page,
    size
  );
  useEffect(() => {
    // console.log("filterObj", filterParam);
    // fetchData(page, size);
    fetchMonitorinfData()
  }, [page]);

  function fetchMonitorinfData() {
    fetchData(page, size);
  }


  // function onSearchClick(obj) {
  //   console.log("SearchObj", obj);
  //   setFilterObj(obj);
  //   setPage(0);
  // }

  function onPageChange(pageNumber) {
    setPage(pageNumber);
  }


  useEffect(() => {
    if (monitoringAndAlertsList !== undefined) {
      setMonitorData(monitoringAndAlertsList.data);
    }
  }, [monitoringAndAlertsList]);

  // useEffect(() => {
  //   getAllMonitoringAlertsData();
  // }, []);

  // const getAllMonitoringAlertsData = async () => {
  //   setIsLoading(true);
  //   await getMonitorDataList.postData({
  //     method: HttpMethod.GET,
  //     url: ApiURL.MONITOR_URL,
  //   });
  //   setIsLoading(false);
  // };

  const metricsApiCall = (subscription_id) => {
    console.log("MonitorID>>", subscription_id);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };
  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const onCloseAlertModal = () => {
    setShowAlertModal(false);
    fetchMonitorinfData()
  };
  const onEditAlert = (item) => {
    setselectedAlert(item);
    setShowModal(true);
  };
  const onDeleteAlert = (item) => {
    setselectedAlert(item);
    setShowDeleteModal(true);
  };
  const onSubmitModal = (status, showAddUpdateStatus) => {
    setShowModal(false);
    if (status) {
      setselectedAlert(null);
    }
    setShowStatus(showAddUpdateStatus);
    setShowAlertModal(true);
    // getAllMonitoringAlertsData();
  };

  const onAddAlertClick = () => {
    setselectedAlert(null);
    setShowModal(true);
  };

  const onAlertDeletion = async (item) => {
    // setIsLoading(true);
    await deleteMonitorDataList.postData({
      method: HttpMethod.DELETE,
      url: ApiURL.MONITOR_URL,
      data: {
        monitor_id: item.monitor_id,
      },
    });
    setShowDeleteModal(false);
    // getAllMonitoringAlertsData();
    // setIsLoading(false);
  };
  useEffect(() => {
    if (deleteMonitorDataList.response !== undefined) {
      if (deleteMonitorDataList.response.status === DeletionSuccess) {
        setShowStatus(MonitorAlertDelete);
        setShowAlertModal(true);
      } else {
        setShowStatus(ErrorMsg);
        setShowAlertModal(true);
      }
    }
  }, [deleteMonitorDataList.response]);

  return (
    <>
      {/* MonitorsAndAlerts-Start */}
      <div className="c-MonitorsAndAlerts monitorsAndAlerts">
        {loader}
        <div className="monitorsAndAlerts-container">
          <Heading label="Monitoring & Alerts" logo={MonitorImage} />
        </div>
        <hr className="monitorsAndAlerts-border" />
        <div className="monitorsAndAlerts-flex">
          <CdButton
            label="Add Monitoring & Alerts"
            icon={Plusicon}
            onClick={() => onAddAlertClick()}
          />
        </div>
        <div className="monitorsAndAlerts-card">
          <div className="monitorsAndAlerts-icon">
            {monitorData.map((item) => (
              <div key={item.monitor_id} className="monitorsAndAlerts-tabular-data">
                <TabularDataCardTabsView
                  keyFor="Monitoring"
                  metricsApiCall={() => metricsApiCall(item.monitor_id)}
                  item={item}
                  onEdit={() => onEditAlert(item)}
                  onDelete={() => onDeleteAlert(item)}
                />
              </div>
            ))}
          </div>
          <div className="mt-tmd">
            <Pagination
              currentPage={paginationData.page}
              total={paginationData.total}
              size={paginationData.size}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
      <div>
        {showModal ? (
          <AddMonitoringandAlertModal
            showModal={showModal}
            onClose={onCloseModal}
            onSubmit={onSubmitModal}
            item={selectedAlert}
          />
        ) : undefined}
        {showDeleteModal ? (
          <DeleteModal
            title="Delete Monitoring & Alerts"
            showModal={showDeleteModal}
            onClose={onCloseDeleteModal}
            onSubmit={onAlertDeletion}
            item={selectedAlert}
          />
        ) : undefined}
        {showAlertModal ? (
          <AlertModal
            title="Status"
            showDescription={showStatus}
            showModal={showAlertModal}
            onClose={onCloseAlertModal}
            onSubmit={onAlertDeletion}
            item={selectedAlert}
          />
        ) : undefined}
      </div>

      {/* MonitorsAndAlerts-end */}
    </>
  );
};
export default MonitorsAndAlerts;
