
const InitialValue= {
    firstName:"",
    lastName:"",
    email:"",
    contact:"",
    details:"",
   
   }
   
   export default InitialValue;