// Package Imports
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { Formik, Form as FormikForm, Field } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// project imports
import { useAxios } from "core/hooks/useAxios";
import { GoogleLogo } from "core/constants/Images";
import { CompanyLogo } from "core/constants/Images";
import CdTextbox from "ui/shared/components/cdTextBox/CdTextbox";
import CdButton from "ui/shared/components/cdButton/CdButton";
import initialValue from "./InitialValue";
import { yupSchema } from "./Validation";
import ApiURL from "core/constants/ApiEndPoints";
import CdPasswordTextField from "ui/shared/components/CdPasswordTextField";
import Loader from "ui/shared/components/loader/Loader";
import { clientId } from "core/constants/Constants";
import * as RoutePaths from "core/constants/RoutePaths";
import HttpMethod from "core/constants/ApiMethodConstants";
import "./Login.css";
import LoginType from "core/constants/LoginType";
import AlertModal from "ui/shared/components/alertModal/AlertModal";
import StatusMsg, { ApiResponseStatus } from "./Constants";
import  * as Constants from "core/constants/Constants";

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";

function Login(props) {
  const navigate = useNavigate();
  const postLoginData = useAxios();
  const postGoogleLoginData = useAxios();
  const [showAlertModal, setShowAlertModal] = useState(false);
  var [statusMsg, setStatusMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;
  const { select } = useWallet();

  function goToDashboard() {
    navigate(RoutePaths.DASHBOARD);
  }

  // useEffect(() => {
  //   // select('Phantom')
  // }, []);
  function goToLandingPage() {
    navigate(RoutePaths.ROOT);
  }
  const onLoginFailure = (res) => {
    // console.log("Login Failed:", res);
  };
  const onCloseModal = () => {
    setShowAlertModal(false);
  };

  async function onLoginSubmitClick(loginData) {
    // setIsLoading(true);
    await postLoginData.postData({
      method: HttpMethod.POST,
      url: ApiURL.SIGN_IN_URL,
      headers: { "Content-Type": "application/json" },
      data: {
        emailaddress: loginData.username,
        password: loginData.password,
      },
    });
    // setIsLoading(false);
  }
  async function onGoogleLoginSuccess(res) {
    // setIsLoading(true);
    await postGoogleLoginData.postData({
      method: HttpMethod.POST,
      url: ApiURL.USER_PROFILE_URL,
      headers: { "Content-Type": "application/json" },
      data: {
        token_type: res.tokenObj.token_type,
        access_token: res.tokenObj.access_token,
        scope: res.tokenObj.scope,
        login_hint: res.tokenObj.login_hint,
        expires_in: res.tokenObj.expires_in,
        id_token: res.tokenObj.id_token,
        session_state: res.tokenObj.session_state,
        type: LoginType.googleLogin,
      },
    });
    // setIsLoading(false);
  }

  useEffect(() => {
    if (
      postLoginData.response &&
      postLoginData.response.data &&
      postLoginData.response.data.status.toLowerCase() ===
        ApiResponseStatus.SUCCESS_RESPONSE
    ) {
      localStorage.setItem("UserId", postLoginData.response.data.userid);
      localStorage.setItem("signinType", "apiLogin");
      localStorage.setItem("Token", postLoginData.response.data.token);
      // localStorage.setItem(Constants.AUTH_TOKEN, "342342424324255"); // hardcoded token
      goToDashboard();
    } else if (
      postLoginData.response &&
      postLoginData.response.data &&
      postLoginData.response.data.status.toLowerCase() ===
        ApiResponseStatus.FAILED_RESPONSE
    ) {
      setShowAlertModal(true);
      setStatusMsg(StatusMsg.LOGIN_FAILED_MSG);
    }
  }, [postLoginData.response]);

  useEffect(() => {
    if (
      postGoogleLoginData.response &&
      postGoogleLoginData.response.status.toLowerCase() === "success"
    ) {
      localStorage.setItem("signinType", "googleLogin");
      localStorage.setItem(
        "UserId",
        postGoogleLoginData.response.message.userid
      );
      localStorage.setItem(
        "Token",
        postGoogleLoginData.response.message.token
      );
      goToDashboard();
    }
  }, [postGoogleLoginData.response]);
  return (
    <>
      {loader}
      <div className="c-LoginPage login-body">
        <div className="company-image">
          <img
            src={CompanyLogo}
            className="company-image-style"
            alt="graph"
            onClick={goToLandingPage}
          />
        </div>

        <div className="login-card">
          <Formik
            initialValues={initialValue}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={yupSchema}
            onSubmit={(values) => {
              onLoginSubmitClick(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldError,
              submitForm,
            }) => (
              <FormikForm>
                <div className="form-username-container">
                  <Field as={CdTextbox} label="Username" name="username" />
                  <p className="text-red-600">
                    {errors.username && touched.username ? errors.username : ""}
                  </p>
                </div>
                <div className="form-password-container">
                  <Field
                    as={CdPasswordTextField}
                    label="Password"
                    name="password"
                  />
                  <p className="text-red-600 ">
                    {errors.password && touched.password ? errors.password : ""}
                  </p>
                </div>
                <div className="submit-button">
                  <CdButton label="Submit" type="submit" />
                </div>

                <div className="login-option-container">OR</div>
                <div className="login-option-container flex justify-center">
                  <WalletMultiButton></WalletMultiButton>
                </div>
                <div className="login-option-container">OR</div>
                <div className="login-option-container">
                  <GoogleLogin
                    clientId={clientId}
                    render={(renderProps) => (
                      <button
                        className="google-signin"
                        onClick={renderProps.onClick}
                      >
                        <img
                          src={GoogleLogo}
                          alt="googlelogo"
                          className="w-72 mx-2"
                        />
                      </button>
                    )}
                    onSuccess={onGoogleLoginSuccess}
                    onFailure={onLoginFailure}
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={true}
                  />

                  <div className="go-to-registration-container">
                    New User?
                    <span className="go-to-registration-column">
                      <Link
                        className="go-to-registration-text"
                        to={RoutePaths.REGISTRATION}
                      >
                        Click here
                      </Link>
                    </span>
                    to Register
                  </div>
                  <div className="go-to-landing-page-container">
                    Go Back To
                    <span className="go-to-landing-page-column">
                      <Link
                        className="go-to-landing-page-text"
                        to={RoutePaths.ROOT}
                      >
                        Landing Page
                      </Link>
                    </span>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>

        {showAlertModal && (
          <AlertModal
            label="Go to Login"
            showModal={showAlertModal}
            onClose={onCloseModal}
            showDescription={statusMsg}
          />
        )}
      </div>
    </>
  );
}
export default Login;
