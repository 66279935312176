import NftRareRankingRowItem from "./NftRareRankingRowItem";

function NftRareRankingRow(props) {
  const { nftRareRankingTableData } = props;
  return (
    <div className="relative overflow-x-auto rounded-lg">
        <div className="relative overflow-x-auto shadow-md rounded-lg bg-gray-900">
      <table className="w-full text-sm text-left ">
        <thead className="text-lg border bg-gray-800 border-gray-900 text-white  ">
          <tr className="w-full">
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-white sm:pl-6 font-medium"
            >
              Collection
            </th>
          </tr>
        </thead>
        <tbody className=" bg-gray-700 text-white font-bold block">
          {nftRareRankingTableData &&
            nftRareRankingTableData.map((item, index) => (
              <NftRareRankingRowItem
                key={index}
                NftRareRankingTableItem={item}
              />
            ))}
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default NftRareRankingRow;
