// Package Imports

import * as Yup from "yup";

export const yupSchema = () => {
  let validation = {};
  validation = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Password must be 8 characters at minimum")
      .required("Enter new password ")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string()
      .required("Please confirm password")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });
  return validation;
};
