//Package Imports
import { CompanyIcon } from "core/constants/Images";
import { Link } from "react-router-dom";
import React from "react";

//Project Imports
import "./NftCollectionCard.css";
import * as RoutePaths from "core/constants/RoutePaths";
import { convertToDecimal } from "core/utils/ConvertToDecimal";

function RareRankingCollectionCard(props) {
  const { item } = props;

  return (
    <div>
      <Link to={RoutePaths.NFT_RARE_RANKING + "?collection=" + item.Collection}>
        <div className="c-nftCollectionCard tabular-data-card-body">
          {/* <div> */}
          <div>
            <img
              className="tabular-data-card-image "
              src={CompanyIcon}
              alt=""
            />
          </div>

          <div className="tabular-data-card-card ">
            <h3 className="tabular-data-card-title">{item.Collection}</h3>
          </div>
          <div className="tabular-data-card-container">
            <div className="tabular-data-card-col">
              <div className="tabular-data-card-col-container ">
                <label htmlFor="Items" className="tabular-data-card-label text-sky-400">
                  Items :
                </label>
                <p className="tabular-data-card-label-data">{item.Items}</p>
              </div>
              <div className="tabular-data-card-col-container">
                <label htmlFor="Items" className="tabular-data-card-label text-sky-400">
                  Holders :
                </label>
                <p className="tabular-data-card-label-data">{item.holders}</p>
              </div>
              <div className="tabular-data-card-col-container">
                <label
                  htmlFor="Floor Price"
                  className="tabular-data-card-label text-sky-400"
                >
                  Floor Price :
                </label>
                <p className="tabular-data-card-label-data">{convertToDecimal(item["floorprice-avg"],4)}</p>
              </div>

              <div className="tabular-data-card-col-container">
                <label htmlFor="On Sale" className="tabular-data-card-label text-sky-400">
                  On Sale
                </label>
                <p className="tabular-data-card-label-data">-</p>
              </div>
              <div className="tabular-data-card-col-container">
                <label htmlFor="On Sale %" className="tabular-data-card-label text-sky-400">
                  On Sale %
                </label>
                <p className="tabular-data-card-label-data">
                {item.onsale_percentage ? (convertToDecimal(item.onsale_percentage,4)) : "-"}
                </p>
              </div>

              <div className="tabular-data-card-col-container">
                <label
                  htmlFor="Floor MC USD"
                  className="tabular-data-card-label text-sky-400"
                >
                  Floor MC USD
                </label>
                <p className="tabular-data-card-label-data">-</p>
              </div>
            </div>
          </div>

          {/* </div> */}
          {/* </div> */}
        </div>
      </Link>
    </div>
  );
}

export default RareRankingCollectionCard;
