
const CdTextarea = (props) => {  
  const {label} = props
  return (
      <div className="relative z-0  w-full m-auto">
        <textarea
          type="text"
          placeholder=" " 
          className="pt-4 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 text-white focus:border-white border-gray-200"
          {...props}
        />
        <label
          className="absolute duration-300 top-3 -z-1 origin-0 transform  text-white text-xl font-medium"
        >
          {label}
        </label>
      </div>      
  );
};

export default CdTextarea;
