export const SUCCESS_RESPONSE = "success";
export const FAILED_RESPONSE = "failed";
export const ApiResponseStatus = {
  SUCCESS_RESPONSE,
  FAILED_RESPONSE,
};
/**
 * For popup msg
 */
export const LOGIN_FAILED_MSG = "Wrong Username Or Password";
const StatusMsg = {
  LOGIN_FAILED_MSG,
};
export default StatusMsg;
