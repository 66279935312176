// Package imports
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ChevronUpIcon } from "@heroicons/react/solid";
import "./Accordian.css";

const Accordion = (props) => {
  const [isActive, setIsActive] = useState(false);
  const { title, icon, active } = props;

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div className="accordion-item">
      <div
        className="accordion-title c-Accordian title-container"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="c-Accordian title ">
          {icon && (
            <img
              className="title-icon "
              src={icon}
              alt="icon"
            />
          )}
          <span className="title-icon-col">{title}</span>
        </div>
        <div>
          {isActive ? (
            <ChevronUpIcon className="rotatedown chevron" />
          ) : (
            <ChevronUpIcon className="rotateup chevron" />
          )}
        </div>
      </div>
      {isActive && (
        <div className="c-Accordian accordian-body ">{props.children}</div>
      )}
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  active: PropTypes.bool,
};
Accordion.defaultProps = {
  active: false,
};
