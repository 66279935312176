//Package Imports
import React from "react";
import { protectedString } from "core/utils/ProtectedString";
import "./DetailsCard.css"

const NftTimelineDetailsCard = (props) => {
  const { Details } = props;
  return (
    <div className="c-DetailsCard body">
      <div className="details-container">
        <p >Creator</p>
        <p>{protectedString(Details.address)}</p>
      </div>
      <div className="c-DetailsCard details-container">
        <p>Share</p>
        <p>{Details.share}</p>
      </div>
    </div>
  );
};
export default NftTimelineDetailsCard;
