//Package Imports
import React from "react";

// Project imports
import "./CdButton.css";

const CdButton = (props) => {
  const { icon, label } = props;
  return (
    <button
      className={`${
        props.type === "outline"
          ? " border-primary c-CdButton cdbutton"
          : " custom-gradient c-CdButton cdbutton-gradient"
      }`}
      {...props}
    >
      <div className="cdbutton-icon-container">
        {icon && <img className="cdbutton-icon-style" src={icon} alt="icon" />}

        <p className="cdbutton-label">{label}</p>
      </div>
    </button>
  );
};
export default CdButton;
