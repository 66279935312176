
import { useWallet } from "@solana/wallet-adapter-react";
import { useAxios } from "core/hooks/useAxios";
import HttpMethod from "core/constants/ApiMethodConstants";
import ApiURL from "core/constants/ApiEndPoints";
import { useEffect } from "react";
import * as RoutePaths from "core/constants/RoutePaths";
import { useNavigate } from "react-router-dom";

function WalletAuth() {
    const navigate = useNavigate();

    const postLoginData = useAxios();
    const { publicKey } = useWallet()

    useEffect(() => {
        // console.log('publicKey', publicKey)
        if (publicKey && !localStorage.getItem("UserId")) {
            // console.log('publicKey', publicKey)
            onLoginViaWallet({
                "type": "wallet",
                "wallet_type": "phantom",
                "pubkey": publicKey.toString()
            })
        }
        }, [publicKey])

    useEffect(() => {
        if (
            postLoginData.response &&
            postLoginData.response &&
            postLoginData.response.status.toLowerCase() === "success"
        ) {
            // console.log(postLoginData.response)
            localStorage.setItem("UserId", postLoginData.response.message.userid);
            localStorage.setItem("Token", postLoginData.response.message.token);
            localStorage.setItem("signinType", "walletLogin");
            goToDashboard()
        }
    }, [postLoginData.response]);

    function goToDashboard() {
        navigate(RoutePaths.DASHBOARD);
      }

    async function onLoginViaWallet(loginData) {
        await postLoginData.postData({
            method: HttpMethod.POST,
            url: ApiURL.USER_PROFILE_URL,
            headers: { "Content-Type": "application/json" },
            data: loginData,
        });
    }
    return (
        <div></div>
    )
}

export default WalletAuth