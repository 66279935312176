// Packages Imports
import React, { useContext, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

// Project Imports

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NftRareRankingDropdown(props) {
  const { label, optionList, selectedValue, onSelectMenuChange } = props;

  const [selected, setSelected] = useState();

  useEffect(() => {
    if (selectedValue) {
      // console.log("selected",selectedValue);
      setSelected(optionList.find((obj) => obj.name === selectedValue));
    }
  }, [selectedValue]);

  const capitalizeFunction = (string) => {
    let newStringlist = string.split("_");
    newStringlist = newStringlist.map((singleSting) => {
      let value = singleSting.slice(0, 1).toUpperCase() + singleSting.slice(1);
      return value;
    });
    return newStringlist.join(" ");
  };

  const dropDownOptionElements = optionList.map((item, index) => {
    return (
      <Listbox.Option
        key={item.name}
        className={({ active }) =>
          classNames(
            active ? "font-bold" : "font-normal ",
            index < optionList.length - 1 ? "border-b border-white" : "",
            "p-3 cursor-pointer "
          )
        }
        // value={item.name.replace("_"," ")}
        value={item}
      >
        {({ selected, active }) => (
          <>
            <span
              className={classNames(
                selected ? "font-semibold" : "font-normal",
                "block truncate text-white "
              )}
            >
              {item.label}
            </span>
          </>
        )}
      </Listbox.Option>
    );
  });

  function onSelectionChange(selectedItem) {
    setSelected(selectedItem);
    onSelectMenuChange(selectedItem);
  }

  return (
    <Listbox value={selected || "Search By"} onChange={onSelectionChange}>
      {({ open }) => (
        <div className=" relative w-full m-auto h-full">
          <Listbox.Label className=" text-white text-xl">{label}</Listbox.Label>
          <Listbox.Button
            value={selected ? selected.label : ""}
            className={classNames(
              selected?.label ? "text-white" : "text-transparent",
              "appearance-none bg-transparent  border-r-2 h-full  rounded-l-lg border-sky-500 focus:outline-none focus:ring-0 mt-0 pb-2 pt-3 px-0 w-full cursor-pointer text-left flex justify-between"
            )}
          >
            <div
              className={classNames(
                " truncate pl-2 text-white w-full flex justify-between"
              )}
            >
              {selected ? selected.label : "Search By"}
              <div className="px-2 ">
                <ChevronDownIcon className="text-white w-6 h-6" />
              </div>
            </div>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="mt-1 z-10 absolute w-full bg-gray-800 text-white shadow-lg h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm  "
            >
              {dropDownOptionElements}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export default NftRareRankingDropdown;
