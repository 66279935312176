// Package Imports

import  React from 'react'
import * as Yup from "yup";

export const yupSchema = () => {
  let validation = {}
  validation = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email:Yup.string().required('Please enter email'),
  });
  return validation
};


