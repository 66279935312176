import moment from 'moment';
import React from 'react'
import "./MarketPlaceActivitiesRowItem.css";

const MarketPlaceRowItem = ({item}) => {
  return (
    <tr className="c-MarketPlaceActivitiesRowItem container">
      <td className="wallet-table-data flex-cols-2">
        {item.market_place}
      </td>
      <td className="sol-table-data">
      {moment(item.activity_date).format("DD MMM YYYY")}
      </td>
      <td className="sol-table-data">
        {item.activity_count}
      </td>
      <td className="sol-table-data">
        {item.Minted}
      </td>
      <td className="sol-table-data">
        {item.Edition}
      </td>
      <td className="sol-table-data">
        {item.Listed}
      </td>
      <td className="sol-table-data">
        {item.Bid}
      </td>
      <td className="sol-table-data">
        {item.Cancel}
      </td>
      <td className="sol-table-data">
        {item.Redeem}
      </td>
      <td className="sol-table-data">
        {item.Buy}
      </td>

    </tr>
  )
}

export default MarketPlaceRowItem