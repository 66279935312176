export const ROOT = "/";
export const SIGNIN = "/login";
export const DASHBOARD = "/dashboard";
export const SUBSCRIPTION = "/subscription";
export const MONITORS_AND_ALERTS = "/monitors-and-alerts";
export const REGISTRATION = "/registration";
export const PROFILEPAGEUI = "/profile";
export const NFT_TIMELINE = "/nft-timeline";
export const ABOUTUS = "/about-us";
export const CONTACTUS = "/contact-us";
export const NFT_RARE_RANKING = "/nft-rare-ranking";
export const NFT_RARE_RANKING_TABLE = "/nft-rare-ranking-table";
