//Package Imports
import React from "react";

//Project Imports
import { CompanyIcon } from "core/constants/Images";
import { protectedString } from "core/utils/ProtectedString";
import "./TabularDataCard.css"
import SubscriptionChart from "ui/view/subscription/SubscriptionChart";


function TabularDataCard(props) {
  const { item, onEdit, onDelete } = props;

  return (
    <div className="c-TabularDataCard tabular-data-card-body">
        <div>
          <img
          className="c-TabularDataCard tabular-data-card-image "
          src={item.image_url.length === 0? CompanyIcon  : item.image_url }
          alt=""
        />
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1  ">
        <div className="tabular-data-card-card flex-col md:col-span-1  col-span-1 md:border-r-2 border-b-2 md:border-b-0">
          <h3 className="tabular-data-card-title">
            {item.name}
          </h3>
          <div>
            <p className="tabular-data-card-description ">
              {item.description}
            </p>
          </div>

          <div className="tabular-data-card-col ">
            <div className="tabular-data-card-col-container">
              <label
                htmlFor="Delivery-input"
                className="tabular-data-card-label"
              >
                Delivery Method :-
              </label>
              <p className="tabular-data-card-label-data">
                {item.delivery_method}
              </p>
            </div>
            <div className="tabular-data-card-col-container">
              <label
                htmlFor="frequency-input"
                className="tabular-data-card-label"
              >
                Frequency :-
              </label>
              <p className="tabular-data-card-label-data">
                {item.frequency}
              </p>
            </div>
          </div>
          <div className="tabular-data-card-query-container">
            <label
              htmlFor="Query"
              className="tabular-data-card-label"
            >
              Query :-
            </label>
            <p className="tabular-data-card-label-data  truncate">
              {item.query}
            </p>
          </div>
        </div>
        <div className="pie-chart-data md:col-span-1 mx-auto">
          < SubscriptionChart />
        </div>
        </div>

      <div className="tabular-data-card-icon-container">
        <button onClick={onEdit} className="mr-2  edit-btn  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tabular-data-card-edit-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </button>
        <button onClick={onDelete} className="delete-btn ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tabular-data-card-delete-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default TabularDataCard;
