// Package imports
import { useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { useNavigate, Link } from "react-router-dom";

// Project imports
import CdTextbox from "ui/shared/components/cdTextBox/CdTextbox";
import CdButton from "ui/shared/components/cdButton/CdButton";
import { useAxios } from "core/hooks/useAxios";
import AlertModal from "ui/shared/components/alertModal/AlertModal";
import * as RoutePaths from "core/constants/RoutePaths";
import { CompanyLogo } from "core/constants/Images";
import ApiURL from "core/constants/ApiEndPoints";
import CdPasswordTextField from "ui/shared/components/CdPasswordTextField";
import initialValue from "./InitialValue";
import { yupSchema } from "./Validation";
import HttpMethod from "core/constants/ApiMethodConstants";
import StatusMsg from "./Constants";
import "./Registration.css";
import LoginType from "core/constants/LoginType";

function Registration(props) {
  const navigate = useNavigate();
  const postRegistrationData = useAxios();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  var [statusMsg, setStatusMsg] = useState("");
  const [showBtn, setShowBtn] = useState("");

  const [showAlertModal, setShowAlertModal] = useState(false);

  function onUserNameChange(event) {
    setName(event.target.value);
  }
  function onUserEmailChange(event) {
    setEmail(event.target.value);
  }
  function onUserPasswordChange(event) {
    setPassword(event.target.value);
  }
  function onUserConfirmPasswordChange(event) {
    setconfirmPassword(event.target.value);
  }
  function goToLogin() {
    navigate(RoutePaths.SIGNIN);
  }
  function goToLandingPage() {
    navigate(RoutePaths.ROOT);
  }

  const onCloseModal = () => {
    setShowAlertModal(false);
  };

  async function onSubmit(registerData) {
    await postRegistrationData.postData({
      method: HttpMethod.POST,
      url: ApiURL.USER_PROFILE_URL,
      headers: { "Content-Type": "application/json" },
      data: {
        name: registerData.name,
        email_address: registerData.email,
        password: registerData.password,
        confirmPassword: registerData.confirmPassword,
        type: LoginType.localLogin,
      },
    });
  }

  useEffect(() => {
    if (postRegistrationData.response !== undefined) {
      if (
        postRegistrationData.response &&
        postRegistrationData.response.status.toLowerCase() ===
          StatusMsg.successResponse
      ) {
        setShowAlertModal(true);
        setStatusMsg(StatusMsg.creationSuccessMsg);
        setShowBtn(true);
      } else if (
        postRegistrationData.response &&
        postRegistrationData.response.status.toLowerCase() ===
          StatusMsg.creationFailedMsg
      ) {
        setShowAlertModal(true);
        setStatusMsg(postRegistrationData.response.message);
        setShowBtn(false);
      }
    }
  }, [postRegistrationData.response]);

  return (
    <div className="c-RegistrationPage registration-body">
      <div className="company-image">
        <img src={CompanyLogo} className="company-image-style" alt="company-logo" onClick={goToLandingPage}/>
      </div>
      <div className="reg-card">
        <Formik
          initialValues={initialValue}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldError,
            submitForm,
          }) => (
            <FormikForm>
              <div className="form-data-container">
                <Field as={CdTextbox} label="Name" name="name"></Field>
                <p className="validation-text">
                  {errors.name && touched.name ? errors.name : ""}
                </p>
              </div>
              <div className="form-data-container">
                <Field
                  as={CdTextbox}
                  label="Email"
                  value={values.email}
                  name="email"
                ></Field>
                <p className="validation-text">
                  {errors.email && touched.email ? errors.email : ""}
                </p>
              </div>
              <div className="form-data-container">
                <Field
                  as={CdPasswordTextField}
                  label="Password"
                  name="password"
                />
                <p className="validation-text">
                  {errors.password && touched.password ? errors.password : ""}
                </p>
              </div>
              <div className="form-data-container">
                <Field
                  as={CdPasswordTextField}
                  label="Confirm Password"
                  name="confirmPassword"
                />
                <p className="validation-text">
                  {errors.confirmPassword && touched.confirmPassword
                    ? errors.confirmPassword
                    : ""}
                </p>
              </div>
              <div className="button-area">
                <CdButton label="Register" type="submit" />
              </div>
              <div className="go-to-login-container">
                Already Registered?
                <span className="go-to-login-column">
                  <Link
                    className="go-to-login-text"
                    to={RoutePaths.SIGNIN}
                  >
                    Go to Login
                  </Link>
                </span>
              </div>
              <div className="go-to-landing-page-container">
               Go Back To
                <span className="go-to-landing-page-column">
                  <Link
                    className="go-to-landing-page-text"
                    to={RoutePaths.ROOT}
                  >
                    Landing Page
                  </Link>
                </span>
              </div>
              
            </FormikForm>
          )}
        </Formik>
      </div>

      {showAlertModal ? (
        <AlertModal
          label="Go to Login"
          showModal={showAlertModal}
          onClose={onCloseModal}
          showDescription={statusMsg}
          buttonDisplay={showBtn}
          onButtonClick={goToLogin}
        />
      ) : undefined}
    </div>
  );
}

export default Registration;
