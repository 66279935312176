// Package Imports
import react from "react";
import { useState, useEffect } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
//Project Imports
import CdButton from "ui/shared/components/cdButton/CdButton";
import CdModal from "ui/shared/components/CdModal";
import CdTextbox from "ui/shared/components/cdTextBox/CdTextbox";
import { yupSchema } from "./Validation";
import initialValue from "./InitialValue";
import HttpMethod from "core/constants/ApiMethodConstants";
import ApiURL from "core/constants/ApiEndPoints";
import { useAxios } from "core/hooks/useAxios";
import CdPasswordTextField from "ui/shared/components/CdPasswordTextField";
import ChangePasswordStatusMsg from "./Constants";
import "./ChangePassword.css";

const Changepassword = (props) => {
  const { onClose, showModal, onSubmit } = props;
  const postChangePasswordData = useAxios();

  async function handlechangePasswordSubmit(changepassword) {
    const userId = localStorage.getItem("UserId");
    await postChangePasswordData.postData({
      method: HttpMethod.POST,
      url: ApiURL.UPDATE_PASSWORD,
      headers: { "Content-Type": "application/json" },
      data: {
        userid: parseInt(userId),
        password: changepassword.newPassword,
      },
    });
  }
  const onCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    if (postChangePasswordData.response !== undefined) {
      let statusMsg = "";
      if (postChangePasswordData.response.status) {
        if (
          postChangePasswordData.response.status.toLowerCase() ===
          ChangePasswordStatusMsg.ChangePasswordsuccessResponse
        ) {
          statusMsg = ChangePasswordStatusMsg.UpdateSuccessMsg;
        } else {
          statusMsg = ChangePasswordStatusMsg.CreationFailedMsg;
        }
      } else {
        statusMsg = ChangePasswordStatusMsg.ErrorMsg;
      }
      onSubmit(true, statusMsg);
    }
  }, [postChangePasswordData]);
  return (
    <div>
      <Formik
        initialValues={initialValue}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={yupSchema}
        onSubmit={(values) => {
          handlechangePasswordSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldError,
          submitForm,
        }) => (
          <FormikForm>
            <CdModal
              title="Change Password"
              showModal={showModal}
              onClose={onCloseModal}
              onSubmit={handlechangePasswordSubmit}
              btnList={[
                {
                  label: "Change Password",
                  type: "submit",
                  disabled: false,
                },
              ]}
            >
              <div className="c-ChangePassword changePassword-container">
                <div className="changePassword-form-container">
                  <Field
                    as={CdPasswordTextField}
                    label="New Password"
                    name="newPassword"
                    value={values.newPassword}
                  ></Field>
                  <p className="validation-text">
                    {errors.newPassword && touched.newPassword
                      ? errors.newPassword
                      : ""}
                  </p>
                </div>
                <div className="changePassword-form-container">
                  <Field
                    as={CdPasswordTextField}
                    label="Confirm Password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                  ></Field>
                  <p className="validation-text">
                    {errors.confirmPassword && touched.confirmPassword
                      ? errors.confirmPassword
                      : ""}
                  </p>
                </div>
              </div>
            </CdModal>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
export default Changepassword;
