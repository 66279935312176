export const ChangePasswordsuccessResponse = "update success";
export const UpdateSuccessMsg = "Password Updated Successfully";
export const CreationFailedMsg = "Creation Failed";
export const ErrorMsg = "Something Went Wrong";
const ChangePasswordStatusMsg = {
  ChangePasswordsuccessResponse,
  UpdateSuccessMsg,
  CreationFailedMsg,
  ErrorMsg,
};
export default ChangePasswordStatusMsg;
