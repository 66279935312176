// Package Imports
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, Field } from "formik";
//Project Imports
import { fetchInitialValues } from "../../../view/nftTimeline/components/searchBar/InitialValue";
import { yupSchema } from "../../../view/nftTimeline/components/searchBar/Validation";
import { SearchIcon } from "core/constants/Images";
import InputBox from "../inbutBox/InputBox";
import * as RoutePaths from "core/constants/RoutePaths";
import "./SearchBar.css";

const SearchBar = (props) => {
  const [searchNftInfo, setSearchNftInfo] = useState();
  const location= useLocation();
  // console.log('location',location);
  const navigate = useNavigate();
  

  const { onNftClick, mintKey } = props;
  useEffect(()=>{
    if(mintKey){
      onNftClick(mintKey)
    }
  },[])
  return (
    <div>
      <Formik
        initialValues={fetchInitialValues(mintKey)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={yupSchema}
        onSubmit={(values) => {
          onNftClick(values.search);
          navigate(RoutePaths.NFT_TIMELINE+"?mintkey="+values.search);

        // console.log('mintkey in searchbar',values);
        }}
        >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldError,
          submitForm,
        }) => (
          <FormikForm>
            <div className="c-SearchBar search-bar-container">
              <div className="search-bar-field pr-10">
                <Field
                  as={InputBox}
                  label="Search"
                  placeholder="Mintkey"
                  name="search"
                  autocomplete="off"
                ></Field>
              </div>
              <p className="search-bar-validation">
                {errors.search && touched.search ? errors.search : ""}
              </p>

              <span className="search-icon">
                <button>
                  <img
                    src={SearchIcon}
                    className="search-icon-style"
                    alt="searchIcon"
                    type="submit"
                  />
                </button>
              </span>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
export default SearchBar;
