export function protectedString(str) {
  var displayProtectedString = "";
  var separator = "...";
  var firstFourCharacter = str.slice(0, 4);
  var lastFourCharacter = str.slice(-4);
  // var displayProtectedString=firstFourProtectedStringCharacter +separator+lastFourProtectedStringCharacter;
  if (str.length <= 9) {
    displayProtectedString = firstFourCharacter + separator;
  } else {
    displayProtectedString = firstFourCharacter + separator + lastFourCharacter;
  }
  return displayProtectedString;
}
