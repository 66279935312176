export const SIGN_IN_URL = "/login";
export const SUBSCRIPTION_URL = "/subscription";
export const MONITOR_URL = "/monitor";
export const WALLET_LEADERBOARD_URL = "/walletleaderboard";
export const USER_PROFILE_URL = "/userprofile";
export const NFT_TIMELINE_URL ="/nfttimeline";
export const UPDATE_PASSWORD ="/updatepassword";
export const CONTACT_US ="/contactus";
// export const NFT_RARE_RANKING ="/nftrarity?collectionname=Sol%20Patrol&traittype=Background&traitvalue=Blue";
export const NFT_RARE_RANKING ="/nftrarityv2";
export const NFT_COLLECTION ="/nftcollection";
export const METRICS_URL ="/subscriptionmetrics";
export const MONITOR_METRICS_URL ="/monitormetrics";
export const MARKET_PLACE_ACTIVITIES_URL ="/marketplaceactivites";
// https://monitor-api-nicbatbx3a-ue.a.run.app/subscriptionmetrics?subscription_id=177
// export const METRICS_URL ="http://0.0.0.0:8001/test";




const ApiURL = {
  SIGN_IN_URL,
  SUBSCRIPTION_URL,
  MONITOR_URL,
  WALLET_LEADERBOARD_URL,
  USER_PROFILE_URL,
  NFT_TIMELINE_URL,
  UPDATE_PASSWORD,
  CONTACT_US,
  NFT_RARE_RANKING,
  NFT_COLLECTION,
  METRICS_URL,
  MONITOR_METRICS_URL,
  MARKET_PLACE_ACTIVITIES_URL
};
export default ApiURL;
