// Package Imports
import { useCallback, useEffect, useState, useContext } from "react";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";

import ApiURL from "core/constants/ApiEndPoints";
import HttpMethod from "core/constants/ApiMethodConstants";

import { useAxios } from "./useAxios";

// Project Imports
import * as ApiEndPoints from "core/constants/ApiEndPoints";
// import AuthContext from "context/AuthContext";

export const useNftRareRankingData = (taskUrlQueryParams, page, size) => {
  let { response, postData, responseSchema } = useAxios();
  const [nftList, setNFtList] = useState(undefined);
  const [paginationData, setPaginationData] = useState({});

  let [searchParams, setSearchParams] = useSearchParams();
  let collection = searchParams.get("collection");
  const fetchData = useCallback(
    (taskUrlQueryParams, page, size) => {
      let addExtraParams = "";
      let sortParams = "sort=id,desc";
      if (page !== undefined && size !== undefined) {
        setPaginationData({ page, size });
        addExtraParams += `page=${page+1}&itemsperpage=${size}`;
      }
      if ( Object.keys(taskUrlQueryParams).length !== 0) {
        addExtraParams += `&traittype=${taskUrlQueryParams.traittype}&traitvalue=${taskUrlQueryParams.traitValue}`;
      }
      postData({
        // method: "GET",
        // url: (`${ApiEndPoints.TASK_SEARCH}?${taskUrlQueryParams}&${addExtraParams}&${sortParams}&`),
        method: HttpMethod.GET,
        url: (`${ApiURL.NFT_RARE_RANKING}?collectionname=${collection}&${addExtraParams}`),
      });
    },
    [postData]
  );

  // console.log("RESPONSE",response);

  useEffect(() => {
    if (response !== undefined) {
      // let responseData = response.map((item) => {
      //   let processVariables = {};
      //   try {
      //     processVariables = JSON.parse(item.camundaProcessVariables);
      //   } catch (error) {
      //     processVariables = {};
      //   }
      //   return {
      //     taskId: item.taskInstanceId,
      //     poricessInstanceId: item.poricessInstanceId,
      //     createdDate: item.startDate,
      //     ...processVariables,
      //   };
      // });
      setNFtList(response);
    }
    if (
      responseSchema !== undefined &&
      responseSchema.headers &&
      paginationData.page !== undefined &&
      paginationData.size !== undefined
    ) {
      const total_rows = response && response.summary && response.summary.total_rows ? response.summary.total_rows : 0
      setPaginationData({
        // total: parseInt(responseSchema.headers["x-total-count"]),
        // total: parseInt(response.summary.total_rows),
        total: parseInt(total_rows),
        page: paginationData.page,
        size: paginationData.size,
      });
    }
  }, [response]);

  return [nftList, fetchData, paginationData];
};
