//Package Imports
import React from "react";
//Project Imports
import "./AttributeCard.css"

const AttributeCard = (props) => {
  return (
      <div className="c-AttributeCard body">
        <h3 className="trait-type">{props.attributes.trait_type}</h3>
        <p className="custom-blue attribute-value">{props.attributes.value}</p>
      </div>
  );
};

export default AttributeCard;
