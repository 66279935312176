// Package Imports
import React, { Component, useEffect } from "react";
// import { ThemeContext } from "osian-components";

// Project Imports
import NonRequireAuth from "ui/routes/NonRequireAuth";
import * as RoutePaths from "core/constants/RoutePaths";
import LandingPage from "./view/landingPage/LandingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./view/dashboard/Dashboard";
import RequireAuth from "./routes/RequireAuth";
import Login from "./view/login/Login";
import Registration from "./view/registration/Registration";
import Subscription from "./view/subscription/Subscription";
import MonitorsAndAlerts from "./view/monitorsAndAlerts/MonitorsAndAlerts";
import NftTimeline from "./view/nftTimeline/NftTimeline";
import ProfilePageUI from "./view/ProfilePage/ProfilePageUI";
import AboutUsPage from "./view/AboutUs/AboutUsPage";
import ContactUs from "./view/ContactUs/ContactUs";
import WalletAuth from "./shared/components/auth/walletAuth";
import NftRareRanking from "./view/nftRareRanking/NftRareRanking";
import NftRareRankingTable from "./view/nftRareRankingTable/NftRareRankingTable";
import GlobalModal from "./shared/components/globalModel/GlobalModal";
import LoaderComponent from "./shared/components/loader/Loader";

function Main() {
  return (
    <Router>
      <LoaderComponent isGlobal={true}>
        <GlobalModal>
          <Routes>
            <Route
              path={RoutePaths.ROOT}
              element={
                <>
                  <NonRequireAuth hideHeader={true}>
                    <WalletAuth />
                    <LandingPage />
                  </NonRequireAuth>
                </>
              }
            />
            <Route
              path={RoutePaths.SIGNIN}
              element={
                <>
                  <NonRequireAuth>
                    <WalletAuth />
                    <Login />
                  </NonRequireAuth>
                </>
              }
            />
            <Route
              path={RoutePaths.REGISTRATION}
              element={
                <>
                  <NonRequireAuth>
                    <WalletAuth />
                    <Registration />
                  </NonRequireAuth>
                </>
              }
            />
            <Route
              path={RoutePaths.ABOUTUS}
              element={
                <NonRequireAuth>
                  <AboutUsPage />
                </NonRequireAuth>
              }
            />
            <Route
              path={RoutePaths.CONTACTUS}
              element={
                <NonRequireAuth>
                  <ContactUs />
                </NonRequireAuth>
              }
            />
            <Route
              path={RoutePaths.DASHBOARD}
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path={RoutePaths.SUBSCRIPTION}
              element={
                <RequireAuth>
                  <WalletAuth />
                  <Subscription />
                </RequireAuth>
              }
            />

            <Route
              path={RoutePaths.MONITORS_AND_ALERTS}
              element={
                <RequireAuth>
                  <WalletAuth />
                  <MonitorsAndAlerts />
                </RequireAuth>
              }
            />

            <Route
              path={RoutePaths.NFT_TIMELINE}
              element={
                <NonRequireAuth>
                  <NftTimeline />
                </NonRequireAuth>
              }
            />

            <Route
              path={RoutePaths.PROFILEPAGEUI}
              element={
                <RequireAuth>
                  <ProfilePageUI />
                </RequireAuth>
              }
            />

            <Route
              path={RoutePaths.NFT_RARE_RANKING}
              element={
                <NonRequireAuth>
                  <NftRareRanking />
                </NonRequireAuth>
              }
            />
            <Route
              path={RoutePaths.NFT_RARE_RANKING_TABLE}
              element={
                <NonRequireAuth>
                  <NftRareRankingTable />
                </NonRequireAuth>
              }
            />
          </Routes>
        </GlobalModal>
      </LoaderComponent>
    </Router>
  );
}

export default Main;
