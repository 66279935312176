import React from "react";
import { Chart } from "react-google-charts";
import { useState } from "react";

export var data = [["Task", "Quantity"]];

function SubscriptionChart(props) {
  const { gData, title } = props;

  const options = {
    backgroundColor: "transparent",
    // title: "Subscription Data ",
    title: title,
    tooltip: { isHtml: true },
    legend: { position: "top", alignment: "end" },
    titleTextStyle: {
      color: "#000",
      fontSize: 14,
    },
  };

  // console.log("GDATA>>", gData);

  return (
    <div className="piechart-graph w-full">
      <Chart
        chartType="ColumnChart"
        data={gData}
        options={options}
        width={"100%"}
        height={250}
      />
    </div>
  );
}

export default SubscriptionChart;
