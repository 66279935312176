// Packages Imports
import { useState, Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { GoogleLogout } from "react-google-login";
import { Link, useNavigate } from "react-router-dom";
// Projects Imports
import * as RoutePaths from "core/constants/RoutePaths";
import { CompanyLogo } from "core/constants/Images";
import { useLocation } from "react-router-dom";
import { clientId } from "core/constants/Constants";
import {
  ConnectWalletNavigation,
  ProfilePageNavigation,
  SignInNavigation,
} from "ui/shared/components/header/Constants";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import "./Header.css";

// var signOut = ["Api Login", "Google Login"];
// localStorage.setItem('Signout_Type', JSON.stringify(signOut));

const authMenuList = [
  {
    name: "Dashboard",
    path: RoutePaths.DASHBOARD,
  },
  {
    name: "Subscriptions",
    path: RoutePaths.SUBSCRIPTION,
  },
  {
    name: "Monitoring & Alerts",
    path: RoutePaths.MONITORS_AND_ALERTS,
  },
  {
    name: "NFT TimeLine",
    path: RoutePaths.NFT_TIMELINE,
  },
  {
    name: "NFT Rare Ranking",
    path: RoutePaths.NFT_RARE_RANKING_TABLE,
  },
];
const nonAuthMenuList = [
  {
    name: "NFT TimeLine",
    path: RoutePaths.NFT_TIMELINE,
  },
  {
    name: "NFT Rare Ranking",
    path: RoutePaths.NFT_RARE_RANKING_TABLE,
  },
];

function Header(props) {
  const { disconnect, publicKey } = useWallet();
  let navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const signinType = localStorage.getItem("signinType");
  // console.log("TYpe:", signinType);
  const authUserNavigation = [
    { name: ProfilePageNavigation, onClick: onProfileClick },
    // { name: ConnectWalletNavigation, onClick: "" },
    // { name: "Sign out", onClick: onSignOutClick },
    // { name: "Google Sign out", onClick: onSignoutSuccess },
  ];
  const nonAuthUserNavigation = [
    { name: SignInNavigation, onClick: onLoginClick },
  ];

  useEffect(() => {
    if (signinType && !publicKey && !localStorage.getItem("UserId")) {
      localStorage.clear();
      navigate(RoutePaths.SIGNIN, { replace: true });
    }
  }, [publicKey]);
  function onProfileClick() {
    navigate(RoutePaths.PROFILEPAGEUI);
  }
  function onLoginClick() {
    navigate(RoutePaths.SIGNIN);
  }
  function onConnectWalletClick() {}
  function onSignOutClick() {
    navigate(RoutePaths.SIGNIN, { replace: true });
    localStorage.clear();
  }
  function onSignOutWalletClick() {
    localStorage.clear();
    disconnect();
  }
  const location = useLocation();
  function menuItemClickHandler(item) {
    navigate(item.path);
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function goToDashboard() {
    navigate(RoutePaths.DASHBOARD);
  }
  function onSignoutSuccess(res) {
    // alert("You have been logged out successfully");
    // console.log("Logout:", res);
    onSignOutClick();
  }
  return (
    <div className="bg-black w-full text-white z-50 relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                src={CompanyLogo}
                className="h-12 cursor-pointer"
                alt="graph"
                onClick={() =>
                  menuItemClickHandler({
                    path: signinType ? RoutePaths.DASHBOARD : RoutePaths.ROOT,
                  })
                }
              />
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {signinType
                  ? authMenuList.map((item, index) => (
                      <button
                        key={index}
                        className={`block font-medium w-auto py-2 px-4 rounded-md shadow custom-gradienthover menu-item text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 focus:ring-offset-gray-900 text-base ${
                          location.pathname == item.path ? "active" : ""
                        }`}
                        onClick={() => menuItemClickHandler(item)}
                      >
                        {item.name}
                      </button>
                    )) : ""
                  // : nonAuthMenuList.map((item, index) => (
                  //     <button
                  //       key={index}
                  //       className={`block font-medium w-auto py-2 px-4 rounded-md shadow custom-gradienthover menu-item text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 focus:ring-offset-gray-900 text-base ${
                  //         location.pathname == item.path ? "active" : ""
                  //       }`}
                  //       onClick={() => menuItemClickHandler(item)}
                  //     >
                  //       {item.name}
                  //     </button>
                  //   ))
                    }
              </div>
            </div>
          </div>

          <Menu as="div" className=" md:block hidden ml-3 relative ">
            <div>
              <Menu.Button className="max-w-xs  rounded-full flex items-center text-sm focus:outline-none">
                <span className="sr-only">Open user menu</span>
                <UserCircleIcon
                  className="h-10 w-10 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0  w-48 rounded-md shadow-lg   bg-black ring-1 ring-white ring-opacity-5 focus:outline-none">
                {signinType
                  ? authUserNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <>
                            <button
                              onClick={item.onClick}
                              className={classNames(
                                active ? "bg-gray-800" : "",
                                "block px-4 py-2 text-sm text-white w-48 text-left  hover:bg-gray-800"
                              )}
                            >
                              {item.name}
                            </button>
                            <a href="https://test.chaindna.io/">
                              <button className="block px-4 py-2 text-sm text-white-700 w-48 text-left hover:bg-gray-800">
                                Docs
                              </button>
                            </a>
                            <a href="https://blogs.chaindna.io/">
                              <button className="block px-4 py-2 text-sm text-white-700 w-48 text-left hover:bg-gray-800">
                                Blog
                              </button>
                            </a>
                          </>
                        )}
                      </Menu.Item>
                    ))
                  : nonAuthUserNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <>
                            <button
                              onClick={item.onClick}
                              className={classNames(
                                active ? "bg-gray-800" : "",
                                "block px-4 py-2 text-sm text-white w-48 text-left  hover:bg-gray-800"
                              )}
                            >
                              {item.name}
                            </button>
                            <a href="https://test.chaindna.io/">
                              <button className="block px-4 py-2 text-sm text-white-700 w-48 text-left hover:bg-gray-800">
                                Docs
                              </button>
                            </a>
                            <a href="https://blogs.chaindna.io/">
                              <button className="block px-4 py-2 text-sm text-white-700 w-48 text-left hover:bg-gray-800">
                                Blog
                              </button>
                            </a>
                            <WalletMultiButton className="c-Header nav select-wallet"></WalletMultiButton>
                          </>
                        )}
                      </Menu.Item>
                    ))}
                {signinType === "walletLogin" && (
                  <>
                    <button
                      onClick={onSignOutWalletClick}
                      className="block px-4 py-2 text-sm text-white-700 w-48 text-left hover:bg-gray-800"
                    >
                      Sign Out
                    </button>
                  </>
                )}
                {signinType === "apiLogin" && (
                  <>
                    <button
                      onClick={onSignOutClick}
                      className="block px-4 py-2 text-sm text-white-700 w-48 text-left hover:bg-gray-800"
                    >
                      Sign Out
                    </button>
                  </>
                )}
                {signinType === "googleLogin" && (
                  <>
                    <GoogleLogout
                      clientId={clientId}
                      render={(renderProps) => (
                        <button
                          className="block px-4 py-2 text-sm text-white-700 w-48 text-left hover:bg-gray-800"
                          onClick={renderProps.onClick}
                        >
                          Sign Out
                        </button>
                      )}
                      // buttonText="Sign Out"
                      onLogoutSuccess={(response) => onSignoutSuccess(response)}
                    ></GoogleLogout>
                  </>
                )}
              </Menu.Items>
            </Transition>
          </Menu>

          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-30 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-28"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-900">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 pt-2">
                        <button
                          type="button"
                          className="-ml-16 mt-16 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none ring-2 ring-inset ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                      <nav className="mt-12  space-y-1">
                        {signinType
                          ? authMenuList.map((item, index) => (
                              <button
                                key={index}
                                className={`block font-medium text-lg text-left px-8 w-full py-2 shadow  menu-item-text   active:text-red-500 text-gray-400 focus:outline-none focus:ring-offset-gray-900  ${
                                  location.pathname == item.path ? "active" : ""
                                }`}
                                onClick={() => menuItemClickHandler(item)}
                              >
                                {item.name}
                              </button>
                            )) : ""
                          // : nonAuthMenuList.map((item, index) => (
                          //     <button
                          //       key={index}
                          //       className={`block font-medium text-left px-8 w-full py-2 shadow  menu-item-text  active:text-red-500 text-white focus:outline-none focus:ring-offset-gray-900 text-lg  ${
                          //         location.pathname == item.path ? "active" : ""
                          //       }`}
                          //       onClick={() => menuItemClickHandler(item)}
                          //     >
                          //       {item.name}
                          //     </button>
                          //   ))
                            }
                      </nav>
                    </div>
                    {!signinType && (
                      <div className="flex-shrink-0 justify-center border-t border-gray-200 p-4">
                        <div className="flex-shrink-0 group block justify-center">
                          <div className=" items-center">
                            <div className="pl-10">
                              <div className="landing-page-wallet-bottom">
                                <button className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white px-14 py-3 rounded-md mb-2">
                                  <Link to={RoutePaths.SIGNIN}>Login</Link>
                                </button>
                              </div>
                              <div>
                              <a href="https://test.chaindna.io/">
                              <button className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white px-14 py-3 rounded-md mb-2">
                                Docs
                              </button>
                            </a>
                              </div>
                              <div>
                              <a href="https://blogs.chaindna.io/">
                              <button className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white px-14 py-3 rounded-md mb-2">
                                Blog
                              </button>
                            </a>
                              </div>
                              <div className="landing-page-wallet-bottom">
                                <WalletMultiButton></WalletMultiButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {signinType && (
                      <div className="flex-shrink-0 justify-center border-t border-gray-200 py-2">
                        <div className="flex-shrink-0 group block justify-center">
                          <div className=" items-center">
                            <div className="pl-10">
                              <div className="landing-page-wallet-bottom">
                                <button
                                  className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white profile-padding py-3 rounded-md mb-2"
                                  onClick={onProfileClick}
                                >
                                  {ProfilePageNavigation}
                                </button>
                              </div>
                              <div>
                              <a href="https://test.chaindna.io/">
                              <button className="text-base docs-padding font-medium text-gray-700 group-hover:text-gray-900 bg-white  py-3 rounded-md mb-2">
                                Docs
                              </button>
                            </a>
                              </div>
                              <div>
                              <a href="https://blogs.chaindna.io/">
                              <button className="text-base docs-padding font-medium text-gray-700 group-hover:text-gray-900 bg-white  py-3 rounded-md mb-2">
                                Blog
                              </button>
                            </a>
                              </div>
                              <div className="landing-page-wallet-bottom ">
                                {signinType === "walletLogin" && (
                                  <>
                                    <button
                                      onClick={onSignOutWalletClick}
                                      className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white px-12 py-3 rounded-md mb-2"
                                    >
                                      Sign Out
                                    </button>
                                  </>
                                )}
                                {signinType === "apiLogin" && (
                                  <>
                                    <button
                                      onClick={onSignOutClick}
                                      className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white px-14 py-3 rounded-md mb-2"
                                    >
                                      Sign Out
                                    </button>
                                  </>
                                )}
                                {signinType === "googleLogin" && (
                                  <>
                                    <GoogleLogout
                                      clientId={clientId}
                                      render={(renderProps) => (
                                        <button
                                          className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white px-14 py-3 rounded-md mb-2"
                                          onClick={renderProps.onClick}
                                        >
                                          Sign Out
                                        </button>
                                      )}
                                      // buttonText="Sign Out"
                                      onLogoutSuccess={(response) =>
                                        onSignoutSuccess(response)
                                      }
                                    ></GoogleLogout>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14">
                  {/* Force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-10 w-10 inline-flex items-center justify-center rounded-md focus:outline-none"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
