// Package imports
import React from "react";

// Project imports
import CdModal from "../CdModal";
import "./AlertModal.css"

function AlertModal(props) {
  const {
    item,
    title,
    showModal,
    onClose,
    onSubmit,
    showDescription,
    btnList,
    buttonDisplay,
    label,
    onButtonClick,
  } = props;

  const onCloseModal = () => {
    onClose();
  };
  const onSubmitModal = () => {
    onSubmit(item);
  };
  const onClickButtonModal = () => {
    onButtonClick();
  };

  return (
    <>
      <CdModal
        title={title}
        showModal={showModal}
        onClose={onCloseModal}
        onSubmit={onSubmitModal}
        btnList={
          buttonDisplay
            ? [
                {
                  label: label,
                  onClick: onClickButtonModal,
                  type: "outline",
                  disabled: false,
                },
              ]
            : null
        }
      >
        <div className="c-AlertModal alert-modal-container">
          <p className="alert-modal-container-text">
            {showDescription}
          </p>
        </div>
      </CdModal>
    </>
  );
}
export default AlertModal;
