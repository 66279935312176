export const SUCCESS_RESPONSE = "creation success";
export const ApiResponseStatus= {
    SUCCESS_RESPONSE,
  };

/**
 * For popup msg
 */
export const CREATION_FAILED_MSG = "Creation Failed";
export const CREATION_SUCCESS_MSG = "Your Data Has Been Submitted";
  
const StatusMsg = {
  CREATION_FAILED_MSG,
  CREATION_SUCCESS_MSG,
};
export default StatusMsg;
