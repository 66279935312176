import { Artwork, RarityIcon } from "core/constants/Images";
import { useNavigate } from "react-router-dom";
import * as RoutePaths from "core/constants/RoutePaths";
import { convertToDecimal } from "core/utils/ConvertToDecimal";

function NftRareRankingCard(props) {
  const { RareRanking } = props;
  let navigate = useNavigate();
  function onNftRareRankingCardClick() {
    navigate(RoutePaths.NFT_TIMELINE+"?mintkey="+RareRanking.mint);
  
  }
  function split(str){
    var splitted = str.split('#'); 
    var rank = splitted[1]; 
    return rank
    }
    let min= (array,find)=> array.reduce((prev,curr)=> prev[find]<curr[find] ? prev:curr);
    const maxRank= min(RareRanking.metadata.attributes, 'max_rank');


  return (
    <button onClick={onNftRareRankingCardClick} className="bg-gray-600 rounded-lg ">
      <div>
        <img
          className="object-contain w-full rounded-t-lg mx-auto"
          src={RareRanking.metadata.image}
          alt=""
        />
      </div>
      <div className="flex flex-nowrap justify-center p-3 gap-2 text-gray-300">
        <div className="font-bold text-xl bg-gray-500 px-3 rounded-r-full rounded-l-full flex items-center">
          {/* <img
            className="object-contain  rounded-t-lg mx-auto w-4 h-4"
            src={RarityIcon}
            alt=""
          />Rank: */}
          <span className="pl-1">{convertToDecimal(RareRanking.rank, 4)}</span>
        </div>
        {/* <div className="font-bold text-xl">#{split(RareRanking.name)}</div> */}
      </div>
     

      <div className=" text-xl font-bold mx-auto text-center max-w-md mb-3 text-gray-300 ">
        {RareRanking.name}
      </div>
      <div className=" text-lg font-medium mx-auto text-center max-w-md mb-1 text-gray-300 ">
        {maxRank.trait_type}.{maxRank.value}
      </div>
 
    </button>
  );
}

export default NftRareRankingCard;
