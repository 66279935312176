import { DatabaseIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

// Project imports
import { CompanyIcon } from "core/constants/Images";
import * as RoutePaths from "core/constants/RoutePaths";
import { convertToDecimal } from "core/utils/ConvertToDecimal";

function NftRareRankingRowItem(props) {
  const { NftRareRankingTableItem } = props;
  return (
    <tr className="border  border-gray-900  flex ">
      <th scope="row" className="px-4 py-2  text-white w-full">
        <div className="grid md:grid-cols-12  grid-cols-1 items-center gap-4">
          <div className="flex items-center col-span-6 ">
            <img src={CompanyIcon} className="h-10 w-18 md:h-14" alt="graph" />
            <div>
              <Link
                className="text-2xl"
                to={
                  RoutePaths.NFT_RARE_RANKING +
                  "?collection=" +
                  NftRareRankingTableItem.Collection
                }
              >
                {NftRareRankingTableItem.Collection}
              </Link>
            </div>
          </div>
          <div className="col-span-3">
            <div className="text-lg grid grid-cols-2 mb-2">
              <div className="text-sky-500">Items : </div>
              <div className="text-2xl ">{NftRareRankingTableItem.Items}</div>
            </div>
            <div className="text-lg grid grid-cols-2 mb-2">
              <div className=" text-sky-500">Floor Price : </div>
              <div className="text-2xl ">{convertToDecimal(NftRareRankingTableItem["floorprice-avg"], 4)}</div>
            </div>
            <div className="text-lg grid grid-cols-2 mb-2">
              <div className=" text-sky-500">Holders </div>
              <div className="text-2xl ">{NftRareRankingTableItem.holders}</div>
            </div>
          </div>

          <div className="col-span-3">
            <div className="text-lg grid grid-cols-2 mb-2">
              <div className=" text-sky-500">On Sale : </div>
              <div className="text-2xl ">-</div>
            </div>
            <div className="text-lg grid grid-cols-2 mb-2">
              <div className=" text-sky-500">On Sale % : </div>
              <div className="text-2xl ">{NftRareRankingTableItem.onsale_percentage ? (convertToDecimal(NftRareRankingTableItem.onsale_percentage,4)) : "-"}</div>
            </div>
            <div className="text-lg grid grid-cols-2 mb-2">
              <div className=" text-sky-500">Floor MC USD </div>
              <div className="text-2xl ">-</div>
            </div>
          </div>
        </div>
      </th>
    </tr>
    // <tr className=" text-white font-semibold text-lg bg-gray-700  border-b border-slate-900">
    //   <td className=" px-3 text-left font-bold text-xl md:text-2xl sm:pl-6 ">
    //     <div className="flex items-center">
    //     <img src={CompanyIcon} className="h-10 md:h-14"  alt="graph" />
    //     <span>
    //       <Link to={RoutePaths.NFT_RARE_RANKING}>
    //         {NftRareRankingTableItem.Collection}
    //       </Link>
    //     </span>
    //     </div>

    //   </td>

    //   <td className="px-3 whitespace-nowrap py-4 text-lg text-white font-bold">
    //     150.00 SOL
    //   </td>
    //   <td className="px-3 whitespace-nowrap py-4 text-lg text-white font-bold">
    //     {NftRareRankingTableItem.Items}
    //   </td>
    //   <td className="px-3 whitespace-nowrap py-4 text-lg text-white font-bold">
    //     5000
    //   </td>
    //   <td className="px-3 whitespace-nowrap py-4 text-lg text-white font-bold">
    //     1000
    //   </td>
    //   <td className="px-3 whitespace-nowrap py-4 text-lg text-white font-bold">
    //     10.05
    //   </td>
    //   <td className="px-3 whitespace-nowrap py-4 text-lg text-white font-bold">
    //     50.00M USD
    //   </td>
    // </tr>
  );
}

export default NftRareRankingRowItem;
