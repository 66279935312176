// Package Imports

import  React from 'react'
import * as Yup from "yup";

export const yupSchema = () => {
    let validation = {}
    validation = Yup.object().shape({
        name: Yup.string().required("Please enter name"),
        email:Yup.string().email("Invalid email address format").required("Email is required"),
        password: Yup.string().min(3, "Password must be 3 characters at minimum").required("password is required"),
        confirmPassword:Yup.string().required("Please confirm password").oneOf([Yup.ref('password'), null], 'Passwords must match')

    });
    return validation
  };

// export const validateForm =()=>{
//     let errors={}
//     errors = 
// }