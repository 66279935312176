// subscription list
const SubscriptionDeliveryMethodList = [
  {
    id: 1,
    name: "STREAMING",
  },
  {
    id: 2,
    name: "WEBSOCKET",
  },
  {
    id: 3,
    name: "API",
  },
  {
    id: 4,
    name: "FTP",
  },
  {
    id: 5,
    name: "CLOUDSTORAGE",
  },
];

const SubscriptionFrequencyList = [
  {
    id: 1,
    name: "STREAMING",
  },
  {
    id: 2,
    name: "MINUTE",
  },
  {
    id: 3,
    name: "HOURLY",
  },
  {
    id: 4,
    name: "DAILY",
  },
  {
    id: 5,
    name: "WEEKLY",
  },
];

// alert list
const AlertDeliveryMethodList = [
  {
    id: 1,
    name: "STREAMING",
  },
  {
    id: 2,
    name: "SMS",
  },
  {
    id: 3,
    name: "TELEGRAM",
  },
  {
    id: 4,
    name: "DISCORD",
  },
  {
    id: 5,
    name: "EMAIL",
  },
  {
    id: 6,
    name: "API",
  },
];

const AlertFrequencyList = [
  {
    id: 1,
    name: "STREAMING",
  },
  {
    id: 2,
    name: "MINUTE",
  },
  {
    id: 3,
    name: "HOURLY",
  },
  {
    id: 4,
    name: "DAILY",
  },
  {
    id: 5,
    name: "WEEKLY",
  },
];


export {
  SubscriptionDeliveryMethodList,
  SubscriptionFrequencyList,
  AlertDeliveryMethodList,
  AlertFrequencyList,
};
