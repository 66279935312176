// Packages Imports
import React, { useContext, Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Listbox, Transition } from '@headlessui/react'

// Project Imports

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function CdSelectMenu(props) {
  const {
    label,
    optionList,
    selectedValue,
    onSelectMenuChange
  } = props

  const [selected, setSelected] = useState()
  
  useEffect(() => {
    if(selectedValue){
      setSelected(optionList.find((obj) => obj.id === selectedValue.id))
    }
  }, [selectedValue])



  const dropDownOptionElements = optionList.map((item, index) => {
    return (
      <Listbox.Option
        key={item.id}
        className={({ active }) =>
          classNames(active ? "font-bold" : "font-normal", (index < optionList.length - 1) ? "border-b border-white" : "", "p-3 cursor-pointer")
        }
        value={item}
      >
        {({ selected, active }) => (
          <>
            <span
              className={classNames(
                selected ? 'font-semibold' : 'font-normal',
                'block truncate'
              )}
            >
             {item.name} 
            </span>

          </>
        )}
      </Listbox.Option>
    )
  })

  function onSelectionChange(selectedItem) {
    setSelected(selectedItem)
    onSelectMenuChange(selectedItem)
  }

  return (

    <Listbox
      value={selected || "select"}
      onChange={onSelectionChange}
    >
      {({ open }) => (
        <div className="z-10 relative w-full m-auto">
          <div className="z-10 relative w-full ">
            <Listbox.Button value={selected ? selected.name : ""}
              className={classNames(
                selected ? 'text-white' : 'text-transparent',
                'appearance-none bg-transparent  block border-0 border-b-2 border-gray-200 focus:outline-none focus:ring-0 mt-0 pb-2 pt-3 px-0 w-full z-1 cursor-pointer text-left floating-selectmenu'
              )}>
              <span
                className={classNames('block truncate')}
              >
                {selected ? selected.name : "select"}
              </span>

            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 mt-1 w-full bg-gray-800 text-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
              >
                {dropDownOptionElements}
              </Listbox.Options>
            </Transition>
            <Listbox.Label className=" -z-1 absolute duration-300 origin-0 text-white text-xl top-3">{label}</Listbox.Label>
          </div>
        </div>
      )}

    </Listbox>


  );
}

export default CdSelectMenu;
