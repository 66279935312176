import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'

  function ModalComponent(props) {
    
    // let cancelButtonRef = useRef(null)
    const {show, title, body, footer } = props;
  // render() {
    return (
      <Transition.Root show={show}  as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto w-full"
          // initialFocus={cancelButtonRef}
          open={show}
          onClose={() => null}
        >
          <div className="flex items-center justify-center min-h-screen p-tmd text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-slate-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    {/* { title ? (
                        <Dialog.Title as="h3" className="text-lg leading-6  font-medium text-white">
                          {title ?? title}
                        </Dialog.Title>
                    ) : undefined

                    } */}
                    <div className="mt-2 text-white text-2xl">
                      {body ?? body}
                    </div>
                  </div>
                </div>
                {footer ?? footer}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  // }
}

export default ModalComponent;