// Package imports
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

// Project imports
import { generateRandomNumberFromZero } from "core/utils/GenerateRandomNumber";
import { CompanyIcon } from "core/constants/Images";
import { protectedString } from "core/utils/ProtectedString";
import { BgColor } from "./Constants";
import { secured } from "core/utils/SecuredNft";
import "./VerticalTimeline.css"

function VerticalTimelineComponent(props) {
  const { activities } = props;
  const bgColor = BgColor;
  return (
    <div>
      <VerticalTimeline>
        {activities.map((item, index) => {
          let generatedRanNum = generateRandomNumberFromZero(
            bgColor.length - 1
          );
          while (generatedRanNum > bgColor.length - 1) {
            generatedRanNum = generateRandomNumberFromZero(bgColor.length - 1);
          }
          let selectedBgColor = bgColor[generatedRanNum];
          // let action = "";
          // let i = 0;
          // while (i < item.actions.length) {
          //   action = action + item.actions[i];
          //   i++;
          // }

          return item.actions.length > 0 ? (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: selectedBgColor, color: "#fff" }}
              contentArrowStyle={{
                borderRightWidth: "7px",
                borderRightStyle: "solid",
                borderRightColor: selectedBgColor,
              }}
              key={index}
              date=""
              iconStyle={{ background: "rgb(0,0,0)", color: "#fff" }}
              icon={<img src={CompanyIcon} className="w-80" alt="graph" />}
            >
              {item.actions.map((actionItem, index) => (
                <h3 key={index} className="c-NftTimeline vertical-timeline-action-data">
                  <span className="vertical-timeline-titles">Action- </span>
                  {secured(actionItem)}
                </h3>
              ))}
              <h4 className="vertical-timeline-data">
                <span className="vertical-timeline-titles">Age- </span>
                {item.price.age}
              </h4>
              <h4 className="vertical-timeline-data">
                <span className="vertical-timeline-titles">Signature- </span>
                {protectedString(item.summary.signature)}
              </h4>
            </VerticalTimelineElement>
          ) : undefined;
        })}
      </VerticalTimeline>
    </div>
  );
}

export default VerticalTimelineComponent;
