import moment from "moment";
export function graph(graphData,label,value) {

  function creationTime(str) {
    // var dateFormat = moment(str).format("Do MMM YYYY");
    var timeFormat = moment(str).format(" h:mm:ss a");
    var DateTime = `${timeFormat}`;
    // var DateTime = `${timeFormat}`;
    return DateTime;
  }

  let arraymetric = [["Task", "Quantity"]];
  if (graphData && Object.keys(graphData).length !== 0) {
      
      const metricgraph = graphData;
      // console.log("label>>>>>>>",label);
      for (let i = 0; i < metricgraph.length; i++) {
          // console.log("metricgraph>>>>>>>",metricgraph)
          // console.log("label>>>>>>>",label);
      let singleArray = [];
      singleArray.push((String(metricgraph[i][label])))
      singleArray.push(Number(metricgraph[i][value]))

      arraymetric.push(singleArray)
    }
  }
  // console.log("log",arraymetric);
  return arraymetric;
}
