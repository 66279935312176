//package imports
import React from "react";
import "./Heading.css"

const Heading = (props) => {
  const {logo, label} = props
  return (
    <div className="c-Heading heading">
      { logo && <img className="heading-icon" src={logo} alt="display" /> }
      <h2 className="heading-label">{label}</h2>
    </div>
  );
};
export default Heading;
