import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { data } from "./Constants";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const options = {
  chart: {
    title: "Price History",
  },
  chartArea: {
    backgroundColor: {
      fill: "#FF0000",
      fillOpacity: 0.1,
    },
  },
  legend: { position: "none" },
  colors: ["#123123"],
  series: {
    0: { axis: "Temps" },
    1: { axis: "Daylight" },
  },
  axes: {
    y: {
      Temps: { label: "Price (SOl)" },
    },
  },
};

export function PriceHistory(props) {
  const [graphData, setGraphData] = useState([
    [{ type: "date", label: "Day" }, "price variation"],
  ]);

  const { priceTime } = props;

  const data = priceTime;
  const NewData = [...data].reverse();


  // useEffect(() => {
  //   const dataToBeShown = priceTime.map((singleData) => {
  //     let singleArray = [];

  //     singleArray.push(new Date(singleData.tx_time));
  //     singleArray.push(Number(singleData.price));

  //     return singleArray;
  //   });

  //   setGraphData([...graphData, ...dataToBeShown]);
  // }, []);

  // console.log("graphData", graphData);

  return (
    <div className="w-full overflow-x-hidden">
      <ResponsiveContainer width="90%" aspect={3}>
        <LineChart
          width={500}
          height={300}
          data={NewData}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid horizontal="true" vertical="" stroke="#243240" />
          <XAxis  dataKey="tx_time" tick={{ fill: "#fff" }} />
          <YAxis dataKey="price" tick={{ fill: "#fff" }} />
          <Tooltip
            contentStyle={{ backgroundColor: "#8884d8", color: "#fff" }}
            itemStyle={{ color: "#fff" }}
            cursor={false}
          />
          <Line
            type="monotone"
            dataKey="price"
            stroke="#8884d8"
            strokeWidth="2"
            dot={{ fill: "#2e4355", stroke: "#8884d8", strokeWidth: 2, r: 5 }}
            activeDot={{
              fill: "#2e4355",
              stroke: "#8884d8",
              strokeWidth: 2,
              r: 10,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
