// Package Imports
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

// Project Imports
import { BgImage } from "core/constants/Images";
import "assets/images/chaindna-landing-bg.webp";
import { CompanyLogo } from "core/constants/Images";
import * as RoutePaths from "core/constants/RoutePaths";
import "./LandingPage.css";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import Footer from "ui/shared/components/footer/Footer";
import "index.css";
import LandingPageChanges from "./LandingPageChanges";

// const divStyle = {
//   color: "blue",
//   backgroundImage: "url(" + BgImage + ")",
// };

const LandingPage = (props) => {
  const { select } = useWallet();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  function goToLogin() {
    navigate(RoutePaths.SIGNIN);
  }
  function goToNftTimeline() {
    navigate(RoutePaths.NFT_TIMELINE);
  }

  function goToNftRareRanking() {
    navigate(RoutePaths.NFT_RARE_RANKING);
  }
  const location = useLocation();
  function menuItemClickHandler(item) {
    navigate(item.path);
  }
  const authMenuList = [
    {
      name: "NFT TimeLine",
      path: RoutePaths.NFT_TIMELINE,
    },
    {
      name: "NFT Rare Ranking",
      path: RoutePaths.NFT_RARE_RANKING_TABLE,
    },
  ];

  // Used to open Phantom login so commented
  // useEffect(()=>{
  //   select('Phantom')
  // },[])
  return (
    <>
      {/* Landing Page started */}
      {/* <div className="c-LandingPage background-image" style={divStyle}> */}
      <div className="c-LandingPage background-image" >
        <div className="header-area" aria-label="Global ">
          <div className="header-row">
            <div className="header-row-data gap-7 none">
              <img src={CompanyLogo} className="h-12" alt="graph" />
              <div className="hidden md:flex md:block px-2">
                {/* {authMenuList.map((item, index) => (
                  <button
                    key={index}
                    className={`landing-page-menu-list  custom-gradienthover menu-item ${
                      location.pathname == item.path ? "active" : ""
                    }`}
                    onClick={() => menuItemClickHandler(item)}
                  >
                    {item.name}
                  </button>
                ))} */}
              </div>
            </div>
          </div>
          <div className="header-nav ">
            <Link className="md:block hidden" to={RoutePaths.SIGNIN}>
              Login
            </Link>
            <div className="sticky  top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-10 w-10 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden md:block">
              <WalletMultiButton></WalletMultiButton>
            </div>
          </div>
        </div>

        <div className="landing-page-data calc-content">
          <div className="landing-page-data-center">
            <div className="md:block hidden">
              <div className="grid-body">
                <div>
                  <h1 className="grid-data flex">
                    <span className="block">
                      Data for an intelligent and informed decentralized
                    </span>
                    <span className="gradient-text custom-gradient px-2">
                      horizon
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="md:hidden block">
              <div className="body-data-grid">
                <div className="grid-body">
                  <div>
                    <h1 className="grid-data">
                      <span className="block">
                        Data for an intelligent and informed decentralized
                      </span>
                      <span className="gradient-text custom-gradient ">
                        horizon
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="landing-page-grid-content mt-8">
              <LandingPageChanges />
              <div className="landing-page-wallet-bottom">
                <WalletMultiButton></WalletMultiButton>
              </div>
              <div className="button-area">
                <button
                  className="button-style custom-gradient "
                  onClick={() => goToLogin()}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-30 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-28"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-900">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 pt-2">
                        <button
                          type="button"
                          className="-ml-16 mt-16 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none ring-2 ring-inset ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                      <nav className="mt-12  space-y-1">
                        {/* {authMenuList.map((item, index) => (
                          <button
                            key={index}
                            className={`block font-medium text-left pl-8 w-full py-2 shadow  menu-item-text  active:text-red-500 text-white focus:outline-none focus:ring-offset-gray-900 text-lg  ${
                              location.pathname == item.path ? "active" : ""
                            }`}
                            onClick={() => menuItemClickHandler(item)}
                          >
                            {item.name}
                          </button>
                        ))} */}
                      </nav>
                    </div>
                    <div className="flex-shrink-0 justify-center border-t border-gray-200 p-4">
                      <div className="flex-shrink-0 group block justify-center">
                        <div className=" items-center">
                          <div className="pl-10">
                            <div className="landing-page-wallet-bottom">
                              <button className="text-base font-medium text-gray-700 group-hover:text-gray-900 bg-white px-14 py-3 rounded-md mb-2">
                                <Link to={RoutePaths.SIGNIN}>Login</Link>
                              </button>
                            </div>
                            <div className="landing-page-wallet-bottom">
                              <WalletMultiButton></WalletMultiButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14">
                  {/* Force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* <Footer /> */}
        </div>
      </div>
      {/* </div> */}
      {/* Landing Page ended */}
    </>
  );
};
export default LandingPage;
