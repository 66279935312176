// Package Imports
import { useState, useEffect, useContext } from 'react'
import axios from 'axios'

// Project Imports
// import AuthContext from 'context/AuthContext'
import ModalContext from 'context/ModalContext'
import LoaderContext from 'context/LoaderContext'
// import * as StorageKeys from 'core/constants/StorageKeys';
import * as RoutePaths from 'core/constants/RoutePaths'
import { useQuery } from "core/hooks/useQuery";
window.totalAPICount = 0;
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL

/**
 *
 * @param {*} axiosParams
 * Add all the common logic here, including getting headers etc.
 */
export const useAxios = (axiosParams, internallyHandleError = true, internallyHandleLoader = true) => {
	const [loading, setLoading] = useState(false)
	const [response, setResponse] = useState(undefined)
	const [responseSchema, setResponseSchema] = useState(undefined)
	const [error, setError] = useState(undefined)
	const [humanReadableError, setHumanReadableError] = useState(undefined)
	// // const authContext = useContext(AuthContext);
	const modalContext = useContext(ModalContext);
	const loaderContext = useContext(LoaderContext);

	let queryParams = useQuery();

	// let userToken = queryParams.get("userToken");
	let userToken = localStorage.getItem("Token");

	let defaultObj = {
		method: '',
		url: '',
		data: {},
		headers: {}
	}

	const setDefaultHeaders = (requesObj) => {

		// let token = await localStorage.getItem(StorageKeys.AUTH_TOKEN);
		// requesObj.headers['Authorization'] = 'Bearer ' + token;

		let token = localStorage.getItem("Token");
		if (token) {
			requesObj.headers['Authorization'] = 'Bearer ' + token;
		}
		return requesObj;
	}

	// Mapping it with default axios object 
	// So that any error will not occur when assigning to axios inner object
	if (!Array.isArray(axiosParams)) {
		axiosParams = {
			...defaultObj,
			...axiosParams
		}

	}

	const fetchData = async (params, internallyHandleError = true, internallyHandleLoader = true) => {
		try {
			setLoading(true);
			window.totalAPICount++;
			if (internallyHandleLoader) {
				loaderContext.setIsLoading(true);
			}
			params = await setDefaultHeaders(params);
			const result = await axios.request(params)
			// console.log(result)
			setResponseSchema(result)
			setResponse(result.data)
		} catch (error) {
			setError(error)
			if (modalContext !== undefined && internallyHandleError) {
				if ([401, 403].indexOf(error?.response?.data?.status) !== -1) {
					if (userToken !== undefined && userToken !== null) {
						localStorage.clear();
						// ADD Login Route URL
						// window.location.href = RoutePaths.CUSTOMER_APPLICATION_UNDER_PROCESS;
					} else {
						// authContext?.sessionExipredModal();
					}
				} else {
					modalContext.showModalWithTitleMessage({ title: 'Error', message: "Session Expired, Please Login", code: 401 })
				}
			}
		}
		finally {
			window.totalAPICount--;
			if (internallyHandleLoader && window.totalAPICount <= 0) {
				loaderContext.setIsLoading(false);
			}
			// setResponseSchema(undefined)
			setLoading(false)
			// setResponse(undefined)
			setError(undefined)
			setHumanReadableError(undefined)
		}
	}

	const fetchMultipleData = async (multipleParams, internallyHandleError = true, internallyHandleLoader = true) => {
		let error = [];
		let response = [];
		let responseSchema = [];
		let humanReadableError = [];

		setLoading(true);
		if (internallyHandleLoader) {
			loaderContext.setIsLoading(true);
		}

		for (let i = 0; i < multipleParams.length; i++) {
			try {
				let params = {
					...defaultObj,
					...multipleParams[i]
				}
				params = await setDefaultHeaders(params);
				const result = await axios.request(params);
				response[i] = result.data;
				responseSchema[i] = result;
			} catch (errorResponse) {
				error[i] = errorResponse;
				humanReadableError[i] = error?.response?.data?.title
			}
		}

		// if (error.length > 0 && internallyHandleError && modalContext !== undefined) {
		if (error?.response?.data?.status === 401) {
			if (userToken !== undefined && userToken !== null) {
				localStorage.clear();
				// window.location.href = RoutePaths.CUSTOMER_APPLICATION_UNDER_PROCESS;
			} else {
				// authContext?.sessionExipredModal();
			}
		} else {
			// modalContext.showModalWithTitleMessage('Error', error?.response?.data?.title)
		}
		// }
		setError(error);
		setResponse(response);
		setResponseSchema(responseSchema)
		setHumanReadableError(humanReadableError);

		if (internallyHandleLoader) {
			loaderContext.setIsLoading(false);
		}

		setLoading(false)
		setResponse(undefined)
		setResponseSchema(undefined)
		setError(undefined)
		setHumanReadableError(undefined)

	}

	useEffect(() => {
		if (Array.isArray(axiosParams)) {
			fetchMultipleData(axiosParams, internallyHandleError, internallyHandleLoader)
		}

		if (axiosParams && axiosParams.method && axiosParams.method === "GET") {
			fetchData(axiosParams, internallyHandleError, internallyHandleLoader)
		}
	}, []) // execute once only

	const postData = async (requestConfig, internallyHandleError = true, internallyHandleLoader = true) => {
		let params = {
			...axiosParams,
			...requestConfig
		}
		await fetchData(params, internallyHandleError, internallyHandleLoader);
	}

	return { response, error, loading, postData, humanReadableError, fetchMultipleData, responseSchema }
}