//Package Imports
import React from "react";
import { useState } from "react";
//Project Imports
import showPasswordIcon from "assets/images/showPasswordIcon.svg"
import hidePasswordIcon from "assets/images/hidePasswordIcon.svg"
const CdPasswordTextField = (props) => {
const [showPassword,setShowpassword]=useState(false);

 function handleToggleButton() {
       setShowpassword(!showPassword);
 }
 
 
  const { label } = props;
  return (
    <div className="relative z-0 w-full">
      <div >
      <img  className="h-8 mt-5 cursor-pointer absolute bottom-2 right-0 " src={showPassword ? hidePasswordIcon:showPasswordIcon } onClick={handleToggleButton}/>
      
      </div>
      <input
        type={showPassword ? "text":"password"}
        autoComplete="off"
        placeholder=" "
        className=" appearance-none text-white bg-transparent block border-0 border-b-2 border-gray-200 focus:outline-none focus:ring-0 mt-0 pb-2 pt-3 px-0 w-full pr-10"
       {...props}
      />
      <label className="-z-1 absolute duration-300 origin-0 text-white text-xl top-3">
        {label}
        
      </label>
    </div>
  );
};

export default CdPasswordTextField;
