import { useState, useEffect } from "react";
import {
  Link,
  Route,
  Routes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Formik, Form as FormikForm, Field } from "formik";
// import { FilterIcon } from "@heroicons/react/outline";

import NftRareRankingCard from "./NftRareRankingCard";
import { useAxios } from "core/hooks/useAxios";
import HttpMethod from "core/constants/ApiMethodConstants";
import ApiURL from "core/constants/ApiEndPoints";
import Loader from "ui/shared/components/loader/Loader";
import { SubscriptionDeliveryMethodList } from "core/utils/DropdownList";
import initialValue from "./Initialvalue";
import { yupSchema } from "./validation";
import CdButton from "ui/shared/components/cdButton/CdButton";
import Dropdown from "ui/shared/components/dropdown/Dropdown";
import * as RoutePaths from "core/constants/RoutePaths";
// import Pagination from "../Pagination/Pagination";
import Pagination from "../Pagination/Pagination";
import { useNftRareRankingData } from "core/hooks/useNftRareRankingData";
import NftRareRankingAccordion from "./Accordian/NftRareRankingAccordian";
import { FilterIcon } from "core/constants/Images";

function NftRareRanking(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  let collection = searchParams.get("collection");
  const [nftRareRankingData, setNftRareRankingData] = useState();
  // data == [{}]
  const [storeNftRareRankingData, setStoreNftRareRankingData] = useState();
  const [nftRareRankingDropdownData, setNftRareRankingDropdownData] =
    useState();
  const [nftDropdownList, setNftDropdownList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState();
  const navigate = useNavigate();

  let size = 12;
  let currentPage = parseInt(
    new URLSearchParams(window.location.search).get("page") || "1"
  );
  const [page, setPage] = useState(currentPage - 1 || 0);
  const [filterParam, setFilterParam] = useState({});
  const [totalEntries, setTotalEntries] = useState(0);
  const [nftList, fetchData, paginationData] = useNftRareRankingData(
    filterParam,
    page,
    size
  );

  const [filterObj, setFilterObj] = useState({});

  useEffect(() => {
    // console.log("filterObj", filterParam);
    if (filterParam !== undefined) {
      fetchData(filterParam, page, size);
    }
  }, [filterParam, page]);

  // function onSearchClick(obj) {
  //   console.log("SearchObj", obj);
  //   setFilterObj(obj);
  //   setPage(0);
  // }

  function onPageChange(pageNumber) {
    setPage(pageNumber);
  }

  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;
  useEffect(() => {
    if (nftList !== undefined) {
      setNftRareRankingData(nftList.data);
      setStoreNftRareRankingData(nftList.data);
      let default_total_rows = 0;
      if (nftList && nftList.summary && nftList.summary.total_rows) {
        default_total_rows = nftList.summary.total_rows;
      }
      setTotalEntries(default_total_rows);
    }
  }, [nftList]);

  let initialobj = {};
  const initialValueFunction = () => {
    nftDropdownList.forEach((singleObj) => {
      const { label } = singleObj;
      initialobj[label] = "";
    });

    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }

      return true;
    }
    if (isEmpty(initialobj)) {
      return {};
    } else {
      return initialobj;
    }
  };

  useEffect(() => {
    if (nftList !== undefined) {
      setNftRareRankingDropdownData(nftList.dropdowns);
    }
  }, [nftList]);

  function onSubmit(values) {
    var filterKeyList = [];

    for (var key in values) {
      if (values.hasOwnProperty(key) && values[key].length > 0) {
        // filterKeyList.push(key + "." + values[key]);
        filterKeyList.push({ traittype: key, traitValue: values[key] });
      }
    }
    if (filterKeyList.length > 0) {
      setFilterParam(filterKeyList[filterKeyList.length - 1]);
    }
    setPage(0);

    // var filteredData = storeNftRareRankingData.filter(function (el) {
    //   var isValidData;
    //   if (filterKeyList.length > 0) {
    //     var isValidDataArray = [];
    //     for (let i = 0; i < filterKeyList.length; i++) {
    //       if (el.metadata.attributes_es.indexOf(filterKeyList[i]) > -1) {
    //         isValidDataArray.push("true");
    //       } else {
    //         isValidDataArray.push("false");
    //       }
    //     }
    //     if (isValidDataArray.indexOf("false") > -1) {
    //       isValidData = false;
    //     } else {
    //       isValidData = true;
    //     }
    //   } else {
    //     isValidData = true;
    //   }
    //   return isValidData;
    // });
    // setNftRareRankingData(filteredData);
  }

  const onReset = async (setFieldValue, values) => {
    nftDropdownList.forEach((singleObj) => {
      const { label } = singleObj;
      setFieldValue(label, "");
      values[label] = "";
    });
    setUpdateData(values);
    setFilterParam({});
    setPage(0);
  };
  const dropDownFunction = (object) => {
    const dropDownArray = [];

    for (let key in object) {
      dropDownArray.push({ label: key, optionList: object[key] });
    }

    setNftDropdownList(dropDownArray);
  };

  useEffect(() => {
    dropDownFunction(nftRareRankingDropdownData);
  }, [nftRareRankingDropdownData]);

  // if (nftDropdownList.length === 0) return;

  return (
    <div>
      {loader}
      <div className="text-white  py-5 max-w-7xl mx-auto col-span-2  pl-4 pr-8 flex md:justify-between flex-col md:flex-row	">
        <div className="md:flex block">
          <div className="text-xl md:text-right text-sky-500 py-3 underline">
            {/* <Link to={RoutePaths.NFT_RARE_RANKING_TABLE}>All Collection:</Link> */}
            <button onClick={() => navigate(-1)}>All Collection:</button>
          </div>
          <div className="md:text-5xl text-3xl font-semibold md:px-2">
            {collection}
          </div>
        </div>

        <h5 className="text-xl md:text-right md:py-3">
          {/* Total Items:-{nftRareRankingData.length} */}
          Total Items:-{totalEntries}
        </h5>
      </div>
      {nftRareRankingData &&
      nftRareRankingData !== undefined &&
      nftRareRankingData.length !== 0 &&
      Object.keys(nftRareRankingData).length !== 0 ? (
        <>
          <div className="grid md:grid-cols-12 lg:gap-4 mx-auto max-w-7xl  ">
            <div className="md:col-span-2 col-span-12">
              <Formik
                initialValues={initialValueFunction()}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={yupSchema}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldError,
                  submitForm,
                  resetForm,
                }) => (
                  <FormikForm>
                    <NftRareRankingAccordion
                      icon={FilterIcon}
                      title= "Filter"
                      active={true}
                    >
                    <div className="">
                      {nftDropdownList.map((singleObj, index) => {
                        const { label, optionList } = singleObj;

                        return (
                          <div className=" w-full mb-3" key={index}>
                            <Field
                              as={Dropdown}
                              label={label}
                              name={label}
                              onSelectMenuChange={(value) => {
                                setFieldValue(label, value);
                              }}
                              optionList={optionList}
                              selectedValue={values[label]}
                            />
                            <p className="text-red-600">
                              {errors.label && touched.label
                                ? errors.label
                                : ""}
                            </p>
                          </div>
                        );
                      })}
                      <div className="md:px-0 px-4 w-full mb-3 flex md:block lg:flex justify-between">
                        <div>
                          <button
                            className="reset-btn"
                            onClick={() => onReset(setFieldValue, values)}
                            type="reset"
                          >
                            Reset
                          </button>
                        </div>
                        <div>
                          <CdButton label="Filter" type="submit" />
                        </div>
                      </div>
                    </div>
                    </NftRareRankingAccordion>
                  </FormikForm>
                )}
              </Formik>
            </div>
            {!isLoading &&
            nftRareRankingData &&
            nftRareRankingData.length !== 0 ? (
              <>
                <div className=" md:col-span-10 md:col-start-3 col-span-12">
                  <div className="w-full">
                    <div className="md:max-w-7xl w-full mx-auto md:px-8 px-4 pb-5 mb-4">
                      <div className="text-white text-xl  mr-2 flex justify-end col-span-12 "></div>
                      <div className="grid lg:grid-cols-4 gap-4 grid-cols-1 ">
                        {loader}
                        {nftRareRankingData &&
                          nftRareRankingData.map((items, index) => (
                            <NftRareRankingCard
                              key={index}
                              RareRanking={items}
                            />
                          ))}
                      </div>
                    </div>
                    <div className="my-tmd">
                      <Pagination
                        currentPage={paginationData.page}
                        total={paginationData.total}
                        size={paginationData.size}
                        onPageChange={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!isLoading ? (
                  <p className="text-white text-2xl my-5 md:col-span-10 col-span-12 flex  items-center justify-center mt-5">
                    No Collection Found
                  </p>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </>
      ) :  (
        <>
          {nftRareRankingData &&(
            <p className="text-white text-2xl flex justify-center items-center mt-5">
              No Data Found
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default NftRareRanking;
