import { useAxios } from "core/hooks/useAxios";
import { useEffect, React, useState } from "react";
import Loader from "ui/shared/components/loader/Loader";
import HttpMethod from "core/constants/ApiMethodConstants";
import ApiURL from "core/constants/ApiEndPoints";
import NftRareRankingRow from "./components/nftRareRankingRow/NftRareRankingRow";
import Heading from "ui/shared/components/heading/Heading";
import NftRareRankingSearchBar from "./components/nftRarerRankingSearchBar/NftRareRankingSearchBar";
import RareRankingCollectionCard from "./components/nftRareRankingRow/RareRankingCollectionCard";
import Pagination from "../Pagination/Pagination";
import objectToQueryString from "core/utils/ObjectToQueryString";
import { useNftCollectionData } from "core/hooks/useNftCollectionData";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";

function NftRareRankingTable(props) {
  // const getNftRareRankingTableDataList = useAxios();
  // const postSearchData = useAxios();
  const [nftRareRankingTable, setNftRareRankingTable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [searchCollectionBy, setSearchCollectionBy] = useState();
  const [searchCollectionValue, setSearchCollectionValue] = useState();
  const [filterObj, setFilterObj] = useState({});
  let size = 10;
  let currentPage = parseInt(
    new URLSearchParams(window.location.search).get("page") || "1"
  );
  const [page, setPage] = useState(currentPage - 1 || 0);

  let [searchParams, setSearchParams] = useSearchParams();

  const storeSearchVar = {};
  useEffect(() => {
    let storeSearchDropdown = searchParams.get("searchBy");
    let storeSearchVal = searchParams.get("searchValue");
    if (storeSearchVal != null) {
      storeSearchVar[storeSearchDropdown] = storeSearchVal;
      setSearchData(storeSearchVar);
    }
  }, []);

  const [nftCollectionData, fetchData, paginationData] = useNftCollectionData(
    storeSearchVar,
    page,
    size
  );
  
  useEffect(() => {
    if (searchData !== undefined) {
      if (Object.keys(searchData).length !== 0) {
        fetchData(searchData, page, size);
      } else {
        fetchData(storeSearchVar, page, size);
      }
    }
  }, [searchData, page]);

  const findSearchData = (searchVal) => {
    setSearchData(searchVal);
    setPage(0);
  };
  function onPageChange(pageNumber) {
    setPage(pageNumber);
  }

  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;

  useEffect(() => {
    if (nftCollectionData !== undefined) {
      if (nftCollectionData.data)
        setNftRareRankingTable(nftCollectionData.data);
    }
  }, [nftCollectionData]);

  // function split(str){
  //   var splitted = str.split(':');
  //   var rank = splitted[1];
  //   return rank
  //   }
  // console.log(split(JSON.stringify(searchData)));
  // console.log("SEARCHED>>", searchData.collection_name);

  // http://localhost:3000/nft-rare-ranking-table?searchBy=collection_namess&searchValue=sol
  // let [searchParams, setSearchParams] = useSearchParams();
  let searchBy = searchParams.get("searchBy");
  let searchValue = searchParams.get("searchValue");
  let collection = searchParams.get("collection");

  // console.log("searchparams", searchParams);

  // const handleClick = () => {
  //   setSearchParams({ searchBy: "searchData.collection_name" });
  // };

  return (
    <div className="container max-w-7xl mx-auto py-tmd text-white">
      {loader}
      <NftRareRankingSearchBar
        findSearchData={findSearchData}
        // searched={handleClick}
        searchCollectionBy={searchCollectionBy}
        searchCollectionValue={searchCollectionValue}
      />
      <div className=" mb-10 mt-20 px-4">
        <Heading label="NFT Rare Ranking" />
      </div>
      {/* <Link to={RoutePaths.NFT_RARE_RANKING_TABLE + "?searchBy=collection_name" + "searchValue="}></Link> */}
      {!isLoading && nftRareRankingTable && nftRareRankingTable.length !== 0 ? (
        <>
          {/* it will showing it deskstop */}
          <div className="md:block hidden">
            <NftRareRankingRow nftRareRankingTableData={nftRareRankingTable} />
          </div>

          {/* it will showing it mobile */}
          <div className="md:hidden block">
            {nftRareRankingTable &&
              nftRareRankingTable.map((item, index) => (
                <div className="m-2" key={index}>
                  <RareRankingCollectionCard key={index} item={item} />
                </div>
              ))}
          </div>
          <div className="mt-tmd">
            <Pagination
              currentPage={paginationData.page}
              total={paginationData.total}
              size={paginationData.size}
              onPageChange={onPageChange}
            />
          </div>
        </>
      ) : (
        <>
          {!isLoading && nftRareRankingTable ? (
            <p className="text-white text-2xl flex justify-center mt-5">
              No Collection Found
            </p>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default NftRareRankingTable;
