// Packages Imports
import { Link } from "react-router-dom";
import { CompanyIcon } from "core/constants/Images";

//project Impots
import facebookicon from "assets/images/facebookicon.png";
import twitter from "assets/images/twitter.png";
import * as RoutePaths from "core/constants/RoutePaths";
import "./footer.css";

const Footer = (props) => {
  return (
    <>
      {/* footer started */}
      <div className="c-footer footer-container">
        <div className="footer-content">
          <div className="footer-grid-content">
            <div className="footer-text-content">
              <Link className="footer-text-style" to={RoutePaths.ABOUTUS}>
                ABOUT US
              </Link>
            </div>
            <div className="footer-text-content">
              <Link
                className="footer-text-style"
                to={RoutePaths.CONTACTUS}
              >
                CONTACT US
              </Link>
            </div>
            <div className="footer-text-content">
              <a href="https://test.chaindna.io/" className="footer-text-style">
                DOCS
              </a>
            </div>
            <div className="footer-text-content">
              <a href="https://blogs.chaindna.io/" className="footer-text-style">
                BLOG
              </a>
            </div>
            <div className="footer-text-content">
              <a href="#" className="footer-text-style">
                PRIVACY POLICY
              </a>
            </div>
          </div>
          <div className="footer-image">
            <a href=" https://twitter.com/chaindna_io">
              <img
                src={twitter}
                className="footer-image-twitter"
                alt="twitter"
              />
            </a>
          </div>
        </div>
      </div>
      {/* footer end */}
    </>
  );
};

export default Footer;
