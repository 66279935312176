export const BgColor = [
    "rgba(0, 0, 0, 1)",
    "rgba(245, 40, 145, 0.8)",
    "rgba(70,130,180)",
    "rgba(39, 166, 130, 0.8)",
    "rgba(0, 122, 236, 0.8)",
    "rgba(196, 12, 12, 0.8)",
    "rgba(47, 71, 12, 0.8)",
    "rgba(148, 59, 243, 0.93)",
    "rgba(89, 10, 68, 0.93)",
    "rgba(89, 133, 68, 0.93)",
    "rgba(139, 96, 7, 0.93)",
    "rgba(5, 65, 56, 0.79)",
    "rgba(250, 57, 0, 0.79)",
    "rgba(6, 126, 10, 1)",
  ];
  