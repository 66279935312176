export const OnlyAlphabetRegex = /^[a-zA-Z ]*$/;
export const PassPortRegex = /^(?!^0+$)[a-zA-Z0-9]{3,20}$/;
export const MobileRegex = /^[6-9]\d{9}$/;
export const PanRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const IFSCRegex = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
export const ImageFileExtension = ["png", "jpg", "jpeg"];
export const DateProps = { placeHolder: "DD-MM-YYYY", disabled: false };
export const DateFormat = "DD-MM-YYYY";
export const DateTimeFormat = "DD-MM-YYYY LTS";
export const DateTimeFormatTZ = "YYYY-MM-DD[T]HH:mm:ss[Z]";
export const PhotographIcon =
  "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA00lEQVRIie2VTQ6CMBCFv7jAAxjrXfw5nxCPwxFMXBh3nKG49Qa4cDCjtKWdNS95IQwz72shACwq1Bq4AD0wFLoHGqBKARpD8L/rFGBc+T5/018dZdanmsZVWDWZXxmDrsAtp9EKMCt2izZi63y0YQu0qt5KLQYvAjigk/OXeJCai8CzATq8A3ZiXQvBswE6yKnrGhyDZwFC4SGI7nGlOwiFhyD3wPwsIBWuIQ8+L10xwKrZT8VTjgdD+EmOfaqp5vdBW3xOASqBeEOwl/DkD2fRRG+H35iDrfTydAAAAABJRU5ErkJggg==";
export const IsInvalideDate = "Invalid date";
export const MonitorUpdateSuccess = "Monitor & Alerts Updated Successfully";
export const MonitorAddSuccess = "Monitor & Alerts Added Successfully";
export const ErrorMsg = "Something went wrong";
export const SubscriptionUpdateSuccess = "Subscription Updated Successfully";
export const SubscriptionAddSuccess = "Subscription Added Successfully";
export const UpdateSuccess = "update success";
export const DeletionSuccess = "Deletion Success";
export const MonitorAlertDelete = "Monitor & Alert Deleted Successfully";
export const SubscriptionDelete = "Subscription Deleted Successfully";
export const clientId =
  "213585453666-2ah0u7uifa36lvj720rroc33gpa7jova.apps.googleusercontent.com";

  export const AUTH_TOKEN = "authToken";
