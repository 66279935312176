// Package imports
import { useState, useEffect } from "react";

// Project imports
import Accordion from "./components/Accordian/Accordian";
import {
  UserLogo,
  NftNAme,
  Attributes,
  HeartGraph,
  Details,
} from "core/constants/Images";
import { PriceHistory } from "./components/priceHistory/PriceHistory";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";
import NftTimelineDetailsCard from "./components/DetailsCard/NftTimelineDetailsCard";
import { useAxios } from "core/hooks/useAxios";
import Loader from "ui/shared/components/loader/Loader";
import SearchBar from "ui/shared/components/searchBar/SearchBar";
import VerticalTimelineComponent from "./components/verticalTimeline/VerticalTimeline";
import AttributeCard from "./components/AttributeCard/AttributeCard";
import ApiURL from "core/constants/ApiEndPoints";
import TitleConstants from "core/constants/TitleConstants";
import HttpMethod from "core/constants/ApiMethodConstants";
import "./NftTimeline.css";

const NftTimeline = (props) => {
  const [nftTimelineData, setNftTimelineData] = useState();
  let [searchParams, setSearchParams] = useSearchParams();
  let mintkey = searchParams.get("mintkey");

  const [nftUriData, setNftUriData] = useState({});
  const getNftTimelineDataList = useAxios();
  const getUriDataList = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setsearchResult] = useState("");
  const [nftPriceHistoryData, setNftPriceHistoryData] = useState([]);
  const { attributes } = props;

  function searchResultHandler(value) {
    setsearchResult(value);
  }

  function splitDate(str) {
    var splitted = str.split("T");
    var time = splitted[1];
    var newDate = str.split("T").shift();
    var dateString = new Date(newDate).toLocaleString("en-us", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });
    var DateTime = `${dateString + " " + time}`;
    return DateTime;
  }

  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;

  useEffect(() => {
    if (getNftTimelineDataList.response !== undefined) {
      setNftTimelineData(getNftTimelineDataList.response.data);
    }
  }, [getNftTimelineDataList.response]);

  useEffect(() => {
    getNftTimeline();
  }, [getNftTimelineDataList.response]);

  const getNftTimeline = async (mintKey) => {
    // setIsLoading(true);
    let timlineApiUrl = ApiURL.NFT_TIMELINE_URL;
    // console.log("timlineApiUrl", timlineApiUrl);
    if (mintKey) {
      await getNftTimelineDataList.postData({
        method: HttpMethod.GET,
        url: timlineApiUrl + "?mintkey=" + mintKey,
      });
      localStorage.removeItem("Mint");
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    if (nftTimelineData && Object.keys(nftTimelineData).length !== 0 && nftTimelineData[0].txns && Object.keys(nftTimelineData[0].txns).length !== 0) {
      const nftPriceHistory = nftTimelineData[0].txns;
      let nftTimeLineDataArr = [];
      for (let i = 0; i < nftTimelineData[0].txns.length; i++) {
        if (
          nftPriceHistory[i].price.price &&
          nftPriceHistory[i].price.tx_time
        ) {
          let NftDatalist = {
            price: parseFloat(nftPriceHistory[i].price.price),
            tx_time: splitDate(nftPriceHistory[i].price.tx_time),
          };
          nftTimeLineDataArr.push(NftDatalist);
        }
      }
      setNftPriceHistoryData(nftTimeLineDataArr);
    } else {
      // console.log("details", "No details found");
    }
  }, [nftTimelineData]);

  // console.log("nftPriceHistoryData", nftPriceHistoryData);

  return (
    <div className="c-NftTimeline body">
      {loader}
      <div className="nft-searchbar-container">
        <SearchBar onNftClick={getNftTimeline} mintKey={mintkey} />
      </div>
      {nftTimelineData && (
        <>
          {Object.keys(nftTimelineData).length !== 0 ? (
            <>
              <div className="accordian  nft-container">
                <div className="grid md:grid-cols-4 grid-cols-1   gap-2 md:gap-8 mb-2 bg-gray-700 p-3 rounded-lg ">
                  <div className="text-2xl bg-gray-600 rounded-lg font-bold   p-3 text-center ">
                    Total Transaction:
                    {nftTimelineData[0].summary.noof_transactions}
                  </div>
                  <div className="text-2xl bg-gray-600 rounded-lg font-bold  p-3 text-center ">
                    Total Transferred:
                    {nftTimelineData[0].summary.noof_transfers}
                  </div>
                  <div className="text-2xl bg-gray-600 rounded-lg font-bold  p-3 text-center ">
                    Total Buy:
                    {nftTimelineData[0].summary.noof_buy_listings}
                  </div>
                  <div className="text-2xl bg-gray-600 rounded-lg font-bold   p-3 text-center ">
                    Cancel Listings:
                    {nftTimelineData[0].summary.noof_cancel_listings}
                  </div>
                </div>
                <div className="nft-grid">
                  <div>
                    <div className="content-separator">
                      <h1 className="nftTimeline-name">
                        {nftTimelineData[0].metadata_full.name}
                      </h1>
                      <div className="nftTimeline-symbol-container">
                        <img
                          className="nftTimeline-symbol-style"
                          src={NftNAme}
                          alt="solono"
                        />
                        <span className="nftTimeline-symbol-col">
                          {nftTimelineData[0].metadata_full.symbol}
                        </span>
                      </div>
                    </div>
                    <div className="content-separator">
                      <Accordion
                        icon={Details}
                        title={TitleConstants.Details}
                        active={true}
                      >
                        <div className="nftTimeline-details">
                          {nftTimelineData[0].metadata_full.properties
                            .creators ? (
                            nftTimelineData[0].metadata_full.properties.creators.map(
                              (items, index) => (
                                <NftTimelineDetailsCard
                                  key={index}
                                  Details={items}
                                />
                              )
                            )
                          ) : (
                            <p className="nftTimeline-no-data-found ">
                              No Details Found
                            </p>
                          )}
                        </div>
                      </Accordion>
                    </div>

                    <div className="content-separator">
                      <Accordion
                        icon={HeartGraph}
                        active={true}
                        title={TitleConstants.PriceHistory}
                      >
                        <PriceHistory priceTime={nftPriceHistoryData} />
                      </Accordion>
                    </div>
                  </div>
                  <div>
                    <div className="nftTimeline-image">
                      {Object.keys(nftTimelineData[0].metadata_full).length !==
                        0 && (
                        <img
                          className="nftTimeline-image-style"
                          src={nftTimelineData[0].metadata_full.image}
                          alt="solono"
                        />
                      )}
                    </div>

                    <div className="content-separator">
                      <Accordion
                        icon={UserLogo}
                        active={true}
                        title={`About ${
                          nftTimelineData[0].metadata_full.collection
                            ? nftTimelineData[0].metadata_full.collection.name
                            : nftTimelineData[0].metadata_full.name
                        }`}
                      >
                        <p>
                          {nftTimelineData[0].metadata_full.description ? (
                            nftTimelineData[0].metadata_full.description
                          ) : (
                            <p className="nftTimeline-no-data-found ">
                              No Description Found
                            </p>
                          )}{" "}
                        </p>
                      </Accordion>
                    </div>
                    <div className="content-separator">
                      <Accordion
                        icon={Attributes}
                        title={TitleConstants.Attributes}
                      >
                        <div className="grid lg:grid-cols-3 gap-4 grid-cols-1">
                          {nftTimelineData[0].metadata_full.attributes ? (
                            nftTimelineData[0].metadata_full.attributes.map(
                              (items, index) => (
                                <AttributeCard key={index} attributes={items} />
                              )
                            )
                          ) : (
                            <p className="nftTimeline-no-data-found ">
                              No Attributes Found
                            </p>
                          )}
                        </div>
                      </Accordion>
                    </div>
                  </div>
                </div>
                <div className="content-separator">
                  {Object.keys(nftTimelineData).length === 0 ? (
                    <p className="nftTimeline-no-data-found ">
                      No TimeLine Data Found
                    </p>
                  ) : (
                    <VerticalTimelineComponent
                      activities={nftTimelineData[0].txns}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <p className="nftTimeline-no-data-found ">No Data</p>
          )}
        </>
      )}
    </div>
  );
};

export default NftTimeline;
