import { protectedString } from "./ProtectedString";
export function secured(nftSecuredActionName) {
  return nftSecuredActionName
    .split(" ")
    .map((str) =>
      str.length > 32 ? str.substring(0, 4) + "..." + str.slice(-4) : str
    )
    .join(" ");
  // let actionNft = nftSecuredActionName;
  // const nftActionArrayJoin = actionNft.split();
  // var nftActionJoinedArray = nftActionArrayJoin.join(" ");
  // var nftActionArray = nftActionJoinedArray.split(" ");
  // const securedArray = [];
  // nftActionArray.forEach((securedkey) => {
  //   if (securedkey.length > 32) {
  //     let securedNftActionString = securedkey.replace(
  //       securedkey,
  //       protectedString(securedkey)
  //     );
  //     securedArray.push(securedNftActionString);
  //   } else {
  //     securedArray.push(securedkey);
  //   }
  // });
  // var securedArrayString = securedArray.join(" ");
  // return securedArrayString;
}
