// Package Imports
import { useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";

// Project Imports
import CdTextarea from "ui/shared/components/CdTextarea";
import CdTextbox from "ui/shared/components/cdTextBox/CdTextbox";
import CdModal from "ui/shared/components/CdModal";
import { useAxios } from "core/hooks/useAxios";
import {
  SubscriptionDeliveryMethodList,
  SubscriptionFrequencyList,
} from "core/utils/DropdownList";
import CdSelectMenu from "ui/shared/components/CdSelectMenu";
import { SUBSCRIPTION_URL } from "core/constants/ApiEndPoints";
import ApiURL from "core/constants/ApiEndPoints";
import Loader from "ui/shared/components/loader/Loader";
import { fetchSubscriptionData } from "ui/view/subscription/InitialValue";
import { yupSchema } from "ui/view/subscription/Validation";
import {
  SubscriptionAddSuccess,
  SubscriptionUpdateSuccess,
  ErrorMsg,
  UpdateSuccess,
} from "core/constants/Constants";
import HttpMethod from "core/constants/ApiMethodConstants";
import "./AddSubscriptionModal.css";


function AddSubscriptionModal(props) {
  const { item, showModal, onClose, onSubmit } = props;
  // const userId = localStorage.getItem("UserId");
  const postSubscriptionData = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;
  async function onSubmitModal(values, resetForm) {
    const accountId = localStorage.getItem("AccountId");
    // setIsLoading(true);
    if (item) {
      await postSubscriptionData.postData({
        method: HttpMethod.PUT,
        url: ApiURL.SUBSCRIPTION_URL,
        data: {
          name: values.subscriptionName,
          image_url: values.subscriptionImageURL,
          delivery_method: values.subscriptionSelectedDeliveryMethod.name,
          frequency: values.subscriptionselectedFrequency.name,
          description: values.subscriptionDescription,
          query: values.subscriptionQuery,
          status: "ENABLED",
          subscription_id: item.subscription_id,
        },
      });
    } else {
      await postSubscriptionData.postData({
        method: HttpMethod.POST,
        url: ApiURL.SUBSCRIPTION_URL,
        data: {
          name: values.subscriptionName,
          image_url: values.subscriptionImageURL,
          delivery_method: values.subscriptionSelectedDeliveryMethod.name,
          frequency: values.subscriptionselectedFrequency.name,
          description: values.subscriptionDescription,
          query: values.subscriptionQuery,
          status: "ENABLED",
        },
      });
    }
    // setIsLoading(false);
  }
  const onCloseModal = () => {
    onClose();
  };
  useEffect(() => {
    if (postSubscriptionData.response !== undefined) {
      let statusMsg = "";
      if (postSubscriptionData.response.status) {
        if (
          postSubscriptionData.response.status.toLowerCase() === UpdateSuccess
        ) {
          statusMsg = SubscriptionUpdateSuccess;
        } else {
          statusMsg = SubscriptionAddSuccess;
        }
      } else {
        statusMsg = ErrorMsg;
      }
      onSubmit(true, statusMsg);
    }
  }, [postSubscriptionData]);

  // console.log("item", item);

  return (
    <>
      {/* AddSubscriptionModal-Start */}
      {loader}
      <div className="c-AddSubscriptionModal AddSubscriptionModal-container">
        (
        <Formik
          initialValues={fetchSubscriptionData(item)}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            onSubmitModal(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldError,
            submitForm,
          }) => (
            <FormikForm>
              <CdModal
                title={item ? "Update Subscription" : "Add Subscription"}
                showModal={showModal}
                onClose={onCloseModal}
                onSubmit={onSubmitModal}
                btnList={[
                  {
                    label: "Cancel",
                    onClick: onCloseModal,
                    type: "outline",
                    disabled: false,
                  },
                  {
                    label: item ? "Update" : "Add",
                    type: "submit",
                    disabled: false,
                  },
                ]}
              >
                <div className="AddSubscriptionModal-content">
                  <div className=" AddSubscriptionModal-name">
                    <Field
                      as={CdTextbox}
                      name="subscriptionName"
                      label="Subscription Name"
                      value={values.subscriptionName}
                    />
                    <p className="AddSubscriptionModal-text-color">
                      {errors.subscriptionName && touched.subscriptionName
                        ? errors.subscriptionName
                        : ""}
                    </p>
                  </div>
                  <div className="AddSubscriptionModal-text">
                    <Field
                      as={CdTextbox}
                      name="subscriptionImageURL"
                      label="Image URL"
                      value={values.subscriptionImageURL}
                    />
                    <p className="AddSubscriptionModal-text-color">
                      {errors.subscriptionImageURL &&
                      touched.subscriptionImageURL
                        ? errors.subscriptionImageURL
                        : ""}
                    </p>
                  </div>
                  <div className="AddSubscriptionModal-flex-container">
                    <div className="AddSubscriptionModal-cdselect-menu">
                      <Field
                        as={CdSelectMenu}
                        label="Delivery Method"
                        name="subscriptionSelectedDeliveryMethod"
                        onSelectMenuChange={(value) =>
                          setFieldValue(
                            "subscriptionSelectedDeliveryMethod",
                            value
                          )
                        }
                        optionList={SubscriptionDeliveryMethodList}
                        selectedValue={
                          values.subscriptionSelectedDeliveryMethod
                        }
                      />
                      <p className="AddSubscriptionModal-text-color">
                        {errors.subscriptionSelectedDeliveryMethod &&
                        touched.subscriptionSelectedDeliveryMethod
                          ? errors.subscriptionSelectedDeliveryMethod
                          : ""}
                      </p>
                    </div>
                    <div className="AddSubscriptionModal-cdselect-menu">
                      <Field
                        as={CdSelectMenu}
                        label="Frequency"
                        name="subscriptionselectedFrequency"
                        onSelectMenuChange={(value) =>
                          setFieldValue("subscriptionselectedFrequency", value)
                        }
                        optionList={SubscriptionFrequencyList}
                        selectedValue={values.subscriptionselectedFrequency}
                      />
                      <p className="AddSubscriptionModal-text-color">
                        {errors.subscriptionselectedFrequency &&
                        touched.subscriptionselectedFrequency
                          ? errors.subscriptionselectedFrequency
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="AddSubscriptionModal-text">
                    <Field
                      as={CdTextarea}
                      label="Query"
                      name="subscriptionQuery"
                      value={values.subscriptionQuery}
                    />
                    <p className="text-red-600 ">
                      {errors.subscriptionQuery && touched.subscriptionQuery
                        ? errors.subscriptionQuery
                        : ""}
                    </p>
                  </div>
                  <div className="AddSubscriptionModal-text">
                    <Field
                      as={CdTextarea}
                      label="Description"
                      name="subscriptionDescription"
                      value={values.subscriptionDescription}
                    />
                    <p className="text-red-600 ">
                      {errors.subscriptionDescription &&
                      touched.subscriptionDescription
                        ? errors.subscriptionDescription
                        : ""}
                    </p>
                  </div>
                </div>
              </CdModal>
            </FormikForm>
          )}
        </Formik>
        )
      </div>
      {/* AddSubscriptionModal-end*/}
    </>
  );
}

export default AddSubscriptionModal;
