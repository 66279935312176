// Package imports
import { Formik, Form as FormikForm, Field } from "formik";
import { useEffect, useState } from "react";

// Project imports
import { CompanyIcon } from "core/constants/Images";
import CdTextarea from "ui/shared/components/CdTextarea";
import CdTextbox from "ui/shared/components/cdTextBox/CdTextbox";
import InitialValue from "./InitialValue";
import { yupSchema } from "./Validation";
import CdButton from "ui/shared/components/cdButton/CdButton";
import "./contactUs.css";
import { useAxios } from "core/hooks/useAxios";
import HttpMethod from "core/constants/ApiMethodConstants";
import ApiURL from "core/constants/ApiEndPoints";
import AlertModal from "ui/shared/components/alertModal/AlertModal";
import StatusMsg, { ApiResponseStatus } from "./Constants";

function ContactUs() {
  const postContactUsData = useAxios();
  const [showAlertModal, setShowAlertModal] = useState(false);
  var [statusMsg, setStatusMsg] = useState("");

  const onCloseModal = () => {
    setShowAlertModal(false);
  };

  async function onSubmit(contactUsData) {
    await postContactUsData.postData({
      method: HttpMethod.POST,
      url: ApiURL.CONTACT_US,
      headers: { "Content-Type": "application/json" },
      data: {
        first_name: contactUsData.firstName,
        last_name: contactUsData.lastName,
        email_address: contactUsData.email,
        mobile_number: contactUsData.contact,
        details: contactUsData.details,
      },
    });
  }
  useEffect(() => {
    if (postContactUsData.response !== undefined) {
      if (
        postContactUsData.response &&
        postContactUsData.response.status.toLowerCase() ===
          ApiResponseStatus.SUCCESS_RESPONSE
      ) {
        setShowAlertModal(true);
        setStatusMsg(StatusMsg.CREATION_SUCCESS_MSG);
      } else if (
        postContactUsData.response &&
        postContactUsData.response.status.toLowerCase() ===
          StatusMsg.CREATION_FAILED_MSG
      ) {
        setShowAlertModal(true);
        setStatusMsg(postContactUsData.response.message);
      }
    }
  }, [postContactUsData.response]);
  return (
    <>
      {/* ContactUs Page Start */}
      <div className="c-ContactUs contact-us-container">
        <div className="contact-us-content">
          <div>
            {/* <div className="contact-us-header">Let's Talk About Your Goals</div> */}
            <div className="contact-us-body">
            Please provide full contact information by filling out the following contact details form.
            </div>
          </div>

          <div className="contact-us-image-row">
            <img className="h-52 w-52" src={CompanyIcon} alt="solono" />
          </div>
        </div>
        <div className="contact-us-card-container">
          <div className="contact-us-card-body custom-gradient">
            Reach Us Out
          </div>
          <Formik
            initialValues={InitialValue}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={yupSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldError,
              submitForm,
            }) => (
              <FormikForm>
                <div className="contact-us-form-row">
                  <div className="contact-us-form-body">
                    <Field as={CdTextbox} name="firstName" label="First Name" />
                    <p className="contact-us-para-color">
                      {errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""}
                    </p>
                  </div>
                  <div className="w-full contact-us-form-body">
                    <Field as={CdTextbox} name="lastName" label="Last Name" />
                    <p className="contact-us-para-color">
                      {errors.lastName && touched.lastName
                        ? errors.lastName
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="contact-us-form-row">
                  <div className="contact-us-form-body">
                    <Field as={CdTextbox} name="email" label="Email" />
                    <p className="contact-us-para-color">
                      {errors.email && touched.email ? errors.email : ""}
                    </p>
                  </div>
                  <div className="contact-us-form-body">
                    <Field as={CdTextbox} name="contact" label="Mobile " />
                    <p className="contact-us-para-color ">
                      {errors.contact && touched.contact ? errors.contact : ""}
                    </p>
                  </div>
                </div>
                <div className="py-3 ">
                  <Field as={CdTextarea} name="details" label="Details" />
                  <p className="text-red-600 ">
                    {errors.details && touched.details ? errors.details : ""}
                  </p>
                </div>
                <div className="contact-us-submit-button">
                  <CdButton label="Submit" type="submit" />
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
        {showAlertModal && (
          <AlertModal
            label="Go to Login"
            showModal={showAlertModal}
            onClose={onCloseModal}
            showDescription={statusMsg}
          />
        )}
      </div>
      {/* ContactUs Page end*/}
    </>
  );
}

export default ContactUs;
