// Package Imports
import { useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";

// Project Imports
import CdTextarea from "ui/shared/components/CdTextarea";
import CdTextbox from "ui/shared/components/cdTextBox/CdTextbox";
import CdModal from "ui/shared/components/CdModal";
import { useAxios } from "core/hooks/useAxios";
import {
  AlertDeliveryMethodList,
  AlertFrequencyList,
} from "core/utils/DropdownList";
import CdSelectMenu from "ui/shared/components/CdSelectMenu";
import ApiURL from "core/constants/ApiEndPoints";
import {
  MonitorUpdateSuccess,
  MonitorAddSuccess,
  ErrorMsg,
  UpdateSuccess,
} from "core/constants/Constants";
import Loader from "ui/shared/components/loader/Loader";
import { fetchMonitoringAndAlertData } from "ui/view/monitorsAndAlerts/InitialValue";
import { yupSchema } from "ui/view/monitorsAndAlerts/Validation";
import HttpMethod from "core/constants/ApiMethodConstants";
import "./AddMonitoringandAlertModal.css";


function AddMonitoringandAlertModal(props) {
  const { item, showModal, onClose, onSubmit } = props;
  // const userId = localStorage.getItem("UserId");
  const postMonitoringAndAlertData = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  let loader = "";
  if (isLoading) loader = <Loader isGlobal />;
  async function onSubmitModal(values) {
    const accountId = localStorage.getItem("AccountId");
    // setIsLoading(true);
    if (item) {
      await postMonitoringAndAlertData.postData({
        method: HttpMethod.PUT,
        url: ApiURL.MONITOR_URL,
        data: {
          name: values.monitoringAndAlertName,
          image_url: values.monitoringAndAlertImageURL,
          delivery_method: values.monitoringAndAlertSelectedDeliveryMethod.name,
          frequency: values.monitoringAndAlertselectedFrequency.name,
          description: values.monitoringAndAlertDescription,
          query: values.monitoringAndAlertQuery,
          status: "ENABLED",
          monitor_id: item.monitor_id,
        },
      });
    } else {
      await postMonitoringAndAlertData.postData({
        method: HttpMethod.POST,
        url: ApiURL.MONITOR_URL,
        data: {
          name: values.monitoringAndAlertName,
          image_url: values.monitoringAndAlertImageURL,
          delivery_method: values.monitoringAndAlertSelectedDeliveryMethod.name,
          frequency: values.monitoringAndAlertselectedFrequency.name,
          description: values.monitoringAndAlertDescription,
          query: values.monitoringAndAlertQuery,
          status: "ENABLED",
        },
      });
    }
    // setIsLoading(false);
  }
  const onCloseModal = () => {
    onClose();
  };
  useEffect(() => {
    if (postMonitoringAndAlertData.response !== undefined) {
      let statusMsg = "";
      if (postMonitoringAndAlertData.response.status) {
        if (
          postMonitoringAndAlertData.response.status.toLowerCase() ===
          UpdateSuccess
        ) {
          statusMsg = MonitorUpdateSuccess;
        } else {
          statusMsg = MonitorAddSuccess;
        }
      } else {
        statusMsg = ErrorMsg;
      }
      onSubmit(true, statusMsg);
    }
  }, [postMonitoringAndAlertData]);

  return (
    <>
      {/* AddMonitoringandAlertModal-Start */}
      {loader}
      <div className="c-AddMonitoringandAlertModal AddMonitoringandAlertModal-container ">
        (
        <Formik
          initialValues={fetchMonitoringAndAlertData(item)}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            onSubmitModal(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldError,
            submitForm,
          }) => (
            <FormikForm>
              <CdModal
                title={
                  item
                    ? "Update Monitoring And Alert"
                    : "Add Monitoring And Alert"
                }
                showModal={showModal}
                onClose={onCloseModal}
                onSubmit={onSubmitModal}
                btnList={[
                  {
                    label: "Cancel",
                    onClick: onCloseModal,
                    type: "outline",
                    disabled: false,
                  },
                  {
                    label: item ? "Update" : "Add",
                    type: "submit",
                    disabled: false,
                  },
                ]}
              >
                <div className="AddMonitoringandAlertModal-content ">
                  <div className="AddMonitoringandAlertModal-name">
                    <Field
                      as={CdTextbox}
                      name="monitoringAndAlertName"
                      label="Monitoring And Alert Name"
                      value={values.monitoringAndAlertName}
                    />
                    <p className="AddMonitoringandAlertModal-text-color">
                      {errors.monitoringAndAlertName &&
                      touched.monitoringAndAlertName
                        ? errors.monitoringAndAlertName
                        : ""}
                    </p>
                  </div>
                  <div className="AddMonitoringandAlertModal-text">
                    <Field
                      as={CdTextbox}
                      name="monitoringAndAlertImageURL"
                      label="Image URL"
                      value={values.monitoringAndAlertImageURL}
                    />
                    <p className="AddMonitoringandAlertModal-text-color">
                      {errors.monitoringAndAlertImageURL &&
                      touched.monitoringAndAlertImageURL
                        ? errors.monitoringAndAlertImageURL
                        : ""}
                    </p>
                  </div>

                  <div className="AddMonitoringandAlertModal-flex-container">
                    <div className="AddMonitoringandAlertModal-cd-selec-menu">
                      <Field
                        as={CdSelectMenu}
                        label="Delivery Method"
                        name="monitoringAndAlertSelectedDeliveryMethod"
                        onSelectMenuChange={(value) =>
                          setFieldValue(
                            "monitoringAndAlertSelectedDeliveryMethod",
                            value
                          )
                        }
                        optionList={AlertDeliveryMethodList}
                        selectedValue={
                          values.monitoringAndAlertSelectedDeliveryMethod
                        }
                      />
                      <p className="AddMonitoringandAlertModal-text-color">
                        {errors.monitoringAndAlertSelectedDeliveryMethod &&
                        touched.monitoringAndAlertSelectedDeliveryMethod
                          ? errors.monitoringAndAlertSelectedDeliveryMethod
                          : ""}
                      </p>
                    </div>
                    <div className="AddMonitoringandAlertModal-cd-selec-menu">
                      <Field
                        as={CdSelectMenu}
                        label="Frequency"
                        name="monitoringAndAlertselectedFrequency"
                        onSelectMenuChange={(value) =>
                          setFieldValue(
                            "monitoringAndAlertselectedFrequency",
                            value
                          )
                        }
                        optionList={AlertFrequencyList}
                        selectedValue={
                          values.monitoringAndAlertselectedFrequency
                        }
                      />
                      <p className="AddMonitoringandAlertModal-text-color">
                        {errors.monitoringAndAlertselectedFrequency &&
                        touched.monitoringAndAlertselectedFrequency
                          ? errors.monitoringAndAlertselectedFrequency
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="AddMonitoringandAlertModal-text">
                    <Field
                      as={CdTextarea}
                      label="Query"
                      name="monitoringAndAlertQuery"
                      value={values.monitoringAndAlertQuery}
                    />
                    <p className="AddMonitoringandAlertModal-text-color">
                      {errors.monitoringAndAlertQuery &&
                      touched.monitoringAndAlertQuery
                        ? errors.monitoringAndAlertQuery
                        : ""}
                    </p>
                  </div>
                  <div className="AddMonitoringandAlertModal-text">
                    <Field
                      as={CdTextarea}
                      label="Description"
                      name="monitoringAndAlertDescription"
                      value={values.monitoringAndAlertDescription}
                    />
                    <p className="AddMonitoringandAlertModal-text-color">
                      {errors.monitoringAndAlertDescription &&
                      touched.monitoringAndAlertDescription
                        ? errors.monitoringAndAlertDescription
                        : ""}
                    </p>
                  </div>
                </div>
              </CdModal>
            </FormikForm>
          )}
        </Formik>
        )
      </div>
      {/* AddMonitoringandAlertModal-end */}
    </>
  );
}

export default AddMonitoringandAlertModal;
