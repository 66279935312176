// Package imports
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ChevronUpIcon } from "@heroicons/react/solid";
import "./NftRareRankingAccordian.css";

const NftRareRankingAccordion = (props) => {
  const [isActive, setIsActive] = useState(false);
  const { title, icon, active } = props;

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div className="accordion-item px-4 md:px-2 mb-3 lg:px-0">
      <div
        className="accordion-title c-NftRareRankingAccordian title-container"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="c-NftRareRankingAccordian title text-white">
       {title}
          <span className="title-icon-col"> {icon && (
            <img
              className="title-icon "
              src={icon}
              alt="icon"
            />
          )}</span>
        </div>
        <div>
          {isActive ? (
            <ChevronUpIcon className="rotatedown chevron" />
          ) : (
            <ChevronUpIcon className="rotateup chevron" />
          )}
        </div>
      </div>
      {isActive && (
        <div className="c-NftRareRankingAccordian accordian-body ">{props.children}</div>
      )}
    </div>
  );
};

export default NftRareRankingAccordion;

NftRareRankingAccordion.propTypes = {
  active: PropTypes.bool,
};
NftRareRankingAccordion.defaultProps = {
  active: false,
};
