// Package imports
import React from "react";

// Project imports
import CdModal from "../CdModal";

function DeleteModal(props) {
  const { item, title, showModal, onClose, onSubmit } = props;
  const onCloseModal = () => {
    onClose();
  };
  const onSubmitModal = () => {
    onSubmit(item);
  };

  return (
    <>
      <CdModal
        title={title}
        showModal={showModal}
        onClose={onCloseModal}
        onSubmit={onSubmitModal}
        btnList={[
          {
            label: "Cancel",
            onClick: onCloseModal,
            type: "outline",
            disabled: false,
          },
          {
            label: "Delete",
            onClick: onSubmitModal,
            type: "success",
            disabled: false,
          },
        ]}
      >
        <div className="relative mt-6 flex justify-center items-center text-red-500 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>

          <p className="text-white text-3xl font-semibold ml-2">
            Are you sure you want to delete?
          </p>
        </div>
      </CdModal>
    </>
  );
}
export default DeleteModal;
