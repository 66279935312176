// Package Imports

import * as Yup from "yup";

export const yupSchema = () => {
    let validation = {}
    validation = Yup.object().shape({
        firstName: Yup.string().required("Please enter first name"),
        lastName: Yup.string().required("Please enter last name"),
        email:Yup.string().email("Invalid email address format").required("Email is required"),
        contact:Yup.string().required("Contact is required"),
        // details: Yup.string().("Please enter details"),     

    });
    return validation
  };
