import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import * as RoutePaths from "core/constants/RoutePaths";
import { HowAreWeDifferentImage } from "core/constants/Images";
import Accordion from "../nftTimeline/components/Accordian/Accordian";

const LandingPageChanges = () => {
  return (
    <div className="c-NewLandingPage  text-gray-300 px-4 lg:px-0 text-justify">
      <div className="my-3">
        <h3 className="paragraph-title">What is ChainDna?</h3>
        <p className="paragraph">
          At ChainDna we offer “Data Infrastructure as a Service” for Web3
          builders in Solana Blockchain. It means, your dev team don’t have to
          create databases, stand up geyser plugins, run rpc nodes or call
          zillion api calls. Dev teams get direct access to our database which
          has accounts and transactions data updated in real time. To further
          reduce your time, Accounts data are decoded and available in human
          readable format. Transactions data is parsed and we make actionable
          activities from the instructions. The same data can be consumed in
          near real time via a Kafka Topic or GCP Pubsub topic or any similar
          messaging service.
        </p>
      </div>
      <div className="mb-3">
        <h3 className="paragraph-title">Who are our target Customers:</h3>
        <p className="paragraph">
          Any Web3 Builders who need to ingest Solana blockchain updates,
          perform ETL processing and store and retrieve large amounts of data.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-y-4 mb-3">
        <div className="border bg-black bg-opacity-70 rounded-lg p-2">
          <Accordion
            icon=""
            title={<h3 className="md:text-2xl font-semibold">
              Profile 1: NFT Portfolio tracking DApps
            </h3>}
            active={false}
          >
            <div className=" paragraph">
              <p>typically monitors holdings of a wallet, tracks buy & sell of
                assets like NFT & Tokens and shows Profit & Loss in real time. This
                profile of applications, the dev team has to create a medium size
                database to store wallets holdings, monitor listing, buy, sell,
                transfer activities and store them in a database. Build an ETL job
                to calculate the Profit and Loss and store the results in the
                database.
                </p>
                <p>
                  Traditionally the dev team hires 1-2 Backend/Database engineers to
                  develop for three months and needs one engineer to maintain
                  ongoing changes. Typically this is a one time fee of 25K-50K,
                  maintenance engineer 8K per month and hosting charges of
                  1000-1800$ per month.
                </p>
              <p className="paragraph">Approximate cost of 50+96+18 =`{'>'}` 166K.</p>
              <p className="paragraph mt-3">
                Using our service, you shrink the development time to a few
                weeks(instead of 3 months), reduce first time development cost to
                8K, zero db hosting charges and a part-time resource to maintain it.
            With our subscription, 30K + 8 + 48 =`{'>'}` 86K. An average savings of
                60-40%.
              </p>
            </div>
          </Accordion>
        </div>
        <div className="border bg-black bg-opacity-70 rounded-lg p-2">
          <Accordion
            icon=""
            title={<h3 className="md:text-2xl font-semibold">
              Profile 2: NFT Marketplace Analytics
            </h3>}
            active={false}
          >
            <div className="">
              <p className="paragraph">
                typically stores all NFT mints, auction activities, creators and
                collectors. Marketplace needs analytics to get insights on auctions,
                mints, transfers etc. Monitor activities occurring at their
                competitors' platforms to gain competition insights. This profile of
                applications, the dev team has to create a large size database to
                ingest and store all NFT activities. Build ETL job to analyze
                collection trends, customer churns, commissions and royalties , and
                store the results in the database.
              </p>
              <p className="paragraph mt-3">
                Traditionally the dev team hires 2-4 Backend/Database engineers to
                develop for three to six months, one Rust developer to decode
                binary data, and needs two engineers to maintain ongoing changes.
                Typically this is a one time fee of 50-80K, maintenance engineer
                12K per month and hosting charges of 2000-3000$ per month.
              </p>
              <p className="paragraph">
              Approximate cost of 80+ 24 +144+36 =`{'>'}` 280K.{" "}
              </p>
              <p className="paragraph mt-3">
                Using our service, you shrink the development time to a few
                weeks(instead of 6 months), reduce first time development cost to
                12K, zero db hosting charges and a one full time resource to
              maintain it. With our subscription, 48K + 12 + 90 =`{'>'}` 150K. An
                average savings of 60-40%.
              </p>
            </div>
          </Accordion>
        </div>
        <div className="border bg-black bg-opacity-70 rounded-lg p-2">
        <Accordion
            icon=""
            title={<h3 className="md:text-2xl font-semibold">
              Profile 3: DeFi Trading
            </h3>}
            active={false}
          >
            <div className="">
              <p className="paragraph">
              typically monitors DEX order books (CLOB), Mango Markets,  looks for price movement and trades the underlying assets. Trading platform runs a Geyser Plugin to ingest account updates, run their models on the streaming data, monitoring applications to track price movements. The dev team has to run a RPC node with Geyser plugin, some Messaging Queue service, a very large size database to ingest and store all Dex transactions and token activities.  Build ETL job to analyze price movement trends, positions tracking, profit and loss, and store the results in the database.
              </p>
              <p className="paragraph mt-3">
              Traditionally the dev team hires 2-4 Backend/Database engineers to develop for three to six months , one Rust developer to decode binary data and needs one engineer to maintain ongoing changes. Typically this is a one time fee of 50-80K, maintenance engineer 12K per month and hosting charges of 4000-5000$ per month. Approximate cost of 80+144+60 =`{'>'}` 284K. 
              </p>
              
              <p className="paragraph mt-3">
              Using our service, you shrink the development time to a few weeks(instead of 6 months), reduce first time development cost to 12K, zero db hosting charges and a one full time resource to maintain it. With our subscription, 60K + 12 + 80 =`{'>'}` 152K. An average savings of 60-40%.
              </p>
            </div>
          </Accordion>
          
        </div>
      </div>
      <div className="mb-3">
        <h3 className="paragraph-title">
          How are we different from other services ?.
        </h3>
        <p className="paragraph">
          QuickNode and other services provide a RPC node or an API endpoint. A
          Dev team ingests data into their database by calling various APIs
          continuously or WebSockets and then transform the data to fit their
          data models. Web-sockets and API are HTTP based protocols that have
          overheads and are generally very slow compared to querying from a
          database or consuming from streaming services like Kafka or Pubsub.
          The application teams need to build logic to keep track of the state
          of your ingestion jobs. With the state, If the ingestion jobs crashes,
          you wouldn’t know where to start again, which leads to duplicate data,
          delay in processing and increased costs.
        </p>
        <p className="paragraph">
          With our service we eliminate those mundane activities and allow
          access to databases or Kafka Topic or pub-sub topics or any similar
          service of your choice. Your dev team connects to our services and
          starts building your applications.
        </p>
        <p className="paragraph">
          10 years ago every team was building their own data center, now
          everyone uses a cloud provider. In the same way, instead of teams
          building and operating their own data infrastructure simply connect
          and use our services.
        </p>
        <img
          src={HowAreWeDifferentImage}
          alt="HowAreWeDifferentImage"
          className="w-full py-2"
        />
      </div>
      <div className="mb-3">
        <h3 className="paragraph-title">What do we support now:</h3>
        <p className="paragraph">
          Full access to Kafka Topic(s) to get access to real time streaming of
          account and transaction updates
        </p>
        <p className="paragraph">
          Full access to Postgres Data with real time updates to Accounts and
          Transactions
        </p>
        <p className="paragraph">
          Subscribe to 100s of thousands of Account address and receive account
          activities published to your messaging service like GCP PubSub Events,
          AWS Kinesis
        </p>
        <p className="paragraph">
          Real Time streaming of NFT Mint, Auction Activities, Transfers,
          Commissions etc
        </p>
      </div>
      <div className="mb-3">
        <h3 className="paragraph-title">How to get started:</h3>
        <p className="paragraph">
          Open a account by signing up
          <span className="px-1">
            <Link className="underline text-sky-500" to={RoutePaths.SIGNIN}>
              here
            </Link>
          </span>
        </p>
        <p className="paragraph">Wait for activation email</p>
        <p className="paragraph">
          Depending on the choice of your infrastructure need, our team will
          engage with you and set up all access. We can go live within 24-48
          hours
        </p>
        <p className="paragraph">
          Our subscription is month to month and starts First calendar date of a
          month . Any remaining days will be prorated.
        </p>
      </div>
    </div>
  );
};

export default LandingPageChanges;
