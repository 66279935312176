/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon, ChevronLeftIcon, ChevronDoubleLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
// import { Autocomplete, Button, Textfield } from 'osian-components';

export default function Pagination({ currentPage, total, size, onPageChange }) {

  const [pageCount, setPageCount] = useState([]);
  const [gotoPageNumber, setGotoPageNumber] = useState({ name: currentPage, id: currentPage });
  const [totalPageCount, setTotalPageCount] = useState([]);
  const [totalPageIndex, setTotalPageIndex] = useState(0);
  const [totalPageCountSuggestion, setTotalPageCountSuggestion] = useState([]);
  useEffect(() => {
    // if (window.history.pushState) {
    //   var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname  +'?page=' + (currentPage + 1);
    //   // var newurl = window.location.href + '?page=' + (currentPage + 1);
    //   window.history.pushState({ path: newurl }, '', newurl);
    // }
    if (size !== undefined && total !== undefined && currentPage !== undefined) {
      var totalPage = Math.ceil(total / size)
      setTotalPageIndex(totalPage - 1 < 0 ? 0 : totalPage - 1)
      let startPoint = currentPage - 2
      startPoint = startPoint < 0 ? 0 : startPoint
      let endPoint = startPoint + 5
      if (endPoint > totalPage) {
        startPoint = totalPage - 5
      }
      if (startPoint < 0) {
        startPoint = 0
      }
      var countList = Array.from(Array(totalPage).keys())
      var totalCountObjList = countList.map(num => ({ id: num, name: num + 1 }))
      setTotalPageCount(totalCountObjList)
      countList = countList.slice(startPoint, endPoint)
      setPageCount(countList)
      setGotoPageNumber(totalCountObjList[currentPage])
    }
  }, [currentPage, total])
  function getTotalNumber(number) {
    setTotalPageCountSuggestion(totalPageCount.slice(number + 1, number + 4))
  }
  // console.log('total :: ', total)
  // console.log('totalPageCount :: ', totalPageCount)
  return (
    <>
      {total !== 0 && totalPageCount[totalPageIndex] &&
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
          <div className="-mt-px w-0 flex-1 flex">
            <a
              onClick={() => onPageChange(0)}
              className={`border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-white text-lg   ${currentPage - 1 < 0 ? 'opacity-50  pointer-events-none' : 'cursor-pointer hover:text-sky-500 hover:border-gray-300'}`}
            >
              <ChevronDoubleLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              First
            </a>
            <a
              onClick={() => onPageChange(currentPage - 1)}
              className={`border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-white text-lg   ${currentPage - 1 < 0 ? 'opacity-50  pointer-events-none' : 'cursor-pointer hover:text-sky-500 hover:border-gray-300'}`}
            >
              <ChevronLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              Prev
            </a>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {pageCount.map(number =>
              number !== currentPage ?
                <a key={'page-number-' + number}
                  onClick={() => onPageChange(number)}
                  className="border-transparent text-white text-lg hover:text-sky-500 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer"
                >
                  {number + 1}
                </a>
                :
                <a
                  key={'page-number-' + number}
                  className="border-sky-500 text-sky-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium pointer-events-none"
                  aria-current="page"
                >
                  {number + 1}
                </a>
            )}
          </div>
          <div className="-mt-px w-0 flex-1 flex justify-end">
            <a
              onClick={() => onPageChange(currentPage + 1)}
              className={`border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-white text-lg ${currentPage + 1 > totalPageCount[totalPageIndex].id ? 'opacity-50 pointer-events-none' : 'cursor-pointer hover:text-sky-500 hover:border-gray-300'}`}
            >
              Next
              <ChevronRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
            <a
              onClick={() => onPageChange(totalPageCount[totalPageIndex].id)}
              className={`border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-white text-lg ${currentPage + 1 > totalPageCount[totalPageIndex].id ? 'opacity-50 pointer-events-none' : 'cursor-pointer hover:text-sky-500 hover:border-gray-300'}`}
            >
              Last
              <ChevronDoubleRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
          </div>
          {/* <div className={`-mt-px w-0 pt-4 flex-1 flex justify-end items-center ${totalPageIndex === 0 ? 'opacity-50 pointer-events-none' : ''}`}>
            <span>Go To</span>
            <Autocomplete
              key="goto"
              className={`text-center mx-2  ${totalPageIndex === 0 ? 'opacity-50 pointer-events-none' : ''}`}
              textfieldSize="normal"
              showLabel={false}
              textfieldType="number"
              onType={(num) => {
                getTotalNumber(num);
              }}
              suggestions={totalPageCountSuggestion}
              selectedValue={gotoPageNumber}
              onSelect={(value) => onPageChange(value.id)}
            />
          </div> */}
        </nav>
      }
    </>
  )
}