// Packages Imports
import React, { useContext, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

// Project Imports

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Dropdown(props) {
  const { label, optionList, selectedValue, onSelectMenuChange } = props;

  const [selected, setSelected] = useState();

  useEffect(() => {
    if (selectedValue) {
      setSelected(optionList.find((obj) => obj === selectedValue));
    } else {
      setSelected();
    }
  }, [selectedValue]);

  const dropDownOptionElements = optionList.map((item, index) => {
    return (
      <Listbox.Option
        key={item}
        className={({ active }) =>
          classNames(
            active ? "font-bold" : "font-normal ",
            index < optionList.length - 1 ? "border-b border-white" : "",
            "p-3 cursor-pointer "
          )
        }
        value={item}
      >
        {({ selected, active }) => (
          <>
            <span
              className={classNames(
                selected ? "font-semibold" : "font-normal",
                "block truncate text-white "
              )}
            >
              {item}
            </span>
          </>
        )}
      </Listbox.Option>
    );
  });

  function onSelectionChange(selectedItem) {
    setSelected(selectedItem);
    onSelectMenuChange(selectedItem);
  }

  return (
    <Listbox value={selected || "select"} onChange={onSelectionChange}>
      {({ open }) => (
        <div className=" relative w-full m-auto ">
          <Listbox.Label className=" text-white text-xl break-words">{label}</Listbox.Label>
          <Listbox.Button
            value={selected ? selected : ""}
            className={classNames(
              selected ? "text-white" : "text-transparent",
              "appearance-none bg-transparent  border-2  rounded-lg border-gray-200 focus:outline-none focus:ring-0 mt-0 pb-2 pt-3 px-0 w-full  cursor-pointer text-left flex justify-between"
            )}
          >
            <div
              className={classNames(
                " truncate pl-2 text-white w-full flex justify-between"
              )}
            >
              {selected ? selected : "Select"}
              <div className="px-2 ">
                <ChevronDownIcon className="text-white w-6 h-6" />
              </div>
            </div>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="mt-1 z-10 absolute w-full bg-gray-800 text-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm  "
            >
              {dropDownOptionElements}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export default Dropdown;
