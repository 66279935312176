// Package Imports

import  React from 'react'
import * as Yup from "yup";

export const yupSchema = () => {
  let validation = {}
  validation = Yup.object().shape({
    username: Yup.string().required("Please enter username"),
    password: Yup.string().required("Please enter password"),
  });
  return validation
};


