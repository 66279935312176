// Package Imports


// Project Imports

import { AlertDeliveryMethodList } from "core/utils/DropdownList";
import { AlertFrequencyList } from "core/utils/DropdownList";
var initialValue = {
  monitoringAndAlertName: "",
  monitoringAndAlertImageURL: "",
  monitoringAndAlertSelectedDeliveryMethod: "",
  monitoringAndAlertselectedFrequency: "",
  monitoringAndAlertQuery: "",
  monitoringAndAlertDescription: "",
};

export const fetchMonitoringAndAlertData = (monitoringAndAlertDataValue) => {
  if(monitoringAndAlertDataValue !== null){
    let monitoringAndAlertData = initialValue;
    var monitoringAndAlertMethodName = AlertDeliveryMethodList.find( ({ name }) => name === monitoringAndAlertDataValue.delivery_method );
    var monitoringAndAlertFrequencyName = AlertFrequencyList.find( ({ name }) => name === monitoringAndAlertDataValue.frequency );
    monitoringAndAlertData.monitoringAndAlertName = monitoringAndAlertDataValue.name;
    monitoringAndAlertData.monitoringAndAlertImageURL = monitoringAndAlertDataValue.image_url;
    monitoringAndAlertData.monitoringAndAlertSelectedDeliveryMethod = monitoringAndAlertMethodName;
    monitoringAndAlertData.monitoringAndAlertselectedFrequency =  monitoringAndAlertFrequencyName;
    monitoringAndAlertData.monitoringAndAlertQuery = monitoringAndAlertDataValue.query;
    monitoringAndAlertData.monitoringAndAlertDescription = monitoringAndAlertDataValue.description;
    return monitoringAndAlertData;   
  }
  else{
      return initialValue={
        monitoringAndAlertName: "",
        monitoringAndAlertImageURL: "",
        monitoringAndAlertSelectedDeliveryMethod: "",
        monitoringAndAlertselectedFrequency: "",
        monitoringAndAlertQuery: "",
        monitoringAndAlertDescription: "",
      };
  }

}
