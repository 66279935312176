// Package imports

// Project imports
import "./MarketPlaceActivitiesTable.css";

import React from "react";
import MarketPlaceRowItem from "../MarketPlaceRowitem/MarketPlaceRowItem";

const MarketPlaceTable = (props) => {
  const { data } = props;

  return (
    <div className="c-MarketPlaceActivitiesTable page-container">
      <table className="table">
        <thead className="table-header">
          <tr>
            <th className="wallet-heading">Marketplace Name</th>
            <th className="activity-heading">Activity Date</th>
            <th className="activity-heading">Activity Count</th>
            <th className="activity-heading">Mints</th>
            <th className="activity-heading">Editions</th>
            <th className="activity-heading">Init Auction</th>
            <th className="activity-heading">Bids</th>
            <th className="activity-heading">Cancels</th>
            <th className="activity-heading">Redeems</th>
            <th className="activity-heading">Buys</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((item, index) => (
              <MarketPlaceRowItem key={index} item={item} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarketPlaceTable;
