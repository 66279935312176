// Package Imports
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";

// Project Imports
import ModalContext from "context/ModalContext";
import ModalComponent from "../modalComponent/ModalComponent";
import CdButton from "../cdButton/CdButton";
import { useNavigate } from 'react-router-dom';

export default function GlobalModal(props) {

    const [onClose, setOnClose] = useState(undefined);

    const [show, setShow] = useState(false);
    const [title, setTitle] = useState(false);
    const [body, setBody] = useState(false);
    const [footer, setFooter] = useState(false);
    const [btnText, setBtnText] = useState("OK");
    let navigate = useNavigate();
    const showModalWithTitleMessage = ({ title, message, btnText, onModalClose, code }) => {
        setShow(true);
        setBody(message);
        setTitle(title);
        if (btnText) setBtnText(btnText);
        if (typeof onModalClose === "function") {
            // Added this, since the onModalClose function is getting executed automatically 
            let obj = {
                closeFunc: onModalClose
            }
            setOnClose(obj);
        }
        if (code == 401) {
            let obj = {
                closeFunc: () => {
                    navigate('/login')
                }
            }
            setOnClose(obj);
        }
    }


    const closeModal = () => {
        if (onClose && typeof onClose.closeFunc === "function") {
            onClose.closeFunc();
        }

        setShow(false);
        localStorage.clear();
    }


    const closeElement = <div className="mt-5 sm:mt-6 flex justify-center">
        <CdButton onClick={closeModal} label={btnText}>
        </CdButton>
    </div>

    return (
        <ModalContext.Provider value={{
            setShow, setTitle, setBody, setFooter,
            showModalWithTitleMessage
        }} >
            <ModalComponent className="bg-gray-900" show={show} title={title} body={body} footer={footer || closeElement}>{body}</ModalComponent>
            {props.children}
        </ModalContext.Provider >
    );
}